import React from "react";

const INITIAL_STATE = {
  notifications: null,
  unreadCount: 0,
  setUnreadCount: () => null,
  setNotifications: () => null,
  resetNotificationContext: () => null,
};

export const NotificationContext = React.createContext(INITIAL_STATE);

const NotificationsReducer = (state, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "SET_UNREAD_COUNT":
      return { ...state, unreadCount: action.payload };
    case "RESET_NOTIFICATIONS_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    NotificationsReducer,
    INITIAL_STATE
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications: state.notifications,
        setNotifications: ({ data, push, unshift }) => {
          dispatch({
            type: "SET_NOTIFICATIONS",
            payload: unshift
              ? [data, ...state.notifications]
              : push
              ? [...state.notifications, ...data]
              : data,
          });
        },
        unreadCount: state.unreadCount,
        setUnreadCount: (count) => {
          dispatch({
            type: "SET_UNREAD_COUNT",
            payload: (count || 0) >= 0 ? count : 0,
          });
        },
        resetNotificationContext: () => {
          dispatch({ type: "RESET_NOTIFICATIONS_STATE" });
        },
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () =>
  React.useContext(NotificationContext);
