import React, { useContext, useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import {
  Button,
  Input,
  Switcher,
  SelectLocation,
  Select,
} from "../../components/input";
import { BusinessesContext } from "../../context/businessesContext";
import { ProductsContext } from "../../context/productsContext";
import { getListingCategories, getProductCategories } from "../../services/api";
import { useSharedContext } from "../../context/sharedContext";

export const SearchModal = ({ navigation, route }) => {
  const { businessCategories, setBusinessCategories, setIsBusinessLoading } =
    useContext(BusinessesContext);
  const {
    productsCategories,
    isProductsLoading,
    setProductsCategories,
    setIsProductsLoading,
  } = useContext(ProductsContext);
  const [searchParams, setSearchParams] = useState({
    itemType: "Listing",
    keyword: undefined,
    category: undefined,
    // priceRange: [],
    minPrice: undefined,
    maxPrice: undefined,
    location: undefined,
    detailLocation: undefined,
    // sortBy: "",
  });
  const { shared } = useSharedContext();
  const back = navigation.canGoBack();
  const [categories, setCategories] = useState([]);
  const createCategoriesList = (list) => {
    const list1 = list.map((e) => {
      return { label: e.title, value: e.id, icon: e?.icon };
    });
    return list1;
  };
  const getAllProductCategories = async () => {
    try {
      setIsProductsLoading(true);
      const res = await getProductCategories({
        limit: null,
        offset: null,
        searchText: "",
      });
      if (res.status === 200) {
        const categories = createCategoriesList(res.data?.data || []);
        setProductsCategories(categories);
        setCategories(categories);
      } else {
        console.log(
          "search-modal, getproductcategories res error : ",
          res.data
        );
      }
    } catch (error) {
      console.log("search-modal, getproductcategories error : ", error);
    } finally {
      setIsProductsLoading(false);
    }
  };
  const getAllListingCategories = async () => {
    try {
      setIsBusinessLoading(true);
      const categoryRes = await getListingCategories({
        limit: null,
        offset: null,
        searchText: "",
      });
      if (categoryRes.status === 200) {
        const categories = createCategoriesList(categoryRes.data?.data || []);
        setBusinessCategories(categories);
        setCategories(categories);
      }
      // else {
      //   console.log(
      //     "search-modal, getlistingcategories res error : ",
      //     categoryRes.data
      //   );
      // }
    } catch (error) {
      console.log("search-modal, getlistingcategories error : ", error);
    } finally {
      setIsBusinessLoading(false);
    }
  };
  const setCategoriesList = (type) => {
    if (type?.toLowerCase()?.includes("product")) {
      if (!productsCategories?.length) {
        getAllProductCategories();
      } else {
        setCategories(productsCategories);
      }
    } else {
      if (!businessCategories?.length) {
        getAllListingCategories();
      } else {
        setCategories(businessCategories);
      }
    }
  };

  useEffect(() => {
    setSearchParams((e) => ({ ...e, ...route.params }));
    setCategoriesList(route.params?.itemType || searchParams?.itemType);
  }, []);

  useEffect(() => {
    if (categories?.length && !searchParams.category) {
      setSearchParams((sp) => {
        const cat = categories.find(
          (c) =>
            c.value == (route.params?.category || route.params?.category?.value)
        ) || {
          label: "All",
          value: "all",
        };
        return {
          ...sp,
          category: { label: cat.label, value: cat.value },
        };
      });
    }
  }, [categories]);

  useEffect(() => {
    if (!back) {
      navigation.replace("home");
    }
    if (shared?.search) {
      setSearchParams((e) => ({ ...e, ...shared?.search }));
      setCategoriesList(shared?.search.itemType);
    } else {
      setCategoriesList(searchParams.itemType);
    }
  }, []);

  const doSearch = async () => {
    // navigation.setParams({
    //   ...searchParams,
    //   category: searchParams.category.value,
    // });
    navigation.replace("search", {
      ...searchParams,
      category: searchParams.category.value,
    });
  };
  const dismiss = () => {
    navigation.goBack();
  };
  const sortBy = [
    { label: "views", value: "views" },
    { label: "total review", value: "total_views" },
  ];
  return (
    <View
      className={`flex-1 items-center justify-center p-4  ${
        back ? "bg-app-d3" : "bg-gray-50"
      }`}
    >
      <View className="w-full max-h-screen my-6 py-2 bg-white rounded-lg max-w-screen-md border border-gray-200 ">
        <View className="flex-row items-center pl-4">
          <Font weight={600} className="text-[15px] flex-1 app-text-d">
            Search
          </Font>
          <Pressable
            className="p-3 rounded-full flex-shrink-0 active:bg-app-c1"
            onPress={dismiss}
          >
            <RemixIcon name="close-line" color={colors.app.d1}></RemixIcon>
          </Pressable>
        </View>
        <ScrollView keyboardShouldPersistTaps="always">
          <View className="p-4 rounded-t-2xl bg-gray-50 w-full h-full flex-1">
            <View className="flex-1">
              <Switcher
                labels={["Listing", "Products"]}
                options={[
                  { label: "Listing", value: "Listing" },
                  { label: "Product", value: "Product" },
                ]}
                label="What are you looking for?"
                value={searchParams.itemType}
                setValue={(e) => {
                  setCategoriesList(e);
                  setSearchParams((ee) => {
                    const params = {
                      ...ee,
                      itemType: e || undefined,
                    };
                    return params;
                  });
                }}
              ></Switcher>
            </View>
            <View className="flex-1">
              <Input
                type="text"
                label="Keyword"
                placeholder="Hotel, Restaurant, Band, Shoe ..."
                value={searchParams.keyword}
                setValue={(e) =>
                  setSearchParams((ee) => {
                    const params = {
                      ...ee,
                      keyword: e || undefined,
                    };
                    return params;
                  })
                }
              ></Input>
            </View>
            <View className="flex-1">
              {/* <Input
                type="text"
                label="Category"
                value={searchParams.category}
                setValue={(e) =>
                  setSearchParams((ee) => ({
                    ...ee,
                    category: e,
                  }))
                }
              ></Input> */}

              <View className="py-2">
                <Font className="flex-1  text-app-d1" weight={600}>
                  Category
                </Font>
              </View>
              <Select
                options={
                  !categories?.length
                    ? []
                    : [{ label: "All", value: "all" }, ...categories]
                }
                placeholder="Select category"
                className="w-full mb-4"
                mode="search"
                value={searchParams.category}
                setValue={(e) => {
                  setSearchParams((ee) => {
                    const params = {
                      ...ee,
                      category: e || undefined,
                    };
                    return params;
                  });
                }}
              ></Select>
            </View>
            <View className="flex-1">
              <Input
                type="pricerange"
                label="Price min."
                label1="Price max."
                value={{
                  max: searchParams.maxPrice,
                  min: searchParams.minPrice,
                }}
                setValue={({ e, type }) => {
                  if (type === "minValue") {
                    setSearchParams((ee) => {
                      const params = {
                        ...ee,
                        minPrice: e || undefined,
                      };
                      return params;
                    });
                  } else if (type === "maxValue") {
                    setSearchParams((ee) => {
                      const params = {
                        ...ee,
                        maxPrice: e || undefined,
                      };
                      return params;
                    });
                  }
                }}
              ></Input>
            </View>
            {/* <View className="flex-1">
              <Select
                label="Sort by"
                placeholder="Sort by"
                options={sortBy}
                mode="simple"
                value={searchParams.sortBy}
                setValue={(e) => {
                  {
                    setSearchParams((ee) => ({ ...ee, sortBy: e.value }));
                  }
                }}
              ></Select>
            </View> */}
            <View className="flex-1 mt-4">
              <Button label="Search" type="primary" onPress={doSearch}></Button>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
