import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Select, Switcher } from "../../components/input";
import { Unautorized } from "./unautorized";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import {
  deleteBookmark,
  deleteProductBookmark,
  DEV_API,
  getBookmark,
  getProductsBookmark,
} from "../../services/api";
import { ActivityIndicator } from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { FontAwesome } from "@expo/vector-icons";
import { Card } from "../../components/card";
import Toast from "react-native-toast-message";
import ConfirmModal from "../../components/ConfirmModal";

export const Wishlist = ({ navigation, route }) => {
  const { user, userToken, isAuthLoading } = useContext(AuthContext);
  const [isDeleteModal, setIsDeleteModal] = React.useState({
    visible: false,
    id: "",
  });
  const {
    userWishlists,
    userProductsWishlist,
    setUserProductsWishlist,
    setUserWishlists,
    setIsUserLoading,
    isUserLoading,
  } = useContext(UserContext);
  const [type, setType] = useState("Listing");
  const [pagination, setPagination] = useState({
    offset: null,
    total_records: null,
  });
  const [refreshing, setRefreshing] = useState(false);

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const perPageRecords = 10;
  const onRemoveBookmark = async (id) => {
    try {
      setIsDeleteModal((prev) => ({ ...prev, visible: false }));
      if (type === "Listing") {
        const res = await deleteBookmark({
          bookmark_id: id,
          token: userToken,
        });
        if (res.status === 204) {
          showToast("success", "Success", "Item removed From Wishlist");
          const newList = userWishlists.filter((e) => e.id !== id);
          setUserWishlists(newList);
        } else {
          if (res.status === 502) {
            showToast(
              "error",
              "Wishlist error",
              "Something went wrong on our side."
            );
          }
          console.log(
            "wishlist, delete listing bookmark res err : ",
            res.data.message
          );
        }
      } else {
        const res = await deleteProductBookmark({
          bookmark_id: id,
          token: userToken,
        });
        if (res.status === 204) {
          const newList = userProductsWishlist.filter((e) => e.id !== id);
          setUserProductsWishlist(newList);
        } else {
          if (res.status === 502) {
            showToast(
              "error",
              "Wishlist error",
              "Something went wrong on our side."
            );
          }
          console.log(
            "wishlist, deleteproductbookmark res err : ",
            res.data.message
          );
        }
      }
    } catch (error) {
      console.log("wishlist, removebookmark err : ", error);
    }
  };
  const getBusinessDetails = async ({ id }) => {
    navigation.navigate("listing", { id });
  };

  const getWishlist = async (token, offset = 0) => {
    try {
      setRefreshing(true);
      setIsUserLoading(true);
      if (type === "Listing") {
        console.log("check api for get listing wishlist");
        const res = await getBookmark({
          token,
          offset: offset,
          limit: perPageRecords,
        });
        if (res.status === 200) {
          console.log("check api for get listing wishlist", res.data.data);
          setUserWishlists((wishlist) =>
            offset
              ? [...(wishlist || []), ...(res.data?.data || [])]
              : res.data?.data || []
          );
          setPagination({
            offset: offset,
            total_records: res.data?.total_count,
          });
        } else {
          if (res.status === 502) {
            showToast(
              "error",
              "Wishlist error",
              "Something went wrong on our side."
            );
          } else if (res.status === 404) {
            showToast("error", "Error", "Not Found");
          }
          console.log("wishlist, getbookmarks res error : ", res.data);
        }
      } else {
        console.log("check api for get product wishlist");
        const productRes = await getProductsBookmark(
          token,
          offset,
          perPageRecords
        );
        if (productRes.status === 200) {
          console.log(
            "check api for get product wishlist data",
            productRes.data.data
          );
          setUserProductsWishlist((wishlist) =>
            offset
              ? [...(wishlist || []), ...(productRes.data?.data || [])]
              : productRes.data?.data || []
          );
          setPagination({
            offset: offset,
            total_records: productRes.data?.total_count,
          });
        } else {
          if (productRes.status === 502) {
            showToast(
              "error",
              "Wishlist error",
              "Something went wrong on our side."
            );
          } else if (productRes.status === 404) {
            showToast("error", "Error", "Not Found");
          }
          console.log(
            "wishlist, getproductsbookmarks res error : ",
            productRes.data
          );
        }
      }
    } catch (error) {
      console.log("wishlist, getbookmarks error : ", error);
    } finally {
      setRefreshing(false);
      setIsUserLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getWishlist(userToken);
    }
  }, [user, userToken, type]);

  // --------------- if loading -------------------
  // if (isUserLoading) {
  //   return (
  //     <View className="flex-1 justify-center items-center">
  //       <Font> Loading Data...</Font>
  //     </View>
  //   );
  // }
  const goToProductDetails = (id) => {
    navigation.navigate("product", { id });
  };
  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="rounded-t-2xl"
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                getWishlist(userToken);
              }}
            />
          }
        >
          <View className="flex-1 w-full justify-center items-center p-4">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-xl font-semibold text-app-d">
                  Wishlists
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className="w-full flex-1">
                  <View className="w-full flex-row justify-between items-center mb-5">
                    <View className="w-full lg:w-72">
                      <Switcher
                        options={[
                          { label: "Listing", value: "Listing" },
                          { label: "Product", value: "Product" },
                        ]}
                        value={type}
                        setValue={(e) => {
                          setType(e);
                        }}
                      ></Switcher>
                    </View>
                  </View>
                  <View className="flex-row flex">
                    {userWishlists.length > 0 &&
                    !isUserLoading &&
                    type === "Listing" ? (
                      <View className="flex-1 w-full mb-6">
                        {userWishlists.map((e, i) => (
                          <Pressable
                            className="w-full active:bg-app-c1 rounded-lg border border-gray-200 flex-row mb-4 lg:p-2 bg-app-a shadow-black shadow-lg "
                            key={`wishlist_${i}`}
                            onPress={() =>
                              getBusinessDetails({ id: e.listing.id })
                            }
                          >
                            <View className="w-32 h-32 lg:h-36 lg:w-40 flex overflow-hidden rounded-lg">
                              <View className="w-full h-full">
                                <Image
                                  source={
                                    e.listing.cover_image_url
                                      ? {
                                          uri: `${DEV_API}/media/${e.listing.cover_image_url}`,
                                        }
                                      : require("../../../assets/bg.jpg")
                                  }
                                  className="w-full h-full object-contain "
                                ></Image>
                              </View>
                            </View>
                            <View className="justify-start px-4 py-1 flex-1">
                              <View className="flex-1">
                                <View className="flex-row items-center pb-1">
                                  <Font
                                    className="text-xs lg:text-md text-app-f"
                                    numberOfLines={1}
                                    weight={500}
                                  >
                                    {e.listing.listing_category.title}
                                  </Font>
                                </View>
                                <View className="flex-1 pb-3">
                                  <Font
                                    className="text-[15px] text-app-d leading-4"
                                    numberOfLines={2}
                                    weight={600}
                                  >
                                    {e.listing.title}
                                  </Font>
                                </View>

                                <View className="flex-row items-center pb-1">
                                  <RemixIcon
                                    name="map-pin-fill"
                                    color={colors.app.d1}
                                    size={20}
                                    className="mr-1"
                                  ></RemixIcon>
                                  {e.listing.city !== null &&
                                  e.listing.state !== null &&
                                  e.listing.country !== null ? (
                                    <Font
                                      className=" text-app-d1"
                                      weight={500}
                                      numberOfLines={1}
                                    >
                                      {e.listing.city +
                                        ", " +
                                        e.listing.state +
                                        ", " +
                                        e.listing.country}
                                    </Font>
                                  ) : (
                                    <Font
                                      className=" text-app-d1"
                                      weight={500}
                                      numberOfLines={1}
                                    >
                                      {" "}
                                      city, state, country.
                                    </Font>
                                  )}
                                </View>
                                {e.listing.total_reviews > 0 && (
                                  <View className="flex-row items-center pb-1">
                                    <RemixIcon
                                      name="star-fill"
                                      size={16}
                                      color={colors.app.i}
                                      className="mr-1 flex-shrink-0"
                                    ></RemixIcon>
                                    <Font
                                      className="text-app-d1"
                                      weight={500}
                                      numberOfLines={1}
                                    >
                                      {" "}
                                      {`${e.listing.average_rating} / 5`} (
                                      {e.listing.total_reviews}){" "}
                                      {`${
                                        e.listing.total_reviews === 1
                                          ? "review"
                                          : "reviews"
                                      }`}
                                    </Font>
                                  </View>
                                )}
                                <View className="flex-row items-center">
                                  <Font
                                    className="text-app-d"
                                    numberOfLines={1}
                                    weight={500}
                                  >
                                    {" "}
                                    ${" "}
                                    {e.listing.min_price
                                      ? e.listing.min_price / 100
                                      : 0}
                                    {" -  "}${" "}
                                    {e.listing.max_price
                                      ? e.listing.max_price / 100
                                      : 0}{" "}
                                  </Font>
                                </View>
                              </View>
                            </View>
                            <View className="flex p-4">
                              <Pressable
                                className="h-10 w-10 bg-app-a rounded-full shadow-md p-1 items-center justify-center flex"
                                onPress={() => {
                                  setIsDeleteModal(() => ({
                                    id: e.id,
                                    visible: true,
                                  }));
                                }}
                              >
                                <RemixIcon
                                  name="bookmark-fill"
                                  color={colors.app.e}
                                  size="22"
                                ></RemixIcon>
                              </Pressable>
                            </View>
                          </Pressable>
                        ))}
                      </View>
                    ) : userProductsWishlist.length > 0 &&
                      !isUserLoading &&
                      type === "Product" ? (
                      <View className="flex-1 w-full mb-6">
                        {userProductsWishlist.length >= 1 && !isUserLoading && (
                          <View className="flex-row w-full p-0 lg:pr-4 flex-wrap">
                            {userProductsWishlist?.map((e, i) => (
                              <View
                                className="w-1/2 lg:w-1/3 p-1 lg:p-2"
                                key={i}
                              >
                                <Pressable
                                  className="w-full rounded-lg overflow-hidden border border-gray-200 bg-app-a shadow-black shadow-lg  active:bg-app-c1"
                                  onPress={() =>
                                    goToProductDetails(e.product.id)
                                  }
                                >
                                  <View className="h-44 lg:h-60 w-full relative">
                                    <Image
                                      source={
                                        e.product.variants[0].cover_image_url
                                          ? {
                                              uri: `${DEV_API}/media/${e.product.variants[0].cover_image_url}`,
                                            }
                                          : require("../../../assets/bip3.jpg")
                                      }
                                      className="w-full h-full"
                                    ></Image>
                                    <Pressable
                                      className="h-8 w-8 bg-app-a rounded-full shadow-md absolute top-2 right-2 items-center justify-center"
                                      onPress={() => {
                                        setIsDeleteModal(() => ({
                                          id: e.id,
                                          visible: true,
                                        }));
                                      }}
                                    >
                                      <FontAwesome
                                        name="heart"
                                        size={20}
                                        color={colors.app.e}
                                      />
                                    </Pressable>
                                  </View>
                                  <View className="p-2">
                                    <Font
                                      className="text-md text-app-d mb-1 leading-5"
                                      weight={600}
                                      numberOfLines={2}
                                    >
                                      {e.product.variants[0].title}
                                    </Font>

                                    <View
                                      className="flex-row items-center"
                                      style={{
                                        marginVertical: 2,
                                      }}
                                    >
                                      <Font
                                        className="text-[15px] text-app-d"
                                        numberOfLines={1}
                                        weight={700}
                                      >
                                        {" "}
                                        ${" "}
                                        {e.product.variants[0].price / 100 ||
                                          e.product.variants[0].sale_price /
                                            100}{" "}
                                      </Font>
                                      <Font
                                        className="text-md text-app-e1 line-through ml-2"
                                        weight={600}
                                        numberOfLines={1}
                                      >
                                        {" "}
                                        ${" "}
                                        {e.product.variants[0].price / 100 ||
                                          e.product.variants[0].sale_price /
                                            100}
                                      </Font>
                                    </View>
                                    <View
                                      className="flex-row items-center"
                                      style={{
                                        marginVertical: 2,
                                      }}
                                    >
                                      <RemixIcon
                                        name="star-fill"
                                        size={16}
                                        color={colors.app.i}
                                        className="mr-1 flex-shrink-0"
                                      ></RemixIcon>
                                      <Font
                                        className="text-md text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        {" "}
                                        {e.product.average_rating} |{" "}
                                        {e.product.total_reviews} reviews
                                      </Font>
                                    </View>
                                  </View>
                                </Pressable>
                              </View>
                            ))}
                          </View>
                        )}
                      </View>
                    ) : (userWishlists.length === 0 ||
                        userProductsWishlist.length === 0) &&
                      !isUserLoading ? (
                      <Card
                        // title={`Reviews`}
                        className="flex"
                      >
                        <View className="w-full flex-row h-80 justify-center items-center">
                          <Font className="text-app-d1">
                            No wishlists found.
                          </Font>
                        </View>
                      </Card>
                    ) : (
                      <Card className="w-full flex-1">
                        <View className="flex-1 w-full justify-center h-40">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.e}
                          ></ActivityIndicator>
                        </View>
                      </Card>
                    )}
                  </View>
                  {(userWishlists?.length || userProductsWishlist?.length) &&
                  !isUserLoading &&
                  pagination.offset + perPageRecords <
                    pagination.total_records ? (
                    <View className="flex-1 flex justify-end flex-row px-4 py-2">
                      <Button
                        className="w-full lg:w-60"
                        label="Load more"
                        type="primary"
                        onPress={() => {
                          getWishlist(
                            userToken,
                            pagination.offset + perPageRecords,
                            perPageRecords
                          );
                        }}
                      ></Button>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
      <ConfirmModal
        visible={isDeleteModal.visible}
        onCancel={() =>
          setIsDeleteModal((...prev) => ({ ...prev, visible: false }))
        }
        onConfirm={() => {
          onRemoveBookmark(isDeleteModal.id);
        }}
        confirmText={"Remove"}
        bodyText={"Are you sure you want to Remove from Wishlist?"}
        BodyComponent={false}
      ></ConfirmModal>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
};
