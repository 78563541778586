import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Maps } from "../../components/maps";
import { Button, Input } from "../../components/input";
import {
  patchUserBillingAddresses,
  patchUserShippingAddresses,
  postUserBillingAddress,
  postUserShippingAddresses,
} from "../../services/api";
import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import { ResponseModal } from "../../components/response-modal";

export const UpateAddress = ({ navigation, route }) => {
  const { userToken } = useContext(AuthContext);
  const { addressState } = route?.params;
  const {
    isUserLoading,
    userAddressList,
    userBillingAddress,
    setUserBillingAddress,
    setUserAddressList,
    setIsUserLoading,
  } = useContext(UserContext);
  const [address, setAddress] = useState(null);
  const [addressIsDefault, setAddressIsDefault] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [filteredAddress, setFilteredAddress] = useState(null);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Test Modal",
  });
  useEffect(() => {
    if (userAddressList.length > 0 && !route.params.is_billing) {
      const index = userAddressList.findIndex((e) => e.id === route.params.id);
      if (index > -1) {
        const indexAddress = {
          ...userAddressList[index].address,
          id: userAddressList[index].id,
        };
        setAddress(indexAddress);
        setFilteredAddress(indexAddress);
      }
    } else if (userAddressList.length === 0 && !route.params.is_billing) {
    } else if (route.params.is_billing) {
      const indexAddress = {
        ...userBillingAddress?.address,
        id: userBillingAddress?.id,
      };
      setAddress(indexAddress);
      setFilteredAddress(indexAddress);
    }
  }, []);
  const isButtonEnabled =
    addressState == "edit"
      ? (filteredAddress?.address_line_1 !== address?.address_line_1 &&
          address?.address_line_1 !== "") ||
        (filteredAddress?.address_line_2 !== address?.address_line_2 &&
          address?.address_line_2 !== "") ||
        (filteredAddress?.phone !== address?.phone && address?.phone !== "") ||
        (filteredAddress?.locality !== address?.locality &&
          address?.locality !== "") ||
        (filteredAddress?.landmark !== address?.landmark &&
          address?.landmark !== "") ||
        (filteredAddress?.city !== address?.city && address?.city !== "") ||
        (filteredAddress?.state !== address?.state && address?.state !== "") ||
        (filteredAddress?.country !== address?.country &&
          address?.country !== "") ||
        (filteredAddress?.postal_code !== address?.postal_code &&
          address?.postal_code !== "") || (filteredAddress?.title !== address?.title && address?.title !== "" )
      : (
        address?.title !== undefined &&
          address?.address_line_1 !== undefined &&
          address?.address_line_2 !== undefined &&
          address?.phone !== undefined &&
          address?.state !== undefined &&
          address?.city !== undefined &&
          address?.country !== undefined &&
          address?.postal_code !== undefined &&
          address?.locality !== undefined
        ) && (address?.title !== "" && 
          address?.address_line_1 !== "" &&
          address?.address_line_2 !== "" &&
          address?.phone !== "" &&
          address?.state !== "" &&
          address?.city !== "" &&
          address?.country !== "" &&
          address?.postal_code !== "" &&
          address?.locality !== "")

  console.log(
    (
      address?.address_line_1 !== undefined &&
      address?.address_line_2 !== undefined &&
      address?.phone !== undefined &&
      address?.state !== undefined &&
      address?.city !== undefined &&
      address?.country !== undefined &&
      address?.postal_code !== undefined &&
      address?.locality !== undefined
    ),
    address?.address_line_1,
    address?.address_line_2,
    address?.phone,
    address?.state,
    address?.city,
    address?.country,
    address?.postal_code,
    address?.locality ,
    "true or false check"
  );

  // const isSaveButtonEnabled = () => {
  //   if (address && filteredAddress) {
  //     for (let e in address) {
  //       console.log(" isSaveButtonEnabled: ");
  //       if (address[e] !== filteredAddress[e] && address[e] !== "") {
  //         return true;
  //       }
  //     }
  //   } else if (address && !filteredAddress) {
  //     for (let e in address) {
  //       console.log(" isSaveButtonEnabled: ");
  //       if (address[e] !== "") {
  //         return true;
  //       }
  //     }
  //   }
  // };
  // useEffect(() => {
  //   setButtonEnabled(isSaveButtonEnabled());
  // }, [address]);
  const goToPost = (slug) => {
    // console.log(slug);
    navigation.navigate({ name: "post", params: { slug: slug } });
  };
  const extractContent = (s, space) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  };

  const onUpdateAddress = async () => {
    const data = {
      id: address?.id,
      lat: address.lat || 0,
      lng: address.lng || 0,
      title:address.title,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      locality: address?.locality,
      landmark: address?.landmark || "landmark",
      city: address?.city,
      state: address?.state,
      country: address?.country,
      postal_code: address?.postal_code,
      phone: address?.phone,
    };
    try {
      setIsUserLoading(true);
      if (route.params.id && !route.params.is_billing) {
        const res = await patchUserShippingAddresses({
          token: userToken,
          address: data,
        });
        if (res.status === 200) {
          const index = userAddressList.findIndex(
            (e) => e.id === route.params.id
          );
          if (index > -1) {
            let updated = [...userAddressList];
            updated[index].address = data;
            setUserAddressList(updated);
          }
          setAddress(data);
          setModal({ isVisible: true, message: res.data.message });
        } else {
          setModal({ isVisible: true, message: res.data.message });
          console.log("update-address, onUpdateAddress res error : ", res.data);
        }
      } else if (!route.params.id && !route.params.is_billing) {
        const res = await postUserShippingAddresses({
          token: userToken,
          address: { ...data, make_default: addressIsDefault },
        });
        if (res.status === 201) {
          let updated = [...userAddressList];
          if (res.data.data.is_default) {
            const index = userAddressList.findIndex(
              (e) => e.is_default === true
            );
            if (index > -1) {
              updated[index].is_default = false;
            }
          }
          let obj = {
            ...res.data.data,
          };
          delete obj.user;
          updated.push(obj);
          setUserAddressList(updated);
          setAddress(obj);
          setModal({ isVisible: true, message: res.data.message });
        } else {
          setModal({ isVisible: true, message: res.data.message });
          console.log("update-address, onAddAddress res error : ", res.data);
        }
      } else if (route.params.id && route.params.is_billing) {
        const res = await patchUserBillingAddresses({
          token: userToken,
          address: data,
        });
        if (res.status === 200) {
          setUserBillingAddress({ address: data });
          setAddress({ address: data });
          setModal({ isVisible: true, message: res.data.message });
        } else {
          setModal({ isVisible: true, message: res.data.message });
          console.log(
            "update-address, billing onUpdateAddress res error : ",
            res.data
          );
        }
      } else if (!route.params.id && route.params.is_billing) {
        const res = await postUserBillingAddress({
          token: userToken,
          address: { ...data, use_for_shipping: addressIsDefault },
        });
        if (res.status === 201) {
          const data = { ...res.data.data.billing };
          delete data.user;
          setUserBillingAddress(data);
          setAddress(data);
          setModal({ isVisible: true, message: res.data.message });
        } else {
          setModal({ isVisible: true, message: res.data.message });
          console.log(
            "update-address, billing onUpdateAddress res error : ",
            res.data
          );
        }
      }
    } catch (error) {
      console.log("update-address, onAddAddress error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-app-b">
      <ScrollView
        className="p-4 rounded-t-2xl bg-app-b"
        // overScrollMode="never"
        keyboardShouldPersistTaps="always"
      >
        <View className="max-w-screen-lg w-full mx-auto items-start">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              {addressState == "add" ? "Add Address" : "Update Address"}
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card>
                <View className="flex-1 w-full">
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="Title"
                      placeholder="Home , Office..."
                      value={address?.title}
                      setValue={(e) => {
                        setAddress({ ...address, title: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="Address line 1"
                      value={address?.address_line_1}
                      setValue={(e) => {
                        setAddress({ ...address, address_line_1: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="Address line 2"
                      value={address?.address_line_2}
                      setValue={(e) => {
                        setAddress({ ...address, address_line_2: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="Locality"
                      value={address?.locality}
                      setValue={(e) => {
                        setAddress({ ...address, locality: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="City"
                      value={address?.city}
                      setValue={(e) => {
                        setAddress({ ...address, city: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="State"
                      value={address?.state}
                      setValue={(e) => {
                        setAddress({ ...address, state: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                      type="text"
                      label="Country"
                      value={address?.country}
                      setValue={(e) => {
                        setAddress({ ...address, country: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                     maxLength={12}
                      type="text"
                      label="Post Code"
                      value={address?.postal_code}
                      setValue={(e) => {
                        setAddress({ ...address, postal_code: e });
                      }}
                    ></Input>
                  </View>
                  <View className="flex-row w-full px-4 py-1">
                    <Input
                     maxLength={12}
                      type="number"
                      label="Recepient Phone"
                      value={address?.phone}
                      setValue={(e) => {
                        setAddress({ ...address, phone: e });
                      }}
                    ></Input>
                  </View>
                  {!route.params.id && (
                    <View className="flex-row justify-between w-full px-4 py-1 border-b border-gray-200 pb-4">
                      <Font className="flex-1 text-app-d1" weight={600}>
                        {!route.params.is_billing
                          ? "Set as default shipping address"
                          : "Use same for shipping"}
                      </Font>
                      <View className="">
                        <Input
                          type="toggle"
                          value={addressIsDefault}
                          setValue={setAddressIsDefault}
                        ></Input>
                      </View>
                    </View>
                  )}
                  {/* <View className="w-full h-60 p-4">
                    <Maps disabled={false}></Maps>
                  </View> */}
                  {isUserLoading ? (
                    <View className="flex-1 w-full px-4 pt-4">
                      <View className="w-full flex-1 lg:w-1/2 bg-app-e h-10 rounded-lg justify-center">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.a}
                        ></ActivityIndicator>
                      </View>
                    </View>
                  ) : (
                    <View className="w-full px-4 pt-4 justify-center items-center">
                      <View className="w-full lg:w-3/5 justify-center">
                        <Button
                          type={`${isButtonEnabled ? "primary" : "disabled"}`}
                          label="Save"
                          onPress={
                            isButtonEnabled ? () => onUpdateAddress() : () => {}
                          }
                        ></Button>
                      </View>
                    </View>
                  )}
                </View>
              </Card>
            </View>
            <View className="hidden lg:flex w-full lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="flex-1 w-full p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      {modal.isVisible && (
        <View>
          <ResponseModal
            modalContent={modal.message}
            modalVisible={modal.isVisible}
            setVisible={(e) => setModal({ ...modal, isVisible: e })}
            primaryButtonLabel="Dismiss"
            onPrimaryButtonPress={() => {
              setModal({ isVisible: false, message: null });
              navigation.goBack();
            }}
          ></ResponseModal>
        </View>
      )}
    </View>
  );
};
