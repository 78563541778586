import React, { useContext } from "react";
import {
  Image,
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Input } from "../../components/input";
import { Unautorized } from "./unautorized";
import { AuthContext } from "../../context/authContext";
import { Widget } from "../../components/widget";
import { useEffect } from "react";
import { OrdersContext } from "../../context/ordersContext";
import { DEV_API, getOrderById, postCancelOrder } from "../../services/api";
import { ActivityIndicator } from "react-native";
import Toast from "react-native-toast-message";
import { UserContext } from "../../context/userContext";
import { MaterialIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { useState } from "react";
import { ResponseModal } from "../../components/response-modal";
import * as Linking from "expo-linking";

export const OrderDetails = ({ navigation, route }) => {
  const { user, userToken } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const { orders, orderDetails, setOrderDetails } = useContext(OrdersContext);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Test Modal",
    orderId: null,
  });
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const getUserOrderById = async () => {
    try {
      setIsLoading(true);
      const res = await getOrderById(userToken, route.params.id);
      if (res.status === 200) {
        setOrderDetails(res.data.data);
      } else {
        setOrderDetails(null);
        console.log("order-details, getUserOrderById res error : ", res.data);
      }
    } catch (error) {
      setOrderDetails(null);
      console.log("order-details, getUserOrderById error : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null && route.params.id) {
      getUserOrderById();
    }
  }, [user, userToken]);

  const onCancelOrder = async (productOrderId) => {
    try {
      setIsLoading(true);
      const res = await postCancelOrder({
        token: userToken,
        productOrderId,
      });
      if (res.status === 200) {
        const index = orderDetails.product_orders.findIndex(
          (e) => e.id === productOrderId
        );
        if (index > -1) {
          const updated = { ...orderDetails };
          updated.product_orders[index].status = "cancelled_by_customer";
          setOrderDetails(updated);
        }
        setModal({ ...modal, orderId: null });
        showToast("success", "Success", res.data?.message || "Successful");
      } else {
        showToast("error", "Error", res.data.message);
        console.log("order-details, onCancelOrder res error : ", res.data);
      }
    } catch (error) {
      console.log("order-details, onCancelOrder error : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl"
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true);
                await getUserOrderById();
                setRefreshing(false);
              }}
            />
          }
        >
          <View className="flex-1 w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-xl font-semibold text-app-d">
                  Order Details
                </Font>
              </View>

              <View className="w-full flex-col lg:flex-row">
                {orderDetails && !isLoading ? (
                  <View className="w-full flex-1 ">
                    <View className="flex-1 w-full">
                      <Card
                        title="Order data"
                        // Widget={() => {
                        //   return (
                        //     <View className="rounded-full bg-app-h2 px-4 py-1">
                        //       <Font className="text-app-h">
                        //         status
                        //         {/* {orderDetails.status} */}
                        //       </Font>
                        //     </View>
                        //   );
                        // }}
                      >
                        <View className="flex-col w-full p-4 pt-0 divide-y divide-gray-200">
                          <View className="flex-row w-full pb-3 items-center">
                            <Font>Order id : #{orderDetails.id}</Font>
                          </View>
                          <View className="flex-1 w-full py-4">
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Name
                              </Font>
                              <View className="justify-end flex-1 w-full">
                                <Font
                                  className="text-app-d1 w-full text-right"
                                  weight={500}
                                >
                                  {userData?.first_name +
                                    " " +
                                    userData?.last_name}
                                </Font>
                              </View>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Email
                              </Font>
                              <View className="justify-end flex-1 w-full">
                                <Font
                                  className="text-app-d1 w-full text-right"
                                  weight={500}
                                >
                                  {userData?.email}
                                </Font>
                              </View>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Contact
                              </Font>
                              <View className="justify-end flex-1 w-full">
                                <Font
                                  className="text-app-d1 w-full text-right"
                                  weight={500}
                                >
                                  {userData?.phone}
                                </Font>
                              </View>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Address
                              </Font>
                              <View className="justify-end flex-1 w-full">
                                <Font
                                  className="text-app-d1 w-full text-right"
                                  weight={500}
                                >
                                  {orderDetails?.shipping_address &&
                                  orderDetails?.shipping_address?.address
                                    ? `${orderDetails.shipping_address.address?.locality}, ` +
                                      `${orderDetails.shipping_address.address?.landmark}, ` +
                                      `${orderDetails.shipping_address.address?.city}, ` +
                                      `${orderDetails.shipping_address.address?.state}, ` +
                                      `${orderDetails.shipping_address.address?.country}`
                                    : orderDetails?.billing_address &&
                                      orderDetails?.billing_address?.address
                                    ? `${orderDetails.billing_address.address?.locality}, ` +
                                      `${orderDetails.billing_address.address?.landmark}, ` +
                                      `${orderDetails.billing_address.address?.city}, ` +
                                      `${orderDetails.billing_address.address?.state}, ` +
                                      `${orderDetails.billing_address.address?.country}`
                                    : "-"}
                                </Font>
                              </View>
                            </View>
                          </View>
                          {/* <View className="flex-row w-full py-4">
                            <Font>
                              Address:{" "}
                              {orderDetails.shipping_address
                                ? `${orderDetails.shipping_address.address.locality}, ` +
                                  `${orderDetails.shipping_address.address.landmark}, ` +
                                  `${orderDetails.shipping_address.address.city}, ` +
                                  `${orderDetails.shipping_address.address.state}, ` +
                                  `${orderDetails.shipping_address.address.country}`
                                : `${orderDetails.billing_address.address.locality}, ` +
                                  `${orderDetails.billing_address.address.landmark}, ` +
                                  `${orderDetails.billing_address.address.city}, ` +
                                  `${orderDetails.billing_address.address.state}, ` +
                                  `${orderDetails.billing_address.address.country}`}
                            </Font>
                          </View> */}
                        </View>
                      </Card>
                    </View>
                    <View className=" w-full">
                      <Card title="Order products">
                        <View className="flex-col w-full p-4 pt-0">
                          {orderDetails.product_orders.map((e, i) => (
                            <>
                              <View
                                className={`w-full rounded-lg border border-gray-200 bg-app-b divide-y divide-gray-200 ${
                                  orderDetails.product_orders.length > 1 &&
                                  orderDetails.product_orders.length !==
                                    i + 1 &&
                                  "mb-4"
                                }`}
                                key={`product_order_items_${i}`}
                              >
                                <View
                                  className={`flex-row px-2 justify-between items-center ${
                                    i !== 0 ? "py-3" : "py-3"
                                  }`}
                                >
                                  <Font className="text-app-d">
                                    Vendor id: #{e.vendor_id}
                                  </Font>
                                  <View
                                    className={`rounded-full px-4 py-1 ${
                                      e.status === "cancelled_by_vendor" ||
                                      e.status === "cancelled_by_customer"
                                        ? "bg-app-e2"
                                        : e.status === "delivered"
                                        ? "bg-app-g2"
                                        : " bg-app-h2"
                                    }`}
                                  >
                                    <Font
                                      className={`leading-4 py-0.5 ${
                                        e.status === "cancelled_by_vendor" ||
                                        e.status === "cancelled_by_customer"
                                          ? "text-app-e"
                                          : e.status === "delivered"
                                          ? "text-app-g"
                                          : " text-app-h"
                                      }`}
                                    >
                                      {e.status === "cancelled_by_vendor"
                                        ? "cancelled by vendor"
                                        : e.status === "cancelled_by_customer"
                                        ? "cancelled by customer"
                                        : e.status === "out for delivery"
                                        ? "out for delivery"
                                        : e.status === "read_for_pickup"
                                        ? "ready for pickup"
                                        : e.status}
                                    </Font>
                                  </View>
                                </View>
                                {e.product_order_items.map((ee, i) => (
                                  <View
                                    className={`w-full flex-1 px-2 py-3 justify-center`}
                                    key={`e.product_order_items_${i}`}
                                  >
                                    <View className="flex-row pb-3 w-full">
                                      <Font className="text-app-d">
                                        Product order id : #{e.id}
                                      </Font>
                                    </View>
                                    <View className="w-full flex-row justify-center">
                                      <View className="h-16 w-16 rounded overflow-hidden">
                                        <Image
                                          source={
                                            ee.ordered_variant?.cover_image_url
                                              ? {
                                                  uri: `${DEV_API}/media/${ee.ordered_variant.cover_image_url}`,
                                                }
                                              : require("../../../assets/bg.jpg")
                                          }
                                          className="w-full h-full object-contain "
                                        ></Image>
                                      </View>
                                      <View className="flex-1 px-4">
                                        <Font
                                          className="text-app-d"
                                          weight={700}
                                          numberOfLines={3}
                                        >
                                          {ee.ordered_variant?.title}
                                        </Font>
                                        <Font
                                          className="text-app-d1"
                                          weight={500}
                                        >
                                          $
                                          {ee.sale_price / 100 ??
                                            ee.price / 100}
                                        </Font>
                                        <Font
                                          className="text-app-d1"
                                          weight={500}
                                        >
                                          Qty : {ee.quantity}
                                        </Font>
                                      </View>
                                      <View className="p-2">
                                        <Font
                                          className="text-[15px] text-app-d"
                                          weight={700}
                                        >
                                          $ {ee.purchase_amount / 100}
                                        </Font>
                                      </View>
                                    </View>
                                  </View>
                                ))}

                                {e?.status &&
                                e?.status !== "cancelled_by_vendor" &&
                                e?.status !== "cancelled_by_customer" ? (
                                  <View className="flex-row w-full border-t border-gray-200 justify-between divide-gray-200">
                                    {![
                                      "cancelled_by_vendor",
                                      "cancelled_by_customer",
                                      "failed",
                                      "delivered",
                                    ].includes(e.status) ? (
                                      <Pressable
                                        className="w-full flex-1 flex-row items-center justify-center py-2"
                                        onPress={() =>
                                          setModal({
                                            isVisible: true,
                                            message: "Cancel the order?",
                                            orderId: e.id,
                                          })
                                        }
                                      >
                                        <MaterialIcons
                                          name="cancel"
                                          size={22}
                                          color={colors.app.e1}
                                        />
                                        <Font className="leading-4 ml-1.5">
                                          Cancel
                                        </Font>
                                      </Pressable>
                                    ) : (
                                      <></>
                                    )}
                                    {e?.tracking_link ? (
                                      <>
                                        <View className="h-full border-r border-app-c" />
                                        <Pressable
                                          className="w-full flex-1 flex-row items-center justify-center py-2"
                                          onPress={() => {
                                            Linking.openURL(
                                              /^(https?:\/\/)|^(?!https?:\/\/?)([a-zA-Z][a-zA-Z\d+\-.]*:\/{1,2}[^\/\s].*)/.test(
                                                e.tracking_link
                                              )
                                                ? e.tracking_link.replace(
                                                    /^(?!https?:\/\/)/,
                                                    "https://"
                                                  )
                                                : e.tracking_link
                                            ).catch((err) => {
                                              showToast(
                                                "error",
                                                "Can't open link on this device",
                                                err
                                              );
                                            });
                                          }}
                                        >
                                          <Entypo
                                            name="location-pin"
                                            size={22}
                                            color={colors.app.g}
                                          />
                                          <Font className="leading-4 ml-1">
                                            Track
                                          </Font>
                                        </Pressable>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </View>
                                ) : (
                                  <></>
                                )}
                              </View>
                              {[
                                "rejected",
                                "cancelled_by_customer",
                                "cancelled_by_vendor",
                              ].includes(e?.status) ? (
                                <View className="w-full flex-row items-center justify-center py-2 px-4 mt-2 border border-app-c rounded">
                                  <Font className="text-app-g1" weight={600}>
                                    {orderDetails?.payment_status === "refunded"
                                      ? "Your total return amount Successfully Refunded."
                                      : "Refund will be processed shortly."}
                                  </Font>
                                </View>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </View>
                      </Card>
                    </View>
                    <View className=" w-full ">
                      <Card title="Payment">
                        <View className="flex-1 w-full">
                          <View className="w-full flex-row flex-wrap p-4 pt-0">
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Payment Method
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                {["created"].includes(orderDetails.status)
                                  ? "- (Payment is still pending...)"
                                  : "-"}
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Total Order Amount
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                {`${
                                  orderDetails?.total_master_order_amount
                                    ? (orderDetails?.currency_symbol || "") +
                                      " " +
                                      orderDetails?.total_master_order_amount /
                                        100
                                    : orderDetails?.total_master_order_amount ??
                                      "-"
                                }`}
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d mr-1" weight={600}>
                                Applied Coupon:
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                {orderDetails?.applied_coupon?.coupon_code ||
                                  "-"}
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d mr-1" weight={600}>
                                Total Discount:
                              </Font>
                              <Font
                                className={
                                  orderDetails?.redeemed_rewards
                                    ?.discount_amount
                                    ? "text-app-g1"
                                    : "text-app-d1"
                                }
                                weight={500}
                              >
                                {orderDetails?.redeemed_rewards?.discount_amount
                                  ? `${orderDetails?.currency_symbol || ""} ${
                                      orderDetails?.redeemed_rewards
                                        ?.discount_amount / 100
                                    }`
                                  : orderDetails?.redeemed_rewards
                                      ?.discount_amount ?? true
                                  ? "-"
                                  : `${orderDetails?.currency_symbol || ""} ${
                                      orderDetails?.redeemed_rewards
                                        ?.discount_amount
                                    }`}
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Shipping Cost
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                -
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d mr-1" weight={600}>
                                Total Payment:
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                {`${
                                  orderDetails?.net_payable
                                    ? (orderDetails?.currency_symbol || "") +
                                      " " +
                                      orderDetails?.net_payable / 100
                                    : orderDetails?.net_payable ?? "-"
                                }`}
                              </Font>
                            </View>
                          </View>
                        </View>
                      </Card>
                      {/* <Card title="Shipment Info">
                        <View className="flex-1 w-full">
                          <View className="w-full flex-row flex-wrap p-4 pt-0">
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Shipper
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                13123
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d" weight={600}>
                                Tracking Number
                              </Font>
                              <Font className="text-app-d1" weight={500}>
                                13123
                              </Font>
                            </View>
                            <View className="w-full flex-row justify-between mb-2">
                              <Font className="text-app-d w-1/2" weight={600}>
                                Shipping Address
                              </Font>
                              <View className="justify-end flex-1 w-full">
                                <Font
                                  className="text-app-d1 w-full text-right"
                                  weight={500}
                                >
                                  {orderDetails.shipping_address &&
                                  orderDetails?.shipping_address?.address
                                    ? `${orderDetails.shipping_address.address?.locality}, ` +
                                      `${orderDetails.shipping_address.address?.landmark}, ` +
                                      `${orderDetails.shipping_address.address?.city}, ` +
                                      `${orderDetails.shipping_address.address?.state}, ` +
                                      `${orderDetails.shipping_address.address?.country}`
                                    : `${orderDetails.billing_address.address?.locality}, ` +
                                      `${orderDetails.billing_address.address?.landmark}, ` +
                                      `${orderDetails.billing_address.address?.city}, ` +
                                      `${orderDetails.billing_address.address?.state}, ` +
                                      `${orderDetails.billing_address.address?.country}`}
                                </Font>
                              </View>
                            </View>
                          </View>
                        </View>
                      </Card> */}
                      {orderDetails?.status === "completd" && (
                        <Card title="Review">
                          <View className="flex-1 w-full p-4 pt-0">
                            <View className="mb-4">
                              <Input type="rating"></Input>
                            </View>
                            <View>
                              <Input type="longtext"></Input>
                            </View>
                          </View>
                        </Card>
                      )}

                      {/* <View className="mb-4">
                        <Button
                          type="primary"
                          label="Cancel Booking"
                          onPress={onCancelOrder}
                        ></Button>
                      </View> */}
                    </View>
                  </View>
                ) : !orderDetails && !isLoading ? (
                  <View className="w-full flex-1">
                    <Font>No details found.</Font>
                  </View>
                ) : (
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                )}
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
      {modal.isVisible && (
        <View>
          <ResponseModal
            modalContent={modal.message}
            modalVisible={modal.isVisible}
            setVisible={(e) => setModal({ ...modal, isVisible: e })}
            primaryButtonLabel="Yes"
            onPrimaryButtonPress={() => {
              onCancelOrder(modal.orderId);
              setModal({ isVisible: false, message: null });
            }}
            secondaryButtonLabel="No"
            onSecondaryButtonPress={() => {
              setModal({ isVisible: false, message: null });
            }}
            showSecondaryButton={true}
          ></ResponseModal>
        </View>
      )}
    </View>
  ) : (
    <Unautorized />
  );
};
