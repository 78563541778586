import React from "react";
import { socket } from "../services/socket-io/index";
import { useContext } from "react";
import { AuthContext } from "./authContext";

export const emitEventHandler = ({ event, payload, cb = () => {} }) => {
  socket.emit(event, payload, (res) => cb(res));
};

const INITIAL_STATE = {
  socketConnectStatus: "disconnected",
  currentChatData: { data: null, currIndex: undefined, prevIndex: undefined },
  setCurrentChatData: () => null,
  resetCurrentChatData: () => null,
  channels: [],
  setChannels: () => [],
  setUpdateChannelUnreadCount: () => [],
  // channelMessages: null,
  // setChannelMessages: () => {},
  currentChannelMessages: [],
  setCurrentChannelMessages: () => [],
  resetCurrentChannelMessages: () => {},
  currentChannelMessagesPagination: { from: null, to: null, total: null },
  setCurrentChannelMessagesPagination: () => ({
    from: null,
    to: null,
    total: null,
  }),
  isTypingData: null,
  redirectChatUserID: null,
  setRedirectChatUserID: () => null,
  dispatchMessageEvents: {
    startedTypingMessage: ({ channel_id, cb }) => {
      emitEventHandler({
        event: "start-typing",
        payload: { channel_id },
        cb,
      });
    },
    stoppedTypingMessage: ({ channel_id, cb }) => {
      emitEventHandler({ event: "stop-typing", payload: { channel_id }, cb });
    },
    recordLastMessageRead: ({ channel_id, message_id }) => {
      emitEventHandler({
        event: "record-last-read",
        payload: { channel_id, message_id },
      });
    },
    deleteMessage: ({ channel_id, message_id }) => {
      emitEventHandler({
        event: "delete-message",
        payload: { channel_id, message_id },
      });
    },
    sendMessage: ({ channel_id, message }) => {
      emitEventHandler({
        event: "send-message",
        payload: { channel_id, message },
      });
    },
    joinChannel: ({ vendor_id, cb }) => {
      emitEventHandler({
        event: "join",
        payload: { vendor_id },
        cb,
      });
    },
  },
  resetMessagesContext: () => null,
};

let isModalShown = false,
  errorMessages = [];

export const MessagesContext = React.createContext(INITIAL_STATE);

const MessagesReducer = (state, action) => {
  switch (action.type) {
    case "SET_RDR_UID": {
      if (action.callback) {
        setTimeout(action.callback, 1000);
      }
      return { ...state, redirectChatUserID: action.payload };
    }
    case "SET_CURR_CH":
      return { ...state, currentChatData: action.payload };
    case "RESET_CURR_CHT_DATA":
      return {
        ...state,
        currentChatData: INITIAL_STATE.currentChatData,
      };
    case "RESET_CURR_CHN_MESGS":
      return {
        ...state,
        currentChannelMessages: INITIAL_STATE.currentChannelMessages,
      };
    case "SET_UPDATE_CURR_CHT_CHN_ON_MSG_RECEIVE": {
      let allChannels = [...(state.channels || [])];
      if (allChannels.length > 0) {
        const currentChannelIndex = allChannels.findIndex(
          (e) => e.id === action.payload.channel_id
        );
        if (currentChannelIndex > -1) {
          allChannels[currentChannelIndex].last_message = action.payload;
          if (!allChannels[currentChannelIndex].unread)
            allChannels[currentChannelIndex].unread =
              (allChannels[currentChannelIndex].unread || 0) + 1;
          let newMessage;
          if (
            state.currentChatData.data &&
            state.currentChatData?.data?.id === action.payload.channel_id
          ) {
            if (currentChannelIndex > 0) {
              const slicedArray = allChannels.slice(
                currentChannelIndex,
                currentChannelIndex + 1
              );
              allChannels.splice(currentChannelIndex, 1);
              allChannels.unshift(...slicedArray);
              // allChannels.unshift(
              //   ...allChannels.splice(currentChannelIndex, 1)
              // );
            }
            let currChatData = { ...state.currentChatData };
            currChatData.last_message = action.payload;
            currChatData.currIndex = 0;
            newMessage = {
              ...action.payload,
              sender_id: action.payload.sender.id,
              sender_role: action.payload.sender.role,
            };
            delete newMessage.sender;
            let currentChannelMessagesData = [
              ...(state.currentChannelMessages || []),
            ];
            // if (currentChannelMessagesData.length >= 20) {
            //   currentChannelMessagesData.pop();
            // }
            currentChannelMessagesData.unshift(newMessage);
            return {
              ...state,
              channels: allChannels,
              currentChatData: currChatData,
              currentChannelMessages: currentChannelMessagesData,
            };
          } else {
            allChannels[currentChannelIndex].unread =
              allChannels[currentChannelIndex].unread + 1;
            // let currChatData = { ...state.currentChatData };
            if (currentChannelIndex > 0) {
              const slicedArray = allChannels.slice(
                currentChannelIndex,
                currentChannelIndex + 1
              );
              allChannels.splice(currentChannelIndex, 1);
              allChannels.unshift(...slicedArray);
              // allChannels.unshift(
              //   ...allChannels.splice(currentChannelIndex, 1)
              // );
              // currChatData.currIndex = currentChannelIndex;
            }
            return {
              ...state,
              // currentChatData: currChatData,
              channels: allChannels,
            };
          }
        } else {
          allChannels.unshift(action.payload);
        }
      } else {
        allChannels.push(action.payload);
      }
    }
    case "SET_UPDATE_CURR_CHT_CHN_ON_DELETE_MSG":
      let updateCurrChannel = [...state.currentChannelMessages].filter(
        (e) => e.id !== action.payload.message_id
      );
      if (state.currentChatData?.data?.id === action.payload.channel_id) {
        let updateCurrChannelLastMsg = [...state.channels];
        const index = updateCurrChannelLastMsg.findIndex(
          (e) => e.id === action.payload.channel_id
        );
        if (index > -1) {
          updateCurrChannelLastMsg[index].last_message =
            updateCurrChannel[updateCurrChannel.length - 1];
          return {
            ...state,
            channels: updateCurrChannelLastMsg,
            currentChannelMessages: updateCurrChannel,
          };
        }
      } else {
        return {
          ...state,
          currentChannelMessages: updateCurrChannel,
        };
      }
    case "SET_UPDATE_CH_UNREAD_COUNT": {
      if (state.channels.length > 0) {
        let channels = [...state.channels];
        const index = channels.findIndex((e) => e.id === action.payload.id);
        if (index > -1) {
          channels[index].unread = 0;
        }
        return { ...state, channels: channels };
      }
    }
    case "TYPING_STARTED": {
      if (state.channels.length > 0) {
        // console.log("------------------action.payload : ", action.payload);
        let channels = [...state.channels];
        const index = channels.findIndex(
          (e) => e.id === action.payload.channel_id
        );
        if (index > -1) {
          return {
            ...state,
            isTypingData: { ...action.payload },
          };
        }
      }
    }
    case "TYPING_STOPPED": {
      if (state.channels.length > 0) {
        let channels = [...state.channels];
        const index = channels.findIndex(
          (e) => e.id === action.payload.channel_id
        );
        if (index > -1) {
          return {
            ...state,
            isTypingData: null,
          };
        }
      }
    }
    case "SET_CHS":
      return { ...state, channels: action.payload };
    case "SET_CURR_CH_MESGS":
      return { ...state, currentChannelMessages: action.payload };
    case "SET_CURR_CH_MESGS_PAGINATION":
      return { ...state, currentChannelMessagesPagination: action.payload };
    case "RESET_MESSAGES_STATE":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const MessagesProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(MessagesReducer, INITIAL_STATE);
  const { user, userToken } = useContext(AuthContext);
  const [socketConnectStatus, setSocketConnectStatus] = React.useState(
    socket.connected ? "connected" : "disconnected"
  ); // 'connected', 'authenticated', 'disconnected'
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  React.useLayoutEffect(() => {
    if (!socket.connected) {
      (async () => {
        if (user && userToken) {
          socket.connect();
        }
      })();
    }

    function errorHandler(errorText) {
      errorMessages.push(errorText);
      // const showModal = () => {
      //   isModalShown = true;
      //   setShowResponseModal({
      //     visible: true,
      //     onRequestClose: () => {
      //       if (errorMessages.length > 1) {
      //         errorMessages.pop();
      //         setTimeout(showModal, 300);
      //       } else {
      //         isModalShown = false;
      //       }
      //       setShowResponseModal({
      //         visible: false,
      //         onRequestClose: () => null,
      //         bodyText: "",
      //       });
      //     },
      //     bodyText: errorMessages[errorMessages.length - 1] || "",
      //   });
      // };
      // if (!isModalShown) {
      //   showModal();
      // }
    }

    function onConnect() {
      setSocketConnectStatus("connected");
      console.log("isConnected to Socket.io:", socket.connected);
      ((auth_token) => {
        if (auth_token) {
          socket.emit(
            "auth",
            {
              token: auth_token,
            },
            (response) => {
              if (response.status === "error") {
                console.log(
                  `${response.error.event}: ${response.error.message}`
                );
                return errorHandler(
                  `${response.error.event}: ${response.error.message}`
                );
              } else {
                setSocketConnectStatus("authenticated");
                console.log("ws server auth response : ", response);
              }
            }
          );
        }
      })(userToken);
    }

    function onMessageDeleted(data) {
      console.log("Message Deleted data:", data);
      dispatch({
        type: "SET_UPDATE_CURR_CHT_CHN_ON_DELETE_MSG",
        payload: data,
      });
    }

    function onMessageArrived(data) {
      console.log("Message Arrived data:", data);
      dispatch({
        type: "SET_UPDATE_CURR_CHT_CHN_ON_MSG_RECEIVE",
        payload: data,
      });
    }

    function onMessage(data) {
      console.log("Message : ", data);
    }

    function onTypingStarted(data) {
      console.log("Typing Started data:", data);
      dispatch({
        type: "TYPING_STARTED",
        payload: data,
      });
    }

    function onTypingStopped(data) {
      console.log("Typing Stopped data:", data);
      dispatch({
        type: "TYPING_STOPPED",
        payload: data,
      });
    }

    function onResponse(data) {
      console.log("response : ", data);
    }

    function onConnectionError() {
      console.log("Impossible to establish the socket.io connection");
      setSocketConnectStatus("disconnected");
    }

    function onDisconnect() {
      setSocketConnectStatus("disconnected");
    }

    socket.on("connect", onConnect);
    socket.on("message", onMessage);
    socket.on("message-deleted", onMessageDeleted);
    socket.on("new-message", onMessageArrived);
    socket.on("typing-started", onTypingStarted);
    socket.on("typing-stopped", onTypingStopped);
    socket.on("response", onResponse);
    socket.on("connect_error", onConnectionError);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("message", onMessage);
      socket.off("message-deleted", onMessageDeleted);
      socket.off("new-message", onMessageArrived);
      socket.off("typing-started", onTypingStarted);
      socket.off("typing-stopped", onTypingStopped);
      socket.off("response", onResponse);
      socket.off("connect_error", onConnectionError);
      socket.off("disconnect", onDisconnect);
    };
  }, [user, userToken]);

  const moveChatAtTopOfListAndGiveCallback = ({
    cb,
    lastMesgId,
    lastIndex,
  }) => {
    let chatsList = state.channels;
    let removedChat = chatsList.splice(state.currentChatData?.currIndex, 1);
    removedChat.prevIndexes = {
      ...removedChat.prevIndexes,
      [lastMesgId]: lastIndex,
    };
    chatsList.unshift(...removedChat);
    dispatch({
      type: "SET_CHS",
      payload: chatsList,
    });
    cb(removedChat.prevIndexes);
  };

  return (
    <MessagesContext.Provider
      value={{
        socketConnectStatus: socketConnectStatus,
        isTypingData: state.isTypingData,
        currentChatData: state.currentChatData,
        setCurrentChatData: ({ data, currIndex, prevIndex }) => {
          const prevChatData = state.currentChatData;
          dispatch({
            type: "SET_CURR_CH",
            payload: {
              data: data,
              currIndex,
              prevIndex: prevIndex || null,
            },
          });
          // if (prevChatData) {
          //   const prevChatID = prevChatData.data?.id;
          //   if (prevChatID) {
          //     dispatch({
          //       type: "SET_CH_MESGS",
          //       payload: {
          //         ...(channels || {}),
          //         [prevChatID]: {
          //           messages: [
          //             ...(channels[prevChatID] || []),
          //             ...state.currentChannelMessages,
          //           ],
          //           pagination: state.currentChannelMessagesPagination,
          //           prevIndexes: prevChatData.prevIndexes,
          //         },
          //       },
          //     });
          //   }
          // }
          // const new_ch_msgs = state.channelMessages[data.id];
          // if (new_ch_msgs) {
          //   dispatch({
          //     type: "SET_CURR_CH_MESGS",
          //     payload: new_ch_msgs.messages || [],
          //   });
          //   const len = new_ch_msgs.messages?.length || 0;
          //   dispatch({
          //     type: "SET_CURR_CH_MESGS_PAGINATION",
          //     payload: new_ch_msgs.pagination || {
          //       from: 1,
          //       to: len,
          //       total: len,
          //     },
          //   });
          // }
        },
        resetCurrentChatData: () => {
          // state.currentChatData = INITIAL_STATE.currentChatData;
          dispatch({ type: "RESET_CURR_CHT_DATA" });
        },
        resetCurrentChannelMessages: () => {
          // state.currentChatData = INITIAL_STATE.currentChatData;
          dispatch({ type: "RESET_CURR_CHN_MESGS" });
        },
        currentChannelMessages: state.currentChannelMessages,
        setCurrentChannelMessages: ({ data, push = false }) => {
          data = Array.isArray(data) ? data : [data];
          dispatch({
            type: "SET_CURR_CH_MESGS",
            payload: push ? [...state.currentChannelMessages, ...data] : data,
          });
          // const currChatData = state.currentChatData;
          // if (currChatData.currIndex !== 0) {
          //   const currMsgsLen = state.currentChannelMessages?.length;
          //   moveChatAtTopOfListAndGiveCallback({
          //     lastMesgId: currMsgsLen
          //       ? state.currentChannelMessages[currMsgsLen - 2].id
          //       : -1,
          //     lastIndex: currMsgsLen ? currMsgsLen - 2 : -1,
          //     cb: (prevIndexes) =>
          //       dispatch({
          //         type: "SET_CURR_CH",
          //         payload: {
          //           data: currChatData.data,
          //           currIndex: 0,
          //           prevIndex: prevIndexes,
          //         },
          //       }),
          //   });
          // }
        },
        updateCurrentChannelMessages: ({ data, push = false }) => {
          if (data.channel_id === state.currentChatData?.data.id) {
            let currChMsgs = [...state.currentChannelMessages];
            const currChMesgsLen = currChMsgs.length;
            for (const i = currChMesgsLen; i > -1; i--) {
              if (
                (currChMsgs[i].id + "").includes("local") &&
                currChMsgs[i].message === data.message
              )
                currChMsgs[i];
            }
            dispatch({
              type: "SET_CURR_CH_MESGS",
              payload: push ? [...currChMsgs, ...data] : data,
            });
          }
        },
        currentChannelMessagesPagination:
          state.currentChannelMessagesPagination,
        setCurrentChannelMessagesPagination: ({ from, to, total }) => {
          dispatch({
            type: "SET_CURR_CH_MESGS_PAGINATION",
            payload: { from, to, total },
          });
        },
        channels: state.channels,
        setChannels: ({ data, unshift = false, push = false }) => {
          data = Array.isArray(data) ? data : [data];
          dispatch({
            type: "SET_CHS",
            payload: unshift
              ? [...data, ...(state.channels || [])]
              : push
              ? [...(state.channels || []), ...data]
              : data,
          });
        },
        setUpdateChannelUnreadCount: ({ id }) => {
          dispatch({
            type: "SET_UPDATE_CH_UNREAD_COUNT",
            payload: id,
          });
        },
        dispatchMessageEvents: state.dispatchMessageEvents,
        // channelMessages: state.channelMessages,
        // setChannelMessages: ({
        //   addMesg: { channel_id, messages, push = false, to },
        //   delMesg = { mesg_id, channel_id },
        // }) => {
        //   let channels = state.channelMessages;
        //   let settingChannel = channels[channel_id];
        //   if (delMesg.mesg_id) {
        //     channels[channel_id].messages = channels[channel_id].messages.filter((m) => m.id !== delMesg.mesg_id);
        //     // channels[channel_id].pagination = {...settingChannel.pagination, to: settingChannel.pagination.to }
        //     dispatch({
        //       type: "SET_CH_MESGS",
        //       payload: {
        //         ...(channels || {}),
        //         [channel_id]: push
        //           ? {
        //               messages: setngChMesgs,
        //               pagination: {
        //                 ...(settingChannel?.pagination || {
        //                   from: null,
        //                   to: null,
        //                   total: null,
        //                 }),
        //                 from: 1,
        //                 to: to || setngMesgsLen,
        //                 total: setngMesgsLen,
        //               },
        //             }
        //           : data,
        //       },
        //     });
        //   } else if (addMesg.channel_id) {
        //     messages = Array.isArray(messages) ? messages : [messages];
        //     const setngChMesgs = [
        //       ...(settingChannel?.messages || []),
        //       ...messages,
        //     ];
        //     const setngMesgsLen = setngChMesgs.length;
        //     dispatch({
        //       type: "SET_CH_MESGS",
        //       payload: {
        //         ...(channels || {}),
        //         [channel_id]: push
        //           ? {
        //               messages: setngChMesgs,
        //               pagination: {
        //                 ...(settingChannel?.pagination || {
        //                   from: null,
        //                   to: null,
        //                   total: null,
        //                 }),
        //                 from: 1,
        //                 to: to || setngMesgsLen,
        //                 total: setngMesgsLen,
        //               },
        //             }
        //           : data,
        //       },
        //     });
        //     const currChatData = state.currentChatData;
        //     if (
        //       currChatData.data.id !== channel_id &&
        //       currChatData.currIndex !== 0
        //     ) {
        //        const currMsgsLen = state.currentChannelMessages?.length;
        //        moveChatAtTopOfListAndGiveCallback({
        //          lastMesgId: currMsgsLen
        //            ? currChatData[currMsgsLen - 2].id
        //            : -1,
        //        lastIndex: currMsgsLen ? currMsgsLen - 2 : -1,
        //        if(currChatData.currIndex) {
        //         const prevIndexesLen = currChatData.prevIndex.length;
        //         dispatch({
        //           type: "SET_CURR_CH",
        //           payload: {
        //             data: currChatData.data,
        //             currIndex: currChatData.currIndex + 1,
        //             prevIndex: prevIndexesLen ? currChatData.prevIndex[prevIndexesLen-1] + 1 : [],
        //           },
        //         })
        //        }
        //       }});
        //     }
        //   }
        // },
        redirectChatUserID: state.redirectChatUserID,
        setRedirectChatUserID: ({ uid, callback = () => null }) => {
          dispatch({ type: "SET_RDR_UID", payload: uid, callback });
        },
        resetMessagesContext: () => {
          dispatch({ type: "RESET_MESSAGES_STATE" });
        },
      }}
    >
      {/* {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )} */}
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => React.useContext(MessagesContext);
