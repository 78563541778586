import React, { useEffect, useRef, useState } from "react";

function zero(num, totalLength) {
  return String(num).padStart(totalLength, "0");
}

import {
  ActivityIndicator,
  Modal,
  Platform,
  Pressable,
  Text,
  TextInput,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "./font";
import colors from "../../colors";
import axios from "axios";
import { Keyboard } from "react-native";
import { TouchableOpacity, ScrollView } from "react-native";

export const Switcher = ({
  value,
  setValue = () => null,
  options = [],
  label = null,
}) => {
  return (
    <>
      {label && (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      )}
      <View className="w-full h-10 rounded-lg flex-row overflow-hidden border border-gray-200 divide-x divide-gray-200">
        {options?.map((e, i) => (
          <Pressable
            className={`h-full flex-1 items-center justify-center ${
              value == e?.value
                ? "bg-app-e active:bg-red-600"
                : "bg-white active:bg-gray-100  "
            }`}
            onPress={() => setValue(e?.value)}
            key={i}
          >
            <Font
              weight={600}
              className={`${value === e.value ? "text-app-a" : "text-app-e"}`}
            >
              {e?.label}
            </Font>
          </Pressable>
        ))}
      </View>
    </>
  );
};

export const Button = ({
  type = "regular",
  shape = "rect",
  iconName,
  leftIcon = null,
  rightIcon = null,
  label = "",
  onPress = null,
  height = "h-10",
  waitState,
  disabled,
  showLoaderWithText,
  loaderWithText = "",
  style,
  children,
}) => {
  const types = {
    disabled: {
      bg: "bg-app-c",
      bgActive: "active:bg-gray-100",
      text: "text-app-d1",
      border: "border-gray-200 ",
    },
    regular: {
      bg: "bg-app-a",
      bgActive: "active:bg-gray-100",
      bgDisabled: "bg-app-a/40",
      text: "text-app-d1",
      border: "border-gray-200 ",
      textDisabled: "text-app-d1/90",
      color: colors.app.d1,
    },
    primary: {
      bg: "bg-app-e",
      bgActive: "active:bg-app-e/70",
      bgDisabled: "bg-app-e/40",
      text: "text-app-a",
      border: "border-gray-200 ",
      circButtonText: "text-app-d1",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    primary_light: {
      bg: "bg-app-e3",
      bgActive: "active:bg-red-600",
      text: "text-app-e",
      border: "border-app-e",
    },
    accent: {
      bg: "bg-app-d",
      bgActive: "active:bg-d1",
      bgDisabled: "bg-app-d/40",
      text: "text-app-a",
      border: "border-gray-200 ",
      circButtonText: "text-app-d1",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    accent_light: {
      bg: "bg-app-d3",
      bgActive: "active:bg-d1",
      text: "text-app-d",
      border: "border-app-d ",
    },
    success: {
      bg: "bg-app-f",
      bgActive: "active:bg-green-600",
      bgDisabled: "bg-app-f/40",
      text: "text-app-a",
      border: "border-gray-200 ",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    info: {
      bg: "bg-app-g",
      bgActive: "active:bg-blue-600",
      bgDisabled: "bg-app-g/40",
      text: "text-app-a",
      border: "border-gray-200 ",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    warning: {
      bg: "bg-app-h",
      bgActive: "active:bg-yellow-600",
      bgDisabled: "bg-app-h/40",
      text: "text-app-a",
      border: "border-gray-200 ",
      textDisabled: "text-app-a/90",
      color: colors.app.a,
    },
    "regular-outline": {
      bg: "border-gray-200 bg-app-a3",
      bgActive: "active:bg-gray-100",
      bgDisabled: "bg-app-a/40",
      text: "text-app-d1",
      border: "",
      textDisabled: "text-app-d1/90",
      color: colors.app.d1,
    },
    "primary-outline": {
      bg: "border-app-e bg-app-a",
      bgActive: "active:bg-app-e2",
      bgDisabled: "bg-app-d/40",
      text: "text-app-e1",
      circButtonText: "text-app-e1",
      textDisabled: "text-app-e/90",
      color: colors.app.e,
    },
    "accent-outline": {
      bg: "border-app-d",
      bgActive: "active:bg-d2",
      bgDisabled: "bg-app-d/40",
      text: "text-app-d",
      border: "",
      textDisabled: "text-app-d/90",
      color: colors.app.a,
    },
    "success-outline": {
      bg: "border-app-f",
      bgActive: "active:bg-green-600",
      bgDisabled: "bg-app-f/40",
      text: "text-app-f",
      border: "",
      textDisabled: "text-app-f/90",
      color: colors.app.f,
    },
    "info-outline": {
      bg: "border-app-g",
      bgActive: "active:bg-app-g2",
      bgDisabled: "bg-app-g/40",
      text: "text-app-g active:text-app-a",
      border: "",
      textDisabled: "text-app-g/90",
      color: colors.app.g,
    },
    "warning-outline": {
      bg: "border-app-h",
      bgActive: "active:bg-yellow-600",
      bgDisabled: "bg-app-h/40",
      text: "text-app-h",
      border: "",
      textDisabled: "text-app-h/90",
      color: colors.app.a,
    },
  };
  return shape === "circ" ? (
    <View className="justify-center items-center" style={style}>
      <Pressable
        className={`rounded-full h-14 w-14 border border-gray-200 justify-center items-center ${types[type].bg} ${types[type].bgActive} ${types[type].text}`}
        onPress={waitState ? () => null : onPress}
        disabled={disabled}
      >
        {waitState ? (
          <ActivityIndicator
            animating={waitState}
            size="small"
            color={types[type].color}
          />
        ) : (
          <RemixIcon
            className={`${types[type].text}`}
            name={iconName}
            size={24}
            color={types[type].color}
          />
        )}
      </Pressable>
      <Font
        className={`text-app-d1 break-words whitespace-normal bg-white/80 ${types[type].circButtonText}`}
      >
        {label}
      </Font>
    </View>
  ) : (
    <Pressable
      onPress={waitState ? () => null : onPress}
      style={style}
      className={`rounded-lg w-full flex items-center justify-center border ${
        disabled ? types[type]?.bgDisabled : types[type]?.bg
      } ${types[type]?.bgActive} ${types[type]?.text} ${height} ${
        types[type]?.border
      }`}
      disabled={disabled}
    >
      <View className="flex-row items-center justify-center">
        {waitState ? (
          <ActivityIndicator
            animating={waitState}
            size="small"
            color={types[type].color}
            className={
              loaderWithText || showLoaderWithText ? "mr-1.5 -ml-0.5" : ""
            }
          />
        ) : (
          <>
            {leftIcon === null ? null : (
              <RemixIcon
                name={leftIcon.name}
                size={16}
                className="mr-2"
                color={leftIcon.color}
              ></RemixIcon>
            )}
          </>
        )}
        {(waitState && (loaderWithText || showLoaderWithText)) ||
        children ||
        label ? (
          <Font
            weight={600}
            className={`leading-4 ${
              disabled ? types[type].textDisabled : types[type].text
            }`}
          >
            {waitState
              ? loaderWithText || (showLoaderWithText ? children || label : "")
              : children || label}
          </Font>
        ) : (
          <></>
        )}
        {rightIcon === null ? null : (
          <RemixIcon
            name={rightIcon.name}
            size={16}
            className="ml-2"
            color={rightIcon.color || types[type].color}
          ></RemixIcon>
        )}
      </View>
    </Pressable>
  );
};

export const Input = ({
  label = null,
  label1 = null,
  type = "text",
  placeholder = "",
  value = null,
  onChangeText = null,
  setValue = () => null,
  action = null,
  validation = false,
  note = null,
  config = null,
  style = [],
  isValid = null,
  isIncrementDisabled = false,
  isDecrementDisabled = false,
  maxValue = 1,
  isSubmitting = false,
  ...props
}) => {
  props = {
    ...props,
    placeholderTextColor: "#94a3b8",
    editable: props.editable ?? true,
  };
  const [secret, setSecret] = useState(true);
  const [valid, setValid] = useState(null);
  useEffect(() => {
    if (type === "openinghour") {
      if (!value.length) {
        setValue([{ open: "", close: "" }]);
      }
    }
  }, []);

  const checkIsValid = (input) => {
    if (input !== null) {
      if (validation) {
        let mail_format = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/;
        let pass_format =
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
        switch (type) {
          case "email":
            if (input == "" || input.match(mail_format)) {
              setValid(true);
              return true;
            } else {
              setValid(false);
              return false;
            }
          case "password":
            if (input == "" || input.match(pass_format)) {
              setValid(true);
              return true;
            } else {
              setValid(false);
              return false;
            }
          default:
            break;
        }
      }
    }
  };

  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
          {label1 !== null ? (
            <Font className="flex-1 text-app-d1" weight={600}>
              {label1}
            </Font>
          ) : null}
          {type === "checkbox" ? (
            <TouchableOpacity
              className={`w-4 h-4 rounded  items-center justify-center outline-none ${
                value ? "bg-red-500" : "bg-app-d3"
              }`}
              onPress={() => setValue(!value)}
            >
              {value ? (
                <RemixIcon name="check-fill" color={colors.app.a}></RemixIcon>
              ) : null}
            </TouchableOpacity>
          ) : null}
          {type === "toggle" ? (
            <Pressable
              className={`w-8 h-4 rounded-full justify-center ${
                value ? "bg-red-500 items-end" : "bg-app-d3 items-start"
              }`}
              onPress={() => setValue((e) => !e)}
            >
              <View className={`w-3 h-3 bg-white rounded-full mx-[2px]`}></View>
            </Pressable>
          ) : null}
          {type === "openinghour" ? (
            <Pressable
              className="w-12 h-10 items-center justify-center"
              onPress={() => setValue((e) => [...e, { open: "", close: "" }])}
            >
              <RemixIcon
                name="add-circle-fill"
                color={colors.app.g}
              ></RemixIcon>
            </Pressable>
          ) : null}

          {/* // openingHour: <Bar />,
                            // availability: <Bar />, */}
        </View>
      ) : (
        <>
          {type === "checkbox" ? (
            <TouchableOpacity
              className={`w-5 h-5 rounded  items-center justify-center ${
                value ? "bg-red-500" : "bg-app-d3"
              }`}
              onPress={() => setValue(!value)}
            >
              {value ? (
                <RemixIcon name="check-fill" color={colors.app.a}></RemixIcon>
              ) : null}
            </TouchableOpacity>
          ) : null}
          {type === "toggle" ? (
            <Pressable
              className={`w-8 h-5 rounded-full justify-center ${
                value ? "bg-red-500 items-end" : "bg-app-d3 items-start"
              }`}
              onPress={() => setValue((e) => !e)}
            >
              <View className={`w-4 h-4 bg-white rounded-full mx-[2px]`}></View>
            </Pressable>
          ) : null}
        </>
      )}
      {type === "text" ? (
        <View
          className={`rounded border border-gray-200 overflow-hidden h-10 bg-app-a ${
            props.editable ? "" : "bg-app-c3"
          }`}
        >
          {!!props?.leftText && (
            <Font className="leading-4 pl-3 text-app-d1">{props.leftText}</Font>
          )}
          <TextInput
            placeholder={placeholder}
            className={`flex-1 leading-4 h-full min-w-0 ${
              props.editable ? "" : "text-app-d/60"
            } ${props?.leftText ? "pl-2" : "pl-3"} ${
              props?.rightText ? "pr-2" : "pr-3"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
                outlineWidth: "0px",
              }
            }
            value={value ? value.toString() : ""}
            onChangeText={(e) => setValue(e)}
            editable={props.editable}
            {...props}
          ></TextInput>
          {!!props?.rightText && (
            <Font className="leading-4 pr-3 text-app-d1">
              {props.rightText}
            </Font>
          )}
        </View>
      ) : null}
      {type === "longtext" ? (
        <View
          className={`rounded border border-gray-200 overflow-hidden ${
            props.height ? props.height : "h-40"
          } bg-app-a ${props.editable ? "" : "bg-app-c3"}`}
        >
          <TextInput
            placeholder={placeholder}
            className={`flex-1 h-full align-start justify-start p-2 min-w-0 ${
              props.editable ? "" : "text-app-d/50"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
                outlineWidth: "0px",
              }
            }
            value={value || ""}
            onChangeText={(e) => setValue(e)}
            multiline={true}
            textAlignVertical="top"
            {...props}
          ></TextInput>
        </View>
      ) : null}
      {type === "email" ? (
        <View
          className={`rounded border  overflow-hidden h-10  bg-app-a ${
            valid !== false ? "border-gray-200" : "border-red-500"
          }`}
        >
          <TextInput
            placeholder={placeholder}
            placeholderTextColor="#999999"
            className={` flex-1 h-10 px-3 min-w-0 ${
              valid !== false ? "text-black" : "text-red-500"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            value={value || ""}
            onChangeText={(e) => {
              setValue(e);
              if (validation) isValid(checkIsValid(e));
              //   isValid(valid);
            }}
            {...props}
          ></TextInput>
        </View>
      ) : null}
      {type === "password" ? (
        <View
          className={`rounded border  overflow-hidden h-10 flex-row bg-app-a ${
            valid !== false ? "border-gray-200" : "border-red-500"
          }`}
        >
          <TextInput
            placeholder={placeholder}
            placeholderTextColor="#999999"
            className={`flex-1 h-10 px-3 min-w-0 ${
              valid !== false ? "text-black" : "text-red-500"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            value={value || ""}
            onChangeText={(e) => {
              setValue(e);
              if (validation) isValid(checkIsValid(e));
              //   isValid(valid);
            }}
            {...props}
            secureTextEntry={secret}
          ></TextInput>
          <Pressable
            onPress={() => setSecret((e) => !e)}
            className="w-12 h-10 items-center justify-center"
          >
            <RemixIcon
              name={secret ? "eye-off-fill" : "eye-fill"}
              color={colors.app.d2}
              size={18}
            ></RemixIcon>
          </Pressable>
        </View>
      ) : null}
      {type === "number" ? (
        <View className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a">
          <TextInput
            keyboardType="number-pad"
            maxLength={props.maxLength}
            placeholder={placeholder}
            className={`flex-1 h-10 px-3 min-w-0 ${
              props.editable ? "" : "text-app-d/50"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            value={value || ""}
            onChangeText={(e) => setValue(e)}
            {...props}
          ></TextInput>
        </View>
      ) : null}
      {type === "phoneNumber" ? (
        <View className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a">
          <TextInput
            placeholder={placeholder}
            className="flex-1 h-10 px-3 min-w-0"
            keyboardType="phone-pad"
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            {...props}
          ></TextInput>
        </View>
      ) : null}
      {type === "pricerange" ? (
        <View className="rounded border border-gray-200 overflow-hidden h-10 flex-row divide-x divide-gray-200  bg-app-a">
          <TextInput
            placeholder={placeholder}
            className="flex-1 h-10 px-3 min-w-0"
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            keyboardType="decimal-pad"
            value={value.min}
            // onChangeText={(e) => setValue((ee) => [e, ee[1]])}
            onChangeText={(e) => setValue({ e, type: "minValue" })}
            {...props}
          ></TextInput>
          <TextInput
            placeholder={placeholder}
            className="flex-1 h-10 px-3 min-w-0"
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            keyboardType="decimal-pad"
            // onChangeText={(e) => setValue((ee) => [ee[0], e])}
            onChangeText={(e) => {
              setValue({ e, type: "maxValue" });
            }}
            value={value.max}
            {...props}
          ></TextInput>
        </View>
      ) : null}
      {type === "rating" ? (
        <View className="flex-row">
          {[1, 2, 3, 4, 5].map((e) => (
            <Pressable onPress={() => setValue(e)} key={e} className="mx-1">
              <RemixIcon
                name="star-fill"
                color={value >= e ? colors.app.h : colors.app.d3}
              ></RemixIcon>
            </Pressable>
          ))}
        </View>
      ) : null}
      {type === "smallcounter" ? (
        <View
          className={`rounded border border-gray-200 overflow-hidden h-8 flex-row  bg-app-a`}
        >
          <Pressable
            className="w-8 h-8 items-center justify-center"
            onPress={() => {
              setValue(value - 1, "decrement");
              // setValue((e) => (e - 1 < 0 ? e : e - 1));
            }}
            disabled={isDecrementDisabled}
          >
            <RemixIcon
              name="subtract-fill"
              color={isDecrementDisabled ? colors.app.d2 : colors.app.d1}
            ></RemixIcon>
          </Pressable>
          <TextInput
            placeholder={placeholder}
            className={`flex-1 h-8 px-3 text-center min-w-0 ${
              valid !== false ? "text-black" : "text-red-500"
            }`}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            value={value.toString() || 0}
            // onChangeText={(e) =>
            //   setValue((ee) => [
            //     e === ""
            //       ? 0
            //       : Number.isNaN(Number(e))
            //       ? ee
            //       : e.length >= 2 && ee == 0
            //       ? Number(e.substring(1))
            //       : e,
            //   ])
            // }
            editable={false}
            {...props}
          ></TextInput>
          <Pressable
            className="w-8 h-8 items-center justify-center"
            onPress={() => {
              setValue(value + 1, "increment");
              // setValue((e) => (e + 1 < 10000 ? e + 1 : e));
            }}
            disabled={isIncrementDisabled}
          >
            <RemixIcon
              name="add-fill"
              color={isIncrementDisabled ? colors.app.d2 : colors.app.d1}
            ></RemixIcon>
          </Pressable>
        </View>
      ) : null}
      {type === "counter" ? (
        <View
          className={`rounded border border-gray-200 overflow-hidden h-10 flex-row divide-x divide-gray-200  bg-app-a`}
        >
          <TouchableOpacity
            className={`w-12 h-10 items-center justify-center ${
              isDecrementDisabled ? "bg-app-c" : "bg-app-d/90"
            }`}
            onPress={() => {
              setValue(+value - 1);
              // setValue((e) => (e - 1 < 0 ? e : e - 1));
            }}
            disabled={isDecrementDisabled}
          >
            <RemixIcon
              name="subtract-fill"
              color={isDecrementDisabled ? colors.app.d2 : colors.app.a}
            ></RemixIcon>
          </TouchableOpacity>
          <TextInput
            placeholder={placeholder}
            className={`flex-1 h-10 px-3 text-center w-12 
            ${valid !== false ? "text-black" : "text-red-500"}
            `}
            style={
              Platform.OS === "web" && {
                outlineColor: "transparent",
              }
            }
            value={+value + "" || 0}
            editable={false}
            onChangeText={(e) => {
              if (!Number.isNaN(e)) setValue(+e);
            }}
            {...props}
          ></TextInput>
          <TouchableOpacity
            className={`w-12 h-10 items-center justify-center ${
              isIncrementDisabled ? "bg-app-c" : "bg-app-d/90"
            }`}
            onPress={() => {
              setValue(+value + 1);
              // setValue((e) => (e + 1 < 10000 ? e + 1 : e));
            }}
            disabled={isIncrementDisabled}
          >
            <RemixIcon
              name="add-fill"
              color={isIncrementDisabled ? colors.app.d2 : colors.app.a}
            ></RemixIcon>
          </TouchableOpacity>
        </View>
      ) : null}
      {type === "coupon" ? (
        <ScrollView keyboardShouldPersistTaps="always">
          <View
            className={`rounded border  overflow-hidden h-10 flex-row divide-x divide-gray-200 bg-app-a ${
              valid !== false ? "border-gray-200" : "border-red-500"
            }`}
          >
            <TextInput
              placeholder={placeholder}
              className={`flex-1 h-10 px-3 min-w-0 ${
                valid !== false ? "text-black" : "text-red-500"
              }`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              value={value || ""}
              onChangeText={(e) => setValue(e)}
              {...props}
            ></TextInput>
            <Pressable
              onPress={() => action(value)}
              className="w-[72px] h-10 items-center justify-center bg-app-e active:bg-app-e1"
            >
              {isSubmitting ? (
                <ActivityIndicator
                  size={"small"}
                  color={colors.app.a}
                ></ActivityIndicator>
              ) : (
                <Font weight={600} className="text-white leading-4">
                  Apply
                </Font>
              )}
            </Pressable>
          </View>
        </ScrollView>
      ) : null}
      {type === "openinghour"
        ? value?.length > 0 &&
          value?.map((e, i) => (
            <View
              className={`rounded border border-gray-200 overflow-hidden h-10 flex-row items-center mb-2 divide-x divide-gray-200 bg-app-a`}
              key={i}
            >
              <TextInput
                placeholder={placeholder}
                className={`flex-1 h-10 px-3 min-w-0 text-center`}
                style={
                  Platform.OS === "web" && {
                    outlineColor: "transparent",
                  }
                }
                value={e.open || ""}
                onChangeText={(e) =>
                  setValue((ee) =>
                    Object.values({
                      ...ee,
                      [i]: {
                        ...ee[i],
                        open: e,
                      },
                    })
                  )
                }
                {...props}
              ></TextInput>

              <TextInput
                placeholder={placeholder}
                className={`flex-1 h-10 px-3 min-w-0 text-center`}
                style={
                  Platform.OS === "web" && {
                    outlineColor: "transparent",
                  }
                }
                value={e.close || ""}
                onChangeText={(e) =>
                  setValue((ee) =>
                    Object.values({
                      ...ee,
                      [i]: {
                        ...ee[i],
                        close: e,
                      },
                    })
                  )
                }
                {...props}
              ></TextInput>

              <Pressable
                className="w-12 h-10 items-center justify-center"
                onPress={() =>
                  setValue((ee) => [...ee.filter((eee, iii) => iii !== i)])
                }
              >
                <RemixIcon name="close-fill" color={colors.app.d1}></RemixIcon>
              </Pressable>
            </View>
          ))
        : null}
      {note !== null ? (
        <View className="py-1">
          <Font className="text-app-d1">{note}</Font>
        </View>
      ) : null}
    </View>
  );
};

export const Select = ({
  value = null,
  setValue = null,
  options = [],
  style = [],
  height,
  label = null,
  placeholder = null,
  mode = "search",
  ...props
}) => {
  const trigger = useRef();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);
  const openDropdown = () => {
    setFilter("");
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: mode === "search" ? py + 39 : py + 20,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };

  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1 relative"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className={`w-full items-center flex-row h-8`}>
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}

      <Pressable
        className={`rounded border border-gray-200 overflow-hidden bg-app-a ${
          height ? height : "h-10"
        }`}
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 px-2 min-w-0 items-center flex-row">
          {value ? (
            <Font weight={500} className="text-app-d flex-1">
              {value.label}
            </Font>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1">
              {placeholder}
            </Font>
          )}
          <RemixIcon
            name="arrow-drop-down-line"
            color={colors.app.d1}
          ></RemixIcon>
        </View>
      </Pressable>

      <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0"
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: isKeyboardVisible
              ? 310
              : position.y + (mode == "search" ? 0 : 20),
            left: position.x,
            width: position.w,
            maxHeight: 200,
            position: "absolute",
          }}
          className=" bg-app-a rounded border border-gray-200"
        >
          {mode == "search" ? (
            <View className="border-b border-gray-200 h-10 p-2">
              <TextInput
                className="flex-1"
                style={
                  Platform.OS === "web" && {
                    outlineColor: "transparent",
                  }
                }
                placeholder="Filter options"
                autoFocus
                onChangeText={(e) => setFilter(e)}
              ></TextInput>
            </View>
          ) : null}
          <ScrollView
            className=""
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              {options.length > 1 ? (
                options
                  ?.filter((e) =>
                    filter.toLowerCase() === ""
                      ? e
                      : e.label.toLowerCase().includes(filter)
                  )
                  ?.map((e, i) => (
                    <TouchableOpacity
                      key={i}
                      className="p-2 w-full"
                      onPress={() => {
                        setValue(e);
                        setOpen(false);
                      }}
                    >
                      <View>
                        <Font>{e.label}</Font>
                      </View>
                    </TouchableOpacity>
                  ))
              ) : (
                <View className="p-2 w-full">
                  <Font>No categories found</Font>
                </View>
              )}
            </View>
          </ScrollView>
        </View>
      </Modal>
      {!!props?.note && (
        <View className="py-1">
          <Font className="text-app-e">{props?.note}</Font>
        </View>
      )}
    </View>
  );
};
export const SelectLocation = ({
  value = null,
  setValue = null,
  style = [],
  label = null,
  placeholder = null,
}) => {
  const trigger = useRef();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState([]);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  const openDropdown = () => {
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  const Search = async () => {
    if (!filter) return;
    let result = await axios.get(
      `https://nominatim.openstreetmap.org/search?q=${filter}&limit=5&format=json&addressdetails=1`
    );
    setOptions(result?.data);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      <Pressable
        className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 h-10 px-2 min-w-0 items-center flex-row">
          {value ? (
            <Font weight={500} className="text-app-d flex-1" numberOfLines={1}>
              {value?.display_name}
            </Font>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1">
              {placeholder}
            </Font>
          )}
          <RemixIcon
            name="arrow-drop-down-line"
            color={colors.app.d1}
          ></RemixIcon>
        </View>
      </Pressable>
      <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0 "
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: 200,
          }}
          className="bg-app-a rounded border border-gray-200"
        >
          <View className="border-b border-gray-200 h-10 p-2">
            <TextInput
              className="flex-1"
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              placeholder="Type location"
              value={filter}
              autoFocus
              onChangeText={(e) => {
                setFilter(e);
                if (filter !== "") setOptions([]);
              }}
              onSubmitEditing={Search}
            ></TextInput>
          </View>
          <ScrollView
            className=""
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              {options.length ? (
                options?.map((e, i) => (
                  <Pressable
                    key={i}
                    className="p-2 w-full"
                    onPress={() => {
                      setValue(e);
                      setOpen(false);
                    }}
                  >
                    <View>
                      <Font>{e?.display_name}</Font>
                    </View>
                  </Pressable>
                ))
              ) : (
                <View className="p-2">
                  <Font>Hit enter to search</Font>
                </View>
              )}
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};
// function C24h(time) {
//     let hours = time?.substr(0, 2);
//     let hourss = parseInt(hours)
//     if (time?.indexOf("AM") != -1 && hourss == 12) {
//         time = time?.replace("12", "0");
//     }
//     if (time?.indexOf("PM") != -1 && hourss < 12) {
//         time = time?.replace(hours, hourss + 12);
//     }
//     return time?.replace(/(AM|PM)/, "");
// }
export const OpeningHours = ({
  value = [],
  setValue = null,
  style = [],
  label = null,
  placeholder = null,
}) => {
  const trigger = useRef();
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState([]);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  useEffect(() => {
    if (value.length > 0) {
      let temp = value.map((e) => {
        let open = value.open.split(":").map((e) => parseInt(e));
        let close = value.close.split(":").map((e) => parseInt(e));
        return {
          oh: open[0],
          om: open[1],
          ch: close[0],
          cm: close[1],
        };
      });
      setTime(temp);
    }
  }, []);
  useEffect(() => {
    if (time.length > 0) {
      let temp = time.map((e) => ({
        open: zero(e?.oh, 2) + ":" + zero(e?.om, 2),
        close: zero(e?.ch, 2) + ":" + zero(e?.cm, 2),
      }));
      setValue(temp);
    } else {
      setValue([]);
    }
  }, [time]);
  const openDropdown = () => {
    setTime({
      oh: 0,
      om: 0,
      ch: 0,
      cm: 0,
    });
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      {time.map((e, i) => (
        <View key={i}>
          <Pressable
            className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
            ref={trigger}
            onPress={openDropdown}
          >
            <View className="flex-1 h-10 pl-2 min-w-0 items-center flex-row">
              {value.length >= 0 && value[i] ? (
                <Font
                  weight={500}
                  className="text-app-d flex-1"
                  numberOfLines={1}
                >
                  {zero(e?.oh, 2) + ":" + zero(e?.om, 2)}
                  {" - "}
                  {zero(e?.ch, 2) + ":" + zero(e?.cm, 2)}
                </Font>
              ) : (
                <Font weight={500} className="text-app-d1 flex-1">
                  Opening - Closing
                </Font>
              )}
              {open || (value.length >= 0 && !value[i]) ? null : (
                <Pressable className="p-2" onPress={() => setTime(null)}>
                  <RemixIcon
                    name="close-line"
                    color={colors.app.d1}
                    size={16}
                  ></RemixIcon>
                </Pressable>
              )}
            </View>
          </Pressable>
          <Modal visible={open} transparent animationType="none">
            <Pressable
              className="absolute w-full h-full top-0 left-0 "
              onPress={() => setOpen(false)}
            ></Pressable>
            <View
              style={{
                top: position.y,
                left: position.x,
                width: position.w,
                maxHeight: 200,
              }}
              className="bg-transparent border border-gray-200 rounded"
            >
              <View className="border-b  border-gray-200 h-10 p-2 bg-transparent"></View>
              <ScrollView
                className="bg-app-a"
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View className="flex-1 w-full divide-y divide-gray-200">
                  <View className="w-full">
                    <View className="flex-row w-full items-center">
                      <Font
                        className="text-xs  text-app-d1 flex-1 px-2"
                        weight={500}
                      >
                        Opening Time
                      </Font>
                    </View>
                    <View className="flex-row items-center h-10 w-full ">
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              oh: e.oh - 1 < 0 ? 23 : e.oh - 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.oh, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              oh: e.oh + 1 >= 24 ? 0 : e.oh + 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      <View className="px-1">
                        <Font className="text-app-d" weight={700}>
                          :
                        </Font>
                      </View>
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              om: e.om - 5 < 0 ? 60 - 5 : e.om - 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.om, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              om: e.om + 5 >= 60 ? 0 : e.om + 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      {/* <Pressable><Font>PM</Font></Pressable> */}
                    </View>
                  </View>
                  <View className="w-full">
                    <View className="flex-row w-full items-center">
                      <Font
                        className="text-xs  text-app-d1 flex-1 px-2"
                        weight={500}
                      >
                        Closing Time
                      </Font>
                    </View>
                    <View className="flex-row items-center h-10 w-full ">
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              ch: e.ch - 1 < 0 ? 23 : e.ch - 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.ch, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              ch: e.ch + 1 >= 24 ? 0 : e.ch + 1,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      <View className="px-1">
                        <Font className="text-app-d" weight={700}>
                          :
                        </Font>
                      </View>
                      <View className="flex-row flex-1 items-center">
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              cm: e.cm - 5 < 0 ? 60 - 5 : e.cm - 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="subtract-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                        <View className="flex-1 items-center justify-center w-[10%]">
                          <Font className="text-app-d" weight={600}>
                            {zero(time?.cm, 2)}
                          </Font>
                        </View>
                        <Pressable
                          className=" p-2"
                          onPress={() =>
                            setTime((e) => ({
                              ...e,
                              cm: e.cm + 5 >= 60 ? 0 : e.cm + 5,
                            }))
                          }
                        >
                          <RemixIcon
                            color={colors.app.d1}
                            name="add-line"
                            size="18"
                          ></RemixIcon>
                        </Pressable>
                      </View>
                      {/* <Pressable><Font>PM</Font></Pressable> */}
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
          </Modal>
        </View>
      ))}
    </View>
  );
};
export const OpeningHoursSlot = ({
  value = null,
  setValue = null,
  style = [],
  label = null,
  placeholder = null,
}) => {
  const trigger = useRef();
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(null);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });
  useEffect(() => {
    if (value !== null) {
      let open = value.open.split(":").map((e) => parseInt(e));
      let close = value.close.split(":").map((e) => parseInt(e));
      setTime({
        oh: open[0],
        om: open[1],
        ch: close[0],
        cm: close[1],
        sl: slot,
      });
    }
  }, []);
  useEffect(() => {
    if (time !== null) {
      setValue({
        open: zero(time?.oh, 2) + ":" + zero(time?.om, 2),
        close: zero(time?.ch, 2) + ":" + zero(time?.cm, 2),
      });
    } else {
      setValue(null);
    }
  }, [time]);
  const openDropdown = () => {
    setTime({
      oh: 0,
      om: 0,
      ch: 0,
      cm: 0,
      sl: 1,
    });
    trigger.current.measure((_fx, _fy, _w, h, _px, py) => {
      setPosition({
        x: _px,
        y: py,
        w: _w,
        h: h,
      });
    });
    setOpen(true);
  };
  let styl = style
    ? style.reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {})
    : [];
  return (
    <View
      className="flex-1"
      style={{
        ...styl,
      }}
    >
      {label !== null ? (
        <View className="w-full h-8 items-center flex-row">
          <Font className="flex-1  text-app-d1" weight={600}>
            {label}
          </Font>
        </View>
      ) : null}
      <Pressable
        className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a"
        ref={trigger}
        onPress={openDropdown}
      >
        <View className="flex-1 h-10 min-w-0 items-center flex-row">
          {value !== null ? (
            <>
              <View className="p-2">
                <Font
                  weight={500}
                  className="text-app-d flex-1"
                  numberOfLines={1}
                >
                  {zero(time?.oh, 2) + ":" + zero(time?.om, 2)}
                  {" - "}
                  {zero(time?.ch, 2) + ":" + zero(time?.cm, 2)}
                </Font>
              </View>
              <View className="flex-1"></View>
              <View className="bg-gray-200 w-10 h-10  items-center justify-center">
                <Font>{time?.sl}</Font>
              </View>
            </>
          ) : (
            <Font weight={500} className="text-app-d1 flex-1 p-2">
              Opening - Closing | Slot
            </Font>
          )}
          {open || !value ? null : (
            <Pressable className="p-2" onPress={() => setTime(null)}>
              <RemixIcon
                name="close-line"
                color={colors.app.d1}
                size={16}
              ></RemixIcon>
            </Pressable>
          )}
        </View>
      </Pressable>
      <Modal visible={open} transparent animationType="none">
        <Pressable
          className="absolute w-full h-full top-0 left-0 "
          onPress={() => setOpen(false)}
        ></Pressable>
        <View
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: 220,
          }}
          className="bg-transparent border border-gray-200 rounded"
        >
          <View className="border-b  border-gray-200 h-10 p-2 bg-transparent"></View>
          <ScrollView
            className="bg-app-a"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full divide-y divide-gray-200">
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Opening Time
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          oh: e.oh - 1 < 0 ? 23 : e.oh - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.oh, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          oh: e.oh + 1 >= 24 ? 0 : e.oh + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  <View className="px-1">
                    <Font className="text-app-d" weight={700}>
                      :
                    </Font>
                  </View>
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          om: e.om - 5 < 0 ? 60 - 5 : e.om - 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.om, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          om: e.om + 5 >= 60 ? 0 : e.om + 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  {/* <Pressable><Font>PM</Font></Pressable> */}
                </View>
              </View>
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Closing Time
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          ch: e.ch - 1 < 0 ? 23 : e.ch - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.ch, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          ch: e.ch + 1 >= 24 ? 0 : e.ch + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  <View className="px-1">
                    <Font className="text-app-d" weight={700}>
                      :
                    </Font>
                  </View>
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          cm: e.cm - 5 < 0 ? 60 - 5 : e.cm - 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {zero(time?.cm, 2)}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          cm: e.cm + 5 >= 60 ? 0 : e.cm + 5,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                  {/* <Pressable><Font>PM</Font></Pressable> */}
                </View>
              </View>
              <View className="w-full">
                <View className="flex-row w-full items-center">
                  <Font
                    className="text-xs  text-app-d1 flex-1 px-2"
                    weight={500}
                  >
                    Available Slot
                  </Font>
                </View>
                <View className="flex-row items-center h-10 w-full ">
                  <View className="flex-row flex-1 items-center">
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          sl: e.sl <= 1 ? 1 : e.sl - 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="subtract-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                    <View className="flex-1 items-center justify-center w-[10%]">
                      <Font className="text-app-d" weight={600}>
                        {time?.sl}
                      </Font>
                    </View>
                    <Pressable
                      className=" p-2"
                      onPress={() =>
                        setTime((e) => ({
                          ...e,
                          sl: e.sl >= 99 ? 99 : e.sl + 1,
                        }))
                      }
                    >
                      <RemixIcon
                        color={colors.app.d1}
                        name="add-line"
                        size="18"
                      ></RemixIcon>
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};

export const DateRange = (
  value = [],
  placeholder = [],
  setValue = null,
  min = null,
  max = null,
  label = null
) => {};
