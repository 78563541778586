import React, { createContext, useContext, useEffect, useState } from "react";

export const OrdersContext = createContext({ reset: () => null });

export const OrdersProvider = ({ children }) => {
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [ordersApiError, setOrdersApiError] = useState(null);

  return (
    <OrdersContext.Provider
      value={{
        isOrdersLoading,
        orders,
        ordersApiError,
        orderDetails,
        setIsOrdersLoading,
        setOrders,
        setOrdersApiError,
        setOrderDetails,
        reset: () => {
          setIsOrdersLoading(false);
          setOrders([]);
          setOrdersApiError(null);
          setOrderDetails(null);
        },
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrdersContext = () => useContext(OrdersContext);
