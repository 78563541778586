import {
  Image,
  TouchableOpacity,
  View,
  Pressable,
  Platform,
  RefreshControl,
  ScrollView,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import React, { useCallback, useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { getUserData, postLogout } from "../../services/api";
import Toast from "react-native-toast-message";
import { useSharedContext } from "../../context/sharedContext";
import { ResponseModal } from "../../components/response-modal";
import { Button } from "../../components/input";
import { mediaURI } from "../../../constants";
import useAppContextActions from "../../context";

export const Account = ({ navigation }) => {
  const { setUserToken, userToken, setUser } = useContext(AuthContext);
  const { userData, setUserData, userWallet } = useContext(UserContext);
  const { resetAppContexts } = useAppContextActions();
  const [gettingUserInfo, setGettingUserInfo] = useState();
  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
    showSecondaryButton: false,
  });
  const {
    screen: { width },
  } = useSharedContext();
  const back = navigation.canGoBack();
  const dismiss = () => {
    if (width > 1024) {
      navigation.goBack();
    } else {
      navigation.replace("home");
    }
  };
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const onLogOut = async () => {
    try {
      const res = await postLogout(userToken);
      if (res.status === 204) {
        showToast("success", "Success", res.data);
        setUser(null);
        setUserData(null);
        setUserToken(null);
        resetAppContexts();
        navigation.replace("home");
      } else {
        console.log("account, logout res error : ", res.data);
      }
    } catch (error) {
      console.log("account, logout error : ", error);
    }
  };

  const getProfile = useCallback(async () => {
    setGettingUserInfo(true);
    try {
      const res = await getUserData(userToken);
      if (res?.status) {
        if (res.status === 200) {
          setUserData(res.data.data);
        } else if (res.status === 401) navigation.replace("signin");
      }
    } catch (error) {
      console.log("error?.code:", error?.code, error);
    } finally {
      setGettingUserInfo(false);
    }
  }, []);

  return (
    <View className="flex-1 bg-transparent h-full relative">
      <Pressable
        className="w-full h-full absolute top-0 left-0 bg-transparent"
        onPress={Platform.OS === "web" ? dismiss : () => null}
      ></Pressable>
      <View className="absolute w-full h-max lg:w-80 top-0 right-0 bottom-0 lg:bottom-0.5 lg:top-16 lg:right-0.5 bg-app-e lg:bg-white rounded-none lg:rounded-lg lg:border lg:border-gray-200 overflow-hidden">
        <View className="w-full h-16 bg-app-e lg:bg-app-a  items-center flex-row px-1 lg:px-4 border-b border-b-transparent lg:border-b-gray-200 ">
          <Pressable
            className="p-3 rounded-full active:bg-app-c3 flex lg:hidden"
            onPress={dismiss}
          >
            <RemixIcon
              name="arrow-left-s-line"
              className="flex lg:hidden"
              color={colors.app.a}
              size="22"
            ></RemixIcon>
            <RemixIcon
              name="arrow-left-s-line"
              className="hidden lg:flex"
              color={colors.app.d1}
              size="22"
            ></RemixIcon>
          </Pressable>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px]  px-2">
            Account
          </Font>
        </View>

        <View className="w-full items-center pt-4">
          <View className="w-20 h-20 rounded-full overflow-hidden border border-app-c3">
            <Image
              source={
                userData?.profile_image_url
                  ? { uri: mediaURI + userData?.profile_image_url }
                  : require("../../../assets/user1.jpg")
              }
              className="w-full h-full"
            ></Image>
          </View>
          <View className="items-center py-4">
            {userData?.first_name && userData?.last_name ? (
              <Font
                className="font-semibold text-app-a lg:text-app-d"
                weight={700}
              >
                {userData?.first_name + " " + userData?.last_name}
              </Font>
            ) : (
              <Font className="font-semibold text-app-a lg:text-app-d">
                Hello, User
              </Font>
            )}
            <Font className="text-app-a1  lg:text-app-d1" weight={500}>
              {userData?.email}
            </Font>
          </View>
        </View>
        <View className="flex-1 h-full w-full">
          <View className="absolute w-full h-full top-0 left-0">
            <View className="h-24"></View>
            <View className="flex-1 bg-gray-100"></View>
          </View>
          <View className="absolute w-full h-full top-0 left-0 px-4 pb-4 lg:p-0">
            <ScrollView
              className="flex-1 overflow-y-scroll bg-white rounded-lg lg:rounded-none border lg:border-0 lg:border-t border-gray-200 py-4 px-2"
              refreshControl={
                Platform.OS === "web" ? null : (
                  <RefreshControl
                    refreshing={gettingUserInfo}
                    onRefresh={getProfile}
                  />
                )
              }
            >
              {/* <View className="flex-row items-center pb-4 border-b border-gray-200">
                <View className="h-full p-4 bg-app-h3 rounded-full mr-4">
                  <RemixIcon
                    name="honour-fill"
                    color={colors.app.h}
                  ></RemixIcon>
                </View>
                <View>
                  <Font className="text-app-h text-lg" weight={700}>
                    $ 100.00
                  </Font>
                  <Font className="text-app-d1 text-xs" weight={500}>
                    Collected Reward Balance
                  </Font>
                </View>
              </View> */}
              <View className="flex-row items-center pb-4 px-2 border-b border-gray-200">
                <View className="w-1/2 flex-row items-center">
                  <View className="h-10 w-10 bg-app-h3 rounded-full mr-4 items-center justify-center">
                    <RemixIcon
                      name="honour-fill"
                      color={colors.app.h}
                      size={22}
                    ></RemixIcon>
                  </View>
                  <View>
                    <Font className="text-app-h text-[15px]" weight={700}>
                      {userData?.rewards?.currency_symbol
                        ? userData?.rewards?.currency_symbol
                        : "$"}
                      &nbsp;
                      {(+(userData?.rewards?.amount || 0) / 100).toFixed(2)}
                    </Font>
                    <Font className="text-app-d1 text-xs" weight={500}>
                      Rewards
                    </Font>
                  </View>
                </View>
                <View className="w-1/2 flex-row items-center">
                  <View className="h-10 w-10 bg-app-e3 rounded-full mr-4 items-center justify-center ">
                    <RemixIcon
                      name="wallet-fill"
                      color={colors.app.e}
                      size={22}
                    ></RemixIcon>
                  </View>
                  <View>
                    <Font className="text-app-e text-[15px]" weight={700}>
                      {userWallet?.currency_symbol
                        ? userWallet?.currency_symbol
                        : "$"}
                      &nbsp;
                      {(+(userWallet?.amount || 0) / 100).toFixed(2) || 0}
                    </Font>
                    <Font className="text-app-d1 text-xs" weight={500}>
                      Wallet
                    </Font>
                  </View>
                </View>
              </View>
              <View className="flex-row justify-center items-center py-2 border-b border-gray-200">
                <View className="flex-row flex-1 mx-2">
                  <Button
                    className="w-full place-self-center"
                    label="Withdraw"
                    type="primary"
                    onPress={() => navigation.navigate("withdraw")}
                  />
                </View>
                <View className="flex-row flex-1 mx-2">
                  <Button
                    className="w-full place-self-center"
                    label="Topup"
                    type="primary"
                    onPress={() => navigation.navigate("top-up")}
                  />
                </View>
              </View>
              <View className="py-4">
                <TouchableOpacity
                  className="flex-row py-3 px-2 items-center"
                  onPress={() => navigation.replace("user-feed")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="ri-chat-smile-3-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    My Feed
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 px-2 items-center"
                  onPress={() => navigation.replace("transactions")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="ri-exchange-funds-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Transactions
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 active:bg-app-c1 px-2  items-center"
                  onPress={() => {
                    navigation.replace("groups");
                  }}
                >
                  <View className="flex-row items-center">
                    <View className="mr-4">
                      <RemixIcon
                        name="ri-team-fill"
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <Font className="text-app-d" weight={600}>
                      Groups
                    </Font>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 px-2 items-center active:bg-app-c1 rounded-lg"
                  onPress={() => navigation.replace("update-profile")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="edit-box-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Update Profile
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 active:bg-app-c1 px-2 items-center"
                  onPress={() => navigation.navigate("forgot-password")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="lock-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Reset Password
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 active:bg-app-c1 px-2  items-center"
                  onPress={() => navigation.replace("manage-addresses")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="road-map-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Manage Addresses
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 active:bg-app-c1 px-2  items-center"
                  onPress={() => navigation.replace("invite-contact")}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="group-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Invite Contact
                  </Font>
                </TouchableOpacity>
                <TouchableOpacity
                  className="flex-row py-3 active:bg-app-c1 px-2  items-center"
                  // onPress={onLogOut}
                  onPress={() =>
                    setModal({
                      isVisible: true,
                      message: "Confirm logout ?",
                    })
                  }
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="logout-box-r-line"
                      color={colors.app.d}
                    ></RemixIcon>
                  </View>
                  <Font className="text-app-d" weight={600}>
                    Logout
                  </Font>
                </TouchableOpacity>
              </View>
              {/* --------------------------confirm logout modal --------------------- */}
              {modal.isVisible && (
                <View className="flex-1">
                  <ResponseModal
                    modalContent={modal.message}
                    modalVisible={modal.isVisible}
                    setVisible={(e) => setModal({ ...modal, isVisible: e })}
                    primaryButtonLabel="No"
                    onPrimaryButtonPress={() => {
                      setModal({ ...modal, isVisible: false });
                    }}
                    showSecondaryButton={true}
                    secondaryButtonLabel="Yes"
                    onSecondaryButtonPress={onLogOut}
                  ></ResponseModal>
                </View>
              )}
            </ScrollView>
          </View>
        </View>
      </View>
    </View>
  );
};
