import React, { useContext, useEffect, useState } from "react";
import { Platform, RefreshControl, ScrollView, View } from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import UploadPicture from "../../components/uploadpicture";
import { Button, Input } from "../../components/input";
import { DEV_API, getUserData, patchUserData } from "../../services/api";
import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import { ResponseModal } from "../../components/response-modal";
import { ActivityIndicator } from "react-native";
import { Widget } from "../../components/widget";
import Toast from "react-native-toast-message";
import { fileURLtoBlob } from "../../../utils/encDec";
import ConfirmModal from "../../components/ConfirmModal";
import axios from "axios";
import { catchAPIErrorText } from "../../../utils";
import { useSharedContext } from "../../context/sharedContext";

export const UpdateProfile = ({ navigation }) => {
  const { setTemporaryData } = useSharedContext();
  const {
    userData,
    setUserData,
    isUserLoading,
    setIsUserLoading,
    setGetUserLoading,
  } = useContext(UserContext);
  const { userToken } = useContext(AuthContext);

  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [profile, setProfile] = useState({
    first_name: userData?.first_name ?? "",
    last_name: userData?.last_name ?? "",
    company_name: userData?.company_name ?? "",
    email: userData?.email ?? "",
    phone: userData?.phone ?? "",
    profile_image: userData?.profile_image_url ?? null,
  });
  const [deleteModal, setIsDeleteModal] = React.useState(false);

  const [isLocalImageAdded, setIsLocalImageAdded] = useState(false);
  const isButtonEnabled =
    (userData?.first_name !== profile.first_name &&
      profile.first_name !== "") ||
    (userData?.last_name !== profile.last_name && profile.last_name !== "") ||
    (userData?.company_name !== profile.company_name &&
      profile.company_name !== "") ||
    (userData?.email !== profile.email && profile.email !== "") ||
    (userData?.phone !== profile.phone && profile.phone !== "") ||
    (profile.profile_image &&
      userData?.profile_image_url !== profile.profile_image);
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  useEffect(() => {
    if (userData) {
      setProfile({
        first_name: userData?.first_name ?? "",
        last_name: userData?.last_name ?? "",
        company_name: userData?.company_name ?? "",
        email: userData?.email ?? "",
        phone: userData?.phone ?? "",
        profile_image: userData?.profile_image_url ?? null,
      });
    }
  }, [userData]);

  const RemovePictureApi = async () => {
    try {
      setIsDeleteModal(false);
      const res = await axios.delete(DEV_API + "/users/me/profile-image", {
        headers: {
          Authorization: `Bearer ${userToken}`,
          // "Content-Type": "multipart/form-data",
        },
      });
      if (res.status == 204) {
        showToast("success", "Success", "Profile Picture Removed");
        setUserData((prev) => ({ ...prev, profile_image_url: "" }));
        setProfile((prev) => ({ ...prev, profile_image: "" }));
        // setShowResponseModal({
        //   visible: true,
        //   onRequestClose: () => {
        //     setShowResponseModal({
        //       visible: false,
        //       onRequestClose: () => null,
        //       bodyText: "",
        //     });
        //   },
        //   bodyText: res.data?.data?.message || "Profile Picture Removed !",
        // });
        // selectImageToUpload(""); // Clear selected image
        // setProfile_image_url(""); // Clear profile image URL

        // setTimeout(()=>{
        //   GetUserData();
        // },3000)
      } else {
        showToast(
          "error",
          "Error Response",
          catchAPIErrorText({ response: res })
        );
      }
    } catch (error) {
      console.log(error, "delete pic error");
      showToast("error", "Error", catchAPIErrorText(error));
    }
  };

  const onChangeProfileImage = (imageObject) => {
    try {
      let path =
        Platform.OS === "ios"
          ? imageObject.uri.replace("file:///", "file:/")
          : imageObject.uri;
      fileURLtoBlob(path).then((data) => {
        imageObject.blob = data;
        setProfile({ ...profile, profile_image: imageObject });
      });
    } catch (error) {
      console.log(
        "Update Profile Error:",
        error,
        error?.response?.status,
        error?.response?.data
      );
      showToast("error", "Error", catchAPIErrorText(error));
    }
  };
  const onUpdateProfile = async () => {
    let data = {};
    for (const key in profile) {
      if (profile?.[key] !== userData?.[key]) {
        data[key] = profile[key];
      }
    }
    try {
      setIsUserLoading(true);
      const res = await patchUserData({
        token: userToken,
        data,
        id: userData.id,
      });
      if (res.status === 200) {
        if (res?.data?.verification_entity == "phone") {
          setTemporaryData({ mobileSMSOTP: res?.data?.otp });
          navigation.replace("verifyOtp", { hash: res?.data?.hash ,redirectTo:"app" });
        } else {
          showToast("success", "Success", res.data?.message);
        }
        await getUserData(userToken).then((res) =>
          setUserData((e) => ({
            ...e,
            ...data,
            ...(res.data?.data || {}),
          }))
        );
        // setModal({ isVisible: true, message: res.data.message });
        
      } else {
        // setModal({ isVisible: true, message: res.data.message });
        showToast("error", "Error Response", res.data?.message);
      }
    } catch (error) {
      console.log("update-profile, updateprofile error : ", error);
      showToast("error", "Error", catchAPIErrorText(error));
    } finally {
      setIsUserLoading(false);
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={async () => {
                setIsRefreshing(true);
                await getUserData(userToken).then((res) => {
                  if (res.status === 200)
                    setUserData((e) => ({
                      ...e,
                      ...(res.data?.data || {}),
                    }));
                });
                setIsRefreshing(false);
              }}
            />
          )
        }
      >
        <ConfirmModal
          visible={deleteModal}
          onCancel={() => setIsDeleteModal(false)}
          onConfirm={RemovePictureApi}
          confirmText={"Delete"}
          bodyText={"Are you sure you want to Remove the Profile Picture ?"}
          BodyComponent={false}
        ></ConfirmModal>
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Update profile
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Update Profile">
                <View className="">
                  <View className="p-4 items-center justify-center">
                    <UploadPicture
                      profileImage={profile.profile_image}
                      prevImage={userData.profile_image_url ?? null}
                      onImageUpdate={(data) => onChangeProfileImage(data)}
                      setIsLocalImageAdded={setIsLocalImageAdded}
                      isLocalImageAdded={isLocalImageAdded}
                    ></UploadPicture>
                  </View>
                  {profile.profile_image !== "" ? (
                    <View className="w-full px-4 pt-4 pb-1 flex-row justify-center">
                      <Button
                        className="w-[50%] lg:w-[30%]"
                        onPress={() => {
                          setIsDeleteModal(true);
                        }}
                        type="primary"
                        label="Remove Picture"
                      ></Button>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View className="flex-row flex-wrap mb-4">
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="First Name"
                        value={profile.first_name}
                        setValue={(e) => {
                          setProfile({ ...profile, first_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Last Name"
                        value={profile.last_name}
                        setValue={(e) => {
                          setProfile({ ...profile, last_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Company Name"
                        value={profile.company_name}
                        setValue={(e) => {
                          setProfile({ ...profile, company_name: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Email"
                        validation={false}
                        value={profile.email}
                        setValue={(e) => {
                          setProfile({ ...profile, email: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Phone"
                        value={profile.phone}
                        setValue={(e) => {
                          setProfile({ ...profile, phone: e });
                        }}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1 justify-end mt-5">
                      {isUserLoading ? (
                        <View className="flex-1 w-full  bg-app-e h-10 rounded-lg justify-center">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.a}
                          ></ActivityIndicator>
                        </View>
                      ) : (
                        <Button
                          type={`${isButtonEnabled ? "primary" : "disabled"}`}
                          label="Save"
                          onPress={onUpdateProfile}
                        ></Button>
                      )}
                    </View>
                  </View>
                </View>
              </Card>
            </View>
            <View className="hidden lg:flex-1 w-full lg:w-2/6 p-0 lg:pl-4 pt-20">
              <Widget></Widget>
            </View>
          </View>
          {/* <Footer></Footer> */}
        </View>
      </ScrollView>
      {modal.isVisible && (
        <View>
          <ResponseModal
            modalContent={modal.message}
            modalVisible={modal.isVisible}
            setVisible={(e) => setModal({ ...modal, isVisible: e })}
            primaryButtonLabel="Dismiss"
            onPrimaryButtonPress={() => {
              setModal({ isVisible: false, message: null });
            }}
            showSecondaryButton={false}
          ></ResponseModal>
        </View>
      )}
    </View>
  );
};
