import React from "react";
import { useSharedContext } from "./sharedContext";
import { useStripeContext } from "./stripeContext";
import { useUserFeedContext } from "./user-feed-context";
import { useOrdersContext } from "./ordersContext";
import { useProductsContext } from "./productsContext";
import { useNotificationContext } from "./notificationContext";
import { useMessagesContext } from "./messagesContext";
import { useBusinessesContext } from "./businessesContext";
import { useUserContext } from "./userContext";
import { useAuthContext } from "./authContext";

const useAppContextActions = () => {
  const { reset: resetSharedContext } = useSharedContext();
  const { reset: resetSripeContext } = useStripeContext();
  const { resetVendorFeed } = useUserFeedContext();
  const { reset: resetProductsContext } = useProductsContext();
  const { reset: resetOrdersContext } = useOrdersContext();
  const { resetNotificationContext } = useNotificationContext();
  const { resetMessagesContext } = useMessagesContext();
  const { reset: resetBusinessesContext } = useBusinessesContext();
  const { reset: resetUserContext } = useUserContext();
  const { reset: resetAuthContext } = useAuthContext();

  const resetAppContexts = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        resetSharedContext();
        resetSripeContext();
        resetVendorFeed();
        resetProductsContext();
        resetOrdersContext();
        resetNotificationContext();
        resetMessagesContext();
        resetBusinessesContext();
        resetUserContext();
        resetAuthContext();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }, [resetSharedContext]);

  return { resetAppContexts };
};

export default useAppContextActions;
