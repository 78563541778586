import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import {
  deleteBookmark,
  DEV_API,
  getBookmark,
  getListing,
  getProducts,
  postBookmark,
} from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import RemixIcon from "react-native-remix-icon";
import { Maps } from "../../components/maps";
import colors from "../../../colors";
import { BusinessesContext } from "../../context/businessesContext";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { ActivityIndicator } from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { ProductsContext } from "../../context/productsContext";
import { Widget } from "../../components/widget";
import { Button } from "../../components/input";
import { SharedContext } from "../../context/sharedContext";
import Toast from "react-native-toast-message";

export const Search = ({ navigation, route }) => {
  const { shared, setShared } = useContext(SharedContext);
  const { userWishlists, setUserWishlists, setIsUserLoading, isUserLoading } =
    useContext(UserContext);
  const { user, userToken } = useContext(AuthContext);
  const {
    isBusinessLoading,
    setIsBusinessLoading,
    businesses,
    setBusinesses,
    pagination: businessesPagination,
    setPagination: setBusinessesPagination,
  } = useContext(BusinessesContext);
  const {
    isProductsLoading,
    setIsProductsLoading,
    products,
    setProducts,
    pagination: productsPagination,
    setPagination: setProductsPagination,
  } = useContext(ProductsContext);
  const [bookmarks, setBookmarks] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchParams, setSearchParams] = useState({
    itemType: "Listing",
    keyword: undefined,
    category: undefined,
    // priceRange: [],
    minPrice: undefined,
    maxPrice: undefined,
    location: undefined,
    detailLocation: undefined,
    // sortBy: "",
  });
  const perPageRecords = 10;
  useEffect(() => {
    setShared((e) => ({ ...e, search: searchParams }));
  }, [searchParams]);
  const getAllListings = async (offset = 0, limit = perPageRecords) => {
    try {
      setIsBusinessLoading(true);
      const res = await getListing({
        itemType: route.params?.itemType?.toLowerCase(),
        searchText: route.params?.keyword,
        listingCategoryId:
          route.params?.category || route.params?.category?.value
            ? ["all", "All", "ALL"].includes(route.params?.category) ||
              ["all", "All", "ALL"].includes(route.params?.category?.value)
              ? undefined
              : route.params?.category || route.params?.category?.value
            : undefined,
        minPrice: +route.params?.minPrice ? +route.params.minPrice : undefined,
        maxPrice: +route.params?.maxPrice ? +route.params.maxPrice : undefined,
        // sortBy: route.params.sortBy,
        offset: offset,
        limit: limit,
      });
      if (res.status === 200) {
        setBusinesses((businesses) =>
          offset
            ? [...(businesses || []), ...(res.data?.data || [])]
            : res.data?.data || []
        );
        setBusinessesPagination({
          offset: offset,
          total_records: res.data?.total_count,
        });
      } else {
        Toast.show({
          type: "error",
          text1: "Error",
          text2: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("search, getlisting error : ", error);
    } finally {
      setIsBusinessLoading(false);
    }
  };
  const getAllProducts = async (offset = 0, limit = perPageRecords) => {
    try {
      setIsProductsLoading(true);
      const res = await getProducts({
        itemType: route.params?.itemType?.toLowerCase(),
        searchText: route.params?.keyword,
        productCategoryId:
          route.params?.category || route.params?.category?.value
            ? ["all", "All", "ALL"].includes(route.params?.category) ||
              ["all", "All", "ALL"].includes(route.params?.category?.value)
              ? undefined
              : route.params?.category || route.params?.category?.value
            : undefined,
        minPrice: +route.params?.minPrice ? +route.params.minPrice : undefined,
        maxPrice: +route.params?.maxPrice ? +route.params.maxPrice : undefined,
        offset: offset,
        limit: limit,
        // sortBy: route.params.sortBy,
      });
      if (res.status === 200) {
        setProducts((products) =>
          offset
            ? [...(products || []), ...(res.data?.data || [])]
            : res.data?.data || []
        );
        setProductsPagination({
          offset: offset,
          total_records: res.data?.total_count,
        });
      } else {
        Toast.show({
          type: "error",
          text1: "Error",
          text2: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("search, getproducts error : ", error);
    } finally {
      setIsProductsLoading(false);
    }
  };
  useEffect(() => {
    setSearchParams((e) => ({ ...e, ...route.params }));
    if (
      (route.params?.itemType || searchParams?.itemType)
        ?.toLowerCase()
        ?.includes("listing")
    ) {
      getAllListings();
    } else if (
      (route.params?.itemType || searchParams?.itemType)
        ?.toLowerCase()
        ?.includes("product")
    ) {
      getAllProducts();
    }
  }, [route.params]);

  const getWishlist = async (token) => {
    try {
      setIsUserLoading(true);
      const res = await getBookmark({ token });
      if (res.status === 200) {
        setUserWishlists(res.data.data);
        const wishlistArray = res.data.data.map((e) => {
          return { id: e.id, listingId: e.listing.id };
        });
        setBookmarks(wishlistArray);
      } else {
        console.log("search, getWishlist error : ", res.data);
      }
    } catch (error) {
      console.log("search, getWishlist error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getWishlist(userToken);
    }
  }, [user, userToken]);

  const onBookmark = async ({ id }) => {
    try {
      const filterBookmark = bookmarks.filter((e) => {
        if (e.listingId === id) return e;
      });
      if (filterBookmark.length === 1) {
        const res = await deleteBookmark({
          bookmark_id: filterBookmark[0].id,
          token: userToken,
        });
        if (res.status === 204) {
          const newArray = bookmarks.filter((e) => {
            if (e.listingId !== id) return e;
          });
          const newArray1 = userWishlists.filter((e) => {
            if (e.listing.id !== id) return e;
          });
          setBookmarks(newArray);
        } else {
          console.log("search, deletebookmark res err : ", res.data);
        }
      } else {
        const res = await postBookmark({ id, token: userToken });
        if (res.status === 201) {
          setBookmarks((e) => [...e, { id: res.data.data.id, listingId: id }]);
        } else {
          console.log("search, onbookmark add res err : ", res.data);
        }
      }
    } catch (error) {
      console.log("search, onbookmark err : ", error);
    }
  };

  // --------------- if loading -------------------
  // if (isBusinessLoading || isUserLoading) {
  //   return (
  //     <View className="flex-1 justify-center items-center">
  //       <Font> Loading Data...</Font>
  //     </View>
  //   );
  // }

  const onGetDetails = ({ id }) => {
    navigation.navigate("listing", { id });
  };
  const goToProductDetails = (id) => {
    navigation.navigate("product", { id });
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-app-b">
      <View className="rounded-t-2xl bg-app-b h-full">
        <ScrollView keyboardShouldPersistTaps="always">
          <View className="flex-1 w-full lg:flex-row h-full">
            <View className="w-full lg:w-3/4 h-full flex flex-col mt-5">
              <View className="mx-6 hidden lg:flex">
                {/* <Font className="text-lg font-semibold text-app-d ">
                                Search
                            </Font> */}
                <Pressable
                  className="w-full active:bg-app-c1 rounded-lg border border-gray-200 flex-row mb-4 lg:p-2 bg-app-a shadow-black shadow-lg "
                  onPress={() => {
                    navigation.navigate("search-modal", searchParams);
                  }}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="search-line"
                      size={24}
                      color={colors.app.d1}
                    ></RemixIcon>
                  </View>
                  <View className="">
                    <Font
                      weight={600}
                      className=" text-app-d text-[15px] leading-6"
                    >
                      {searchParams.keyword
                        ? searchParams.keyword
                        : searchParams.category?.label ?? "All"}
                    </Font>
                  </View>
                </Pressable>
              </View>
              <ScrollView
                className="flex-1 w-full h-full px-2 lg:px-4"
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={async () => {
                      setRefreshing(true);
                      if (
                        searchParams.itemType.toLowerCase().includes("listing")
                      ) {
                        await (async () => {
                          await getAllListings();
                          await getWishlist();
                        })();
                      } else if (
                        searchParams.itemType.toLowerCase().includes("product")
                      ) {
                        await getAllProducts();
                      }
                      setRefreshing(false);
                    }}
                  />
                }
                keyboardShouldPersistTaps="always"
              >
                {searchParams.itemType.toLowerCase().includes("listing") ? (
                  <>
                    {/* <Animated.View
            className="w-full lg:w-1/2 h-full flex lg:hidden"
            style={{
              height: heightAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ["0%", "100%"],
              }),
            }}
          >
            <Maps className="flex-1"></Maps>
          </Animated.View> */}
                    {/* <View className="w-full h-10 flex lg:hidden">
              <Pressable
                className="flex-1 items-center justify-center"
                onPress={toggleMap}
              >
                <View className="h-1 w-16 bg-app-d3 rounded"></View>
              </Pressable>
            </View> */}
                    {/* <View className="w-full">
                    <Select
                      options={[
                        { label: "test", value: "test" },
                        { label: "test 2", value: "test 2" },
                        { label: "test 3", value: "test 3" },
                      ]}
                      placeholder="Select"
                      className="w-full mb-4"
                      mode="simple"
                    ></Select>
                  </View> */}
                    {businesses?.length >= 1 && !isUserLoading ? (
                      <View className="flex-row w-full p-0 flex-wrap">
                        {businesses.map((e, i) => (
                          <View
                            className="w-full lg:w-1/2 p-2"
                            key={`business_data_${i}`}
                          >
                            <Pressable
                              className="w-full active:bg-app-c1 rounded-lg border border-gray-200 flex-row p-2 bg-app-a shadow-black shadow-lg "
                              onPress={() => onGetDetails({ id: e.id })}
                            >
                              <View className="w-32 h-32 lg:h-40 lg:w-40 flex overflow-hidden rounded-md">
                                <Image
                                  source={
                                    e.cover_image_url
                                      ? {
                                          uri: `${DEV_API}/media/${e.cover_image_url}`,
                                        }
                                      : require("../../../assets/bg.jpg")
                                  }
                                  className="w-full h-full object-contain"
                                ></Image>
                                {user && (
                                  <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                                    <Pressable
                                      className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden"
                                      onPress={() => onBookmark({ id: e.id })}
                                    >
                                      {() => {
                                        const isBookmark = bookmarks.filter(
                                          (ee) => {
                                            if (ee.listingId === e.id) return e;
                                          }
                                        );
                                        return (
                                          <RemixIcon
                                            name={
                                              isBookmark.length === 1
                                                ? "bookmark-fill"
                                                : "bookmark-line"
                                            }
                                            color={colors.app.e}
                                            size="18"
                                          ></RemixIcon>
                                        );
                                      }}
                                    </Pressable>
                                    <View className="flex-1"></View>
                                  </View>
                                )}
                              </View>
                              <View className="justify-start pl-4 pr-0 py-1 flex-1">
                                <View className="flex-row items-center pb-1">
                                  <Font
                                    className="text-xs lg:text-md text-app-f"
                                    numberOfLines={2}
                                    weight={500}
                                  >
                                    {e.listing_category.title}
                                  </Font>
                                </View>
                                <View className="flex pb-3">
                                  <Font
                                    className="text-[15px] text-app-d leading-4"
                                    numberOfLines={2}
                                    weight={600}
                                  >
                                    {e.title}
                                  </Font>
                                </View>
                                <View className="flex flex-row items-start pb-1">
                                  <RemixIcon
                                    name="map-pin-fill"
                                    color={colors.app.d1}
                                    size={19}
                                    className="mr-2 flex-shrink-0"
                                  ></RemixIcon>
                                  {e.city !== null &&
                                  e.state !== null &&
                                  e.country !== null ? (
                                    <Font
                                      className="text-sm text-app-d1 leading-4"
                                      weight={600}
                                      numberOfLines={2}
                                    >
                                      {e.city +
                                        ", " +
                                        e.state +
                                        ", " +
                                        e.country}
                                    </Font>
                                  ) : (
                                    <Font
                                      className="text-sm text-app-d1 leading-4"
                                      numberOfLines={2}
                                      weight={600}
                                    >
                                      NA
                                    </Font>
                                  )}
                                </View>
                                <View className="flex-row items-center pb-1">
                                  <RemixIcon
                                    name="star-fill"
                                    size={19}
                                    color={colors.app.i}
                                    className="mr-2 flex-shrink-0"
                                  ></RemixIcon>
                                  <Font
                                    className="text-md text-app-d1"
                                    numberOfLines={1}
                                    weight={600}
                                  >
                                    {`${e.average_rating}/5`}
                                  </Font>
                                  <Font className="leading-5 text-base text-app-d1">
                                    {" | "}
                                  </Font>
                                  <Font
                                    className="text-md text-app-d1"
                                    numberOfLines={1}
                                    weight={600}
                                  >
                                    {e.total_reviews}{" "}
                                    {`${
                                      e.total_reviews === 1
                                        ? "review"
                                        : "reviews"
                                    }`}
                                  </Font>
                                </View>
                                <View className="flex-row items-center">
                                  <View>
                                    <RemixIcon
                                      name="money-dollar-circle-fill"
                                      size={19}
                                      color={colors.app.d1}
                                      className="mr-2 flex-shrink-0"
                                    ></RemixIcon>
                                  </View>
                                  <Font
                                    className="text-app-d1 leading-4"
                                    weight={600}
                                    numberOfLines={1}
                                  >
                                    $
                                    {e.min_price
                                      ? Number(e.min_price) / 100
                                      : 0}
                                    {" - "}$
                                    {e.max_price
                                      ? Number(e.max_price) / 100
                                      : 0}{" "}
                                  </Font>
                                </View>
                              </View>
                              {user && (
                                <View className="hidden lg:flex pt-4 pr-4 pl-1">
                                  <Pressable
                                    onPress={() => onBookmark({ id: e.id })}
                                  >
                                    {() => {
                                      const isBookmark = bookmarks.filter(
                                        (ee) => {
                                          if (ee.listingId === e.id) return e;
                                        }
                                      );
                                      return (
                                        <RemixIcon
                                          name={
                                            isBookmark.length === 1
                                              ? "bookmark-fill"
                                              : "bookmark-line"
                                          }
                                          color={colors.app.e}
                                          size="24"
                                        ></RemixIcon>
                                      );
                                    }}
                                  </Pressable>
                                </View>
                              )}
                            </Pressable>
                          </View>
                        ))}
                      </View>
                    ) : businesses?.length === 0 &&
                      !isBusinessLoading &&
                      !isUserLoading ? (
                      <View className="flex-1 justify-center items-center">
                        <Font>No Businesses Found</Font>
                      </View>
                    ) : (
                      <></>
                    )}
                  </>
                ) : searchParams.itemType.toLowerCase().includes("product") ? (
                  products?.length >= 1 ? (
                    <View className="flex-row w-full p-0 flex-wrap">
                      {products?.map((e, i) => (
                        <View className="w-1/2 lg:w-1/4 p-2" key={i}>
                          <Pressable
                            className="w-full rounded-lg overflow-hidden border border-gray-200 bg-app-a shadow-black shadow-lg  active:bg-app-c1"
                            onPress={() => goToProductDetails(e.id)}
                          >
                            <View className="h-44 w-full">
                              <Image
                                source={
                                  e.cover_image_url
                                    ? {
                                        uri: `${DEV_API}/media/${e.cover_image_url}`,
                                      }
                                    : require("../../../assets/bip3.jpg")
                                }
                                className="w-full h-full"
                              ></Image>
                            </View>
                            <View className="p-2">
                              <Font
                                className="text-md text-app-d mb-1 leading-5"
                                weight={600}
                                numberOfLines={2}
                              >
                                {e.title}
                              </Font>

                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <Font
                                  className="text-[15px] text-app-d"
                                  numberOfLines={1}
                                  weight={700}
                                >
                                  {" "}
                                  $ {e.price / 100 || e.sale_price / 100}{" "}
                                </Font>
                                <Font
                                  className="text-md text-app-e1 line-through ml-2"
                                  weight={600}
                                  numberOfLines={1}
                                >
                                  {" "}
                                  $ {e.price / 100 || e.sale_price / 100}
                                </Font>
                              </View>
                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <RemixIcon
                                  name="star-fill"
                                  size={16}
                                  color={colors.app.i}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                <Font
                                  className="text-md text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {" "}
                                  {e.product_details.average_rating} |{" "}
                                  {e.product_details.total_reviews} reviews
                                </Font>
                              </View>
                            </View>
                          </Pressable>
                        </View>
                      ))}
                    </View>
                  ) : products?.length === 0 && !isProductsLoading ? (
                    <View className="flex-1 w-full justify-center items-center">
                      <Font>No products found.</Font>
                    </View>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {isBusinessLoading || isProductsLoading ? (
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                ) : (
                  <></>
                )}
                {!isBusinessLoading &&
                !isProductsLoading &&
                (searchParams.itemType.toLowerCase().includes("product")
                  ? productsPagination.offset + perPageRecords <
                    productsPagination.total_records
                  : searchParams.itemType.toLowerCase().includes("listing")
                  ? businessesPagination.offset + perPageRecords <
                    businessesPagination.total_records
                  : null) ? (
                  <View className="flex-1 flex-row lg:justify-end px-4 pt-4 pb-2">
                    <Button
                      label="Load more"
                      className="w-full lg:w-60"
                      type="primary"
                      onPress={() => {
                        if (
                          searchParams.itemType
                            .toLowerCase()
                            .includes("listing")
                        ) {
                          getAllListings(
                            businessesPagination.offset + perPageRecords,
                            perPageRecords
                          );
                        } else if (
                          searchParams.itemType
                            .toLowerCase()
                            .includes("product")
                        ) {
                          getAllProducts(
                            productsPagination.offset + perPageRecords,
                            perPageRecords
                          );
                        }
                      }}
                    ></Button>
                  </View>
                ) : (
                  <></>
                )}
              </ScrollView>
            </View>
            <View className="w-full lg:flex-1 h-full hidden lg:flex mt-5">
              {/* <Maps className="flex-1"></Maps> */}
              <View className="w-full px-4">
                <Widget></Widget>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  );
};
