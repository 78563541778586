import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, RefreshControl } from "react-native";
import { Image, Pressable, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { ResponseModal } from "../../components/response-modal";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import {
  axiosPost,
  DEV_API,
  getBookingById,
  postCancelBooking,
} from "../../services/api";
import { Unautorized } from "./unautorized";
import { catchAPIErrorText } from "../../../utils";
import Toast from "react-native-toast-message";
export const BookingDetails = ({ navigation, route }) => {
  const isFocused = useIsFocused();
  const { user, userToken, setUser, setToken, isAuthLoading } =
    useContext(AuthContext);
  const {
    userData,
    bookingDetails,
    selectedBookingId,
    userBookings,
    setBookingDetails,
  } = useContext(UserContext);
  const [review, setReview] = useState({ rating: 0, message: undefined });
  const [sendingReview, setSendingReview] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
    showSecondaryButton: false,
  });
  const getBookingDetails = async () => {
    try {
      setIsLoading(true);
      const res = await getBookingById({
        token: userToken,
        booking_id: route.params.booking_id,
      });
      if (res.status === 200) {
        setBookingDetails(res.data.data);
      } else {
        console.log(
          "booking-details, get booking details res error : ",
          res.data
        );
      }
    } catch (error) {
      console.log("booking-details, get booking details error: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isFocused && user && userToken !== null) {
      getBookingDetails();
    }
  }, [user, userToken, isFocused]);
  useEffect(() => {
    if (bookingDetails !== null) {
      getUnitName();
    }
  }, [bookingDetails]);

  const getUnitName = () => {
    if (bookingDetails?.listing) {
      const keys = Object.keys(bookingDetails?.listing?.services || {});
      const data = keys.forEach((e) => {
        const a = bookingDetails?.listing?.services[e].filter(
          (ee) => bookingDetails?.service_id === ee.service_unit.id
        );
        setServiceDetails(a[0]);
      });
    }
  };
  const onCancelBooking = async () => {
    try {
      setIsCancelling(true);
      const res = await postCancelBooking({
        token: userToken,
        booking_id: bookingDetails.id,
      });
      if (res.status === 200) {
        setModal({
          isVisible: true,
          message: res.data.message,
        });
        setBookingDetails((e) => ({ ...e, status: "cancelled_by_customer" }));
        // navigation.navigate("bookings");
      } else {
        console.log("booking-details, cancel booking res error: ", res);
      }
    } catch (error) {
      console.log("booking-details, cancel booking error: ", res);
    } finally {
      setIsCancelling(false);
    }
  };

  const calculateOpeningHours = ({ startTime, closeTime }) => {
    const startHr = Math.floor(startTime / 60);
    const startMin = Math.floor(startTime % 60);
    const closeHr = Math.floor(closeTime / 60);
    const closeMin = Math.floor(closeTime % 60);

    const opensAt =
      (startHr <= 12 ? startHr : startHr - 12) +
      ":" +
      (startMin < 10 ? `0${startMin}` : startMin) +
      " " +
      (startHr < 12 ? "AM" : "PM");
    const closesAt =
      (closeHr <= 12 ? closeHr : closeHr - 12) +
      ":" +
      (closeMin < 10 ? `0${closeMin}` : closeMin) +
      " " +
      (closeHr < 12 ? "AM" : "PM");
    return opensAt + " - " + closesAt;
  };

  const getBusinessDetails = (listingId) => {
    navigation.navigate("listing", { id: listingId });
  };

  const submitReviewHandler = async () => {
    if (!review.rating) return;
    setSendingReview(true);
    await axiosPost({
      Url: `/listings/${bookingDetails.listing.id}/reviews`,
      token: userToken,
      data: review,
    })
      .then((data) => {
        Toast.show({
          type: "success",
          text1: "Success",
          text2: data?.data?.message || "Successfully added review",
        });
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: "Review Error",
          text2: catchAPIErrorText(error, "something went wrong!"),
        });
      })
      .finally(() => {
        setSendingReview(false);
      });
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="rounded-t-2xl "
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                setRefreshing(true);
                await getBookingDetails();
                setRefreshing(false);
              }}
            />
          }
        >
          {bookingDetails && !isLoading ? (
            <View className="flex-1 h-full w-full p-4 items-center">
              <View className="my-5 hidden lg:flex flex-row w-full max-w-screen-lg">
                <Font className="text-lg font-semibold text-app-d flex-1">
                  Booking Details
                </Font>
              </View>
              <View className="flex-col lg:flex-row lg:max-w-screen-lg w-full">
                <View className=" w-full lg:w-4/6 p-0 lg:pr-4 ">
                  <View className="flex w-full">
                    <Card
                      title="Booking Details"
                      Widget={() => (
                        <View
                          className={`rounded-full px-2 py-1 ${
                            ["completed", "confirmed", "verified"].includes(
                              bookingDetails.status
                            )
                              ? "bg-app-g3 border border-app-g"
                              : ["created", "pending_confirmation"].includes(
                                  bookingDetails.status
                                )
                              ? "bg-app-h3 border border-app-h"
                              : [
                                  "rejected",
                                  "cancelled_by_customer",
                                  "cancelled_by_vendor",
                                ].includes(bookingDetails.status) &&
                                "bg-app-e3 border border-app-e"
                          }`}
                        >
                          <Font
                            className={`uppercase text-xs ${
                              ["completed", "confirmed", "verified"].includes(
                                bookingDetails.status
                              )
                                ? "text-app-g"
                                : ["created", "pending_confirmation"].includes(
                                    bookingDetails.status
                                  )
                                ? "text-app-h"
                                : [
                                    "rejected",
                                    "cancelled_by_customer",
                                    "cancelled_by_vendor",
                                  ].includes(bookingDetails.status) &&
                                  "text-app-e"
                            }`}
                            weight={600}
                          >
                            {bookingDetails.status === "cancelled_by_customer"
                              ? "cancelled"
                              : bookingDetails.status === "pending_confirmation"
                              ? "confirmation pending"
                              : bookingDetails.status}
                          </Font>
                        </View>
                      )}
                    >
                      <View className="flex-1 w-full px-4">
                        {bookingDetails?.qr_url && (
                          <View className="items-center mb-2 pb-5 border-b border-gray-100">
                            <View className="w-80 h-80 bg-app-c1">
                              <Image
                                source={{
                                  uri: `${DEV_API}/media/${bookingDetails?.qr_url}`,
                                }}
                                className="w-80 h-80"
                              ></Image>
                            </View>
                          </View>
                        )}
                        <View className="w-full flex-row flex-wrap py-4">
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Booking Id
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              # {bookingDetails?.id}
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Booking For
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {new Date(
                                bookingDetails?.sessions[0]?.date
                              ).toDateString()}
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Number of{" "}
                              {`${
                                bookingDetails.listing
                                  ? serviceDetails?.service_unit?.name
                                  : "unit"
                              }s`}
                              {/* {getUnitName()} */}
                              {/* {selectedBookingId.serviceUintName} */}
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {
                                bookingDetails?.sessions[0]
                                  ?.service_unit_quantity
                              }
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Booking Slot
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {calculateOpeningHours({
                                startTime:
                                  bookingDetails?.sessions[0]?.start_in_min,
                                closeTime:
                                  bookingDetails?.sessions[0]?.end_in_min,
                              })}
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Total Amount
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              $ {bookingDetails?.total_amount / 100}
                            </Font>
                          </View>
                          {/* <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Booking Ends At
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {bookingDetails?.sessions[0]?.end_in_min}
                            </Font>
                          </View> */}
                        </View>
                      </View>
                    </Card>
                  </View>
                  <View className="flex-1 lg:flex w-full">
                    <Card title="Customer Details">
                      <View className="flex-1 w-full">
                        <View className="w-full flex-1 px-4 pt-0">
                          <View className="flex-1 w-full mb-2">
                            <Font className="text-app-d" weight={600}>
                              Full name
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {`${userData?.first_name} ${userData?.last_name}`}
                            </Font>
                          </View>
                          <View className="flex-1 w-full mb-2">
                            <Font className="text-app-d" weight={600}>
                              Email
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {userData?.email}
                            </Font>
                          </View>
                          <View className="flex-1 w-full mb-2">
                            <Font className="text-app-d" weight={600}>
                              Phone
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {userData?.phone}
                            </Font>
                          </View>
                          <View className="flex-1 w-full mb-2">
                            <Font className="text-app-d" weight={600}>
                              Address
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              user address
                              {/* {bookingDetails?.customer.address} */}
                            </Font>
                          </View>
                        </View>
                      </View>
                    </Card>
                  </View>
                  {bookingDetails?.listing && (
                    <View className="hidden w-full lg:flex">
                      <Card title="Listing">
                        {bookingDetails?.listing ? (
                          <View className="flex-1 w-full px-3">
                            <Pressable
                              className="w-full active:bg-app-c1 rounded-lg border border-gray-200 flex-row mb-4 p-2"
                              onPress={() =>
                                getBusinessDetails(bookingDetails.listing.id)
                              }
                            >
                              <View className="w-32 h-32 flex overflow-hidden rounded-lg">
                                <View className="w-full h-full">
                                  <Image
                                    source={
                                      bookingDetails.listing.cover_image_url
                                        ? {
                                            uri: `${DEV_API}/media/${bookingDetails.listing.cover_image_url}`,
                                          }
                                        : require("../../../assets/bg.jpg")
                                    }
                                    className="w-full h-full object-contain "
                                  ></Image>
                                </View>
                                {/* <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                                  <Pressable
                                    className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden"
                                    // onPress={() => onBookmark(bookingDetails.id)}
                                  >
                                    <RemixIcon
                                      name="bookmark-fill"
                                      color={colors.app.e}
                                      size="18"
                                    ></RemixIcon>
                                  </Pressable>
                                  <View className="flex-1"></View>
                                </View> */}
                              </View>
                              <View className="justify-center px-4 flex-1">
                                <View className="flex-1">
                                  <View
                                    className="flex-row items-center pb-2"
                                    style={{
                                      marginVertical: 2,
                                    }}
                                  >
                                    <Font
                                      className="text-xs lg:text-md text-app-f"
                                      numberOfLines={1}
                                      weight={500}
                                    >
                                      {
                                        bookingDetails.listing.listing_category
                                          .title
                                      }
                                    </Font>
                                  </View>
                                  <View className="flex-1">
                                    <Font
                                      className="text-[15px] text-app-d leading-4"
                                      numberOfLines={2}
                                      weight={600}
                                    >
                                      {bookingDetails.listing.title}
                                    </Font>
                                  </View>
                                  {bookingDetails.listing.total_reviews > 0 && (
                                    <View
                                      className="flex-row items-center"
                                      style={{
                                        marginVertical: 2,
                                      }}
                                    >
                                      <RemixIcon
                                        name="star-fill"
                                        size={16}
                                        color={colors.app.i}
                                        className="mr-1 flex-shrink-0"
                                      ></RemixIcon>
                                      <Font
                                        className="text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        {" "}
                                        {`${bookingDetails.listing.average_rating} / 5`}{" "}
                                        ({bookingDetails.listing.total_reviews}){" "}
                                        {`${
                                          bookingDetails.listing
                                            .total_reviews === 1
                                            ? "review"
                                            : "reviews"
                                        }`}
                                      </Font>
                                    </View>
                                  )}

                                  <View
                                    className="flex-row items-center mb-1"
                                    style={{
                                      marginVertical: 2,
                                    }}
                                  >
                                    <RemixIcon
                                      name="map-pin-fill"
                                      color={colors.app.d1}
                                      size={20}
                                      className="mr-1"
                                    ></RemixIcon>
                                    {bookingDetails.listing.city !== null &&
                                    bookingDetails.listing.state !== null &&
                                    bookingDetails.listing.country !== null ? (
                                      <Font
                                        className=" text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        {bookingDetails.listing.city +
                                          ", " +
                                          bookingDetails.listing.state +
                                          ", " +
                                          bookingDetails.listing.country}
                                      </Font>
                                    ) : (
                                      <Font
                                        className=" text-app-d1"
                                        weight={500}
                                        numberOfLines={1}
                                      >
                                        {" "}
                                        city, state, country.
                                      </Font>
                                    )}
                                  </View>
                                  <View
                                    className="flex-row items-center"
                                    style={{
                                      marginVertical: 2,
                                      paddingLeft: 5,
                                    }}
                                  >
                                    <Font
                                      className="text-app-d"
                                      numberOfLines={1}
                                      weight={500}
                                    >
                                      {" "}
                                      $ {bookingDetails.listing.min_price / 100}
                                      {" -  "}${" "}
                                      {bookingDetails.listing.max_price / 100}{" "}
                                    </Font>
                                  </View>
                                </View>
                              </View>
                              {/* <View className="hidden lg:flex p-4">
                                <Pressable>
                                  <RemixIcon
                                    name="bookmark-fill"
                                    color={colors.app.e}
                                    size="24"
                                  ></RemixIcon>
                                </Pressable>
                              </View> */}
                            </Pressable>
                            {/* <View className="flex-row gap-3 w-full border-2 border-app-d1 rounded-lg py-3 justify-center">
                             
                              <MaterialIcons
                                name="message"
                                size={24}
                                color="black"
                              />
                              <Font className="text-center">
                                Contact Vendor
                              </Font>
                            </View> */}
                          </View>
                        ) : (
                          <View className="flex-1 w-full">
                            <Font>Error occured while fetching details.</Font>
                          </View>
                        )}
                      </Card>
                    </View>
                  )}
                  {/* <View className="flex-1 w-full">
                  <Card title="Service">
                    <View className="flex-1 w-full px-4 pt-0">
                      {bookingDetails?.services.map((e, i) => (
                        <View
                          className="flex-row items-center justify-start w-full border-b border-gray-100"
                          key={`services_${i}`}
                        >
                          <View className="bg-app-e w-1 h-5"></View>
                          <View className="w-full py-2 flex-row justify-between ml-2">
                            <View className="flex-1">
                              <Font className="text-app-d" weight={600}>
                                {e.name}
                              </Font>
                            </View>
                            <View className="flex-1 items-end pr-4">
                              <Font className="text-app-d" weight={500}>
                                $ {e.subtotal}
                              </Font>
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                  </Card>
                </View> */}
                </View>
                <View className="flex-1 w-full lg:w-2/6 p-0 lg:pl-4">
                  <Card title="Payment Details">
                    <View className="w-full flex-row flex-wrap p-4 pt-0">
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d" weight={600}>
                          Payment Method
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {["created"].includes(bookingDetails.status)
                            ? "- (Payment is still pending...)"
                            : "-"}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d" weight={600}>
                          Total Price
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {`${bookingDetails?.currency_symbol} ${
                            bookingDetails?.total_amount
                              ? bookingDetails?.total_amount / 100
                              : bookingDetails?.total_amount ?? "-"
                          }`}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Reward/Coupon:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {bookingDetails?.applied_coupon?.coupon_code || "-"}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d" weight={600}>
                          Total Discount
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {bookingDetails?.master_order?.redeemed_rewards
                            ?.discount_amount
                            ? ("- " +
                                bookingDetails?.master_order?.currency_symbol ||
                                "") +
                              " " +
                              bookingDetails?.master_order?.redeemed_rewards
                                ?.discount_amount /
                                100
                            : bookingDetails?.master_order?.redeemed_rewards
                                ?.discount_amount ?? "-"}
                        </Font>
                      </View>
                      {/* <View className="w-full flex-row justify-between mb-2">
                            <Font className="text-app-d" weight={600}>
                              Pay with reward
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              $ {bookingDetails?.payment.by_reward}
                            </Font>
                          </View> */}
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d" weight={600}>
                          Total Payment
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {`${bookingDetails?.currency_symbol} ${
                            bookingDetails?.total_amount
                              ? bookingDetails?.total_amount / 100
                              : bookingDetails?.total_amount ?? "-"
                          }`}
                        </Font>
                      </View>
                      {[
                        "rejected",
                        "cancelled_by_customer",
                        "cancelled_by_vendor",
                      ].includes(bookingDetails?.status) ? (
                        <View className="w-full flex-row items-center mb-2">
                          <Font className="text-app-g1" weight={600}>
                            {bookingDetails?.payment_status === "refunded"
                              ? "Your total return amount Successfully Refunded."
                              : "Refund will be processed shortly."}
                          </Font>
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                  </Card>
                  {bookingDetails?.listing ? (
                    <Card title="Listing" className="lg:hidden">
                      <View className="px-4 block">
                        {bookingDetails?.listing ? (
                          <Pressable
                            className="w-full active:bg-app-c1 rounded-lg border border-gray-200 flex-row"
                            onPress={() =>
                              getBusinessDetails(bookingDetails.listing.id)
                            }
                          >
                            <View className="w-32 h-36 lg:w-40 block overflow-hidden rounded-lg">
                              <Image
                                source={
                                  bookingDetails.listing.cover_image_url
                                    ? {
                                        uri: `${DEV_API}/media/${bookingDetails.listing.cover_image_url}`,
                                      }
                                    : require("../../../assets/bg.jpg")
                                }
                                className="w-full h-full object-contain "
                              ></Image>
                            </View>
                            <View className="justify-start px-3 py-1 flex-1">
                              <View className="flex-row items-center pb-1">
                                <Font
                                  className="text-xs lg:text-md text-app-f"
                                  numberOfLines={1}
                                  weight={500}
                                >
                                  {
                                    bookingDetails.listing.listing_category
                                      .title
                                  }
                                </Font>
                              </View>
                              <View className="flex pb-3">
                                <Font
                                  className="text-[15px] text-app-d leading-4"
                                  numberOfLines={2}
                                  weight={600}
                                >
                                  {bookingDetails.listing.title}
                                </Font>
                              </View>
                              {bookingDetails.listing.total_reviews > 0 && (
                                <View className="flex-row items-center pb-1">
                                  <RemixIcon
                                    name="star-fill"
                                    size={16}
                                    color={colors.app.i}
                                    className="mr-1 flex-shrink-0"
                                  ></RemixIcon>
                                  <Font
                                    className="text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    {" "}
                                    {`${bookingDetails?.listing?.average_rating} / 5`}{" "}
                                    ({bookingDetails?.listing?.total_reviews}){" "}
                                    {`${
                                      bookingDetails?.listing?.total_reviews ===
                                      1
                                        ? "review"
                                        : "reviews"
                                    }`}
                                  </Font>
                                </View>
                              )}
                              <View className="flex-row items-center pb-1">
                                <RemixIcon
                                  name="map-pin-fill"
                                  color={colors.app.d1}
                                  size={20}
                                  className="mr-1"
                                ></RemixIcon>
                                {bookingDetails.listing.city !== null &&
                                bookingDetails.listing.state !== null &&
                                bookingDetails.listing.country !== null ? (
                                  <Font
                                    className=" text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    {bookingDetails.listing.city +
                                      ", " +
                                      bookingDetails.listing.state +
                                      ", " +
                                      bookingDetails.listing.country}
                                  </Font>
                                ) : (
                                  <Font
                                    className=" text-app-d1"
                                    weight={500}
                                    numberOfLines={1}
                                  >
                                    {" "}
                                    city, state, country.
                                  </Font>
                                )}
                              </View>
                              <View className="flex-row justify-start">
                                <Font
                                  className="text-app-d1"
                                  numberOfLines={1}
                                  weight={500}
                                >
                                  {" "}
                                  $ {bookingDetails.listing.min_price / 100}
                                  {" -  "}${" "}
                                  {bookingDetails.listing.max_price / 100}{" "}
                                </Font>
                              </View>
                            </View>
                            <View className="hidden lg:flex p-4">
                              <Pressable>
                                <RemixIcon
                                  name="bookmark-fill"
                                  color={colors.app.e}
                                  size="24"
                                ></RemixIcon>
                              </Pressable>
                            </View>
                          </Pressable>
                        ) : (
                          <View className="flex-1">
                            <Font>Error occured while fetching details.</Font>
                          </View>
                        )}
                      </View>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {bookingDetails?.status === "completed" ? (
                    <Card title="Leave a review">
                      <View className="flex-1 w-full px-4 pt-0">
                        <View className="w-full mb-5">
                          <Font className="text-app-d1 pb-2" weight={500}>
                            Rating:{" "}
                          </Font>
                          <Input
                            type="rating"
                            value={review.rating}
                            setValue={(e) =>
                              setReview({ ...review, rating: e })
                            }
                          ></Input>
                        </View>
                        <View className="w-full mb-5">
                          <Font className="text-app-d1 pb-2" weight={500}>
                            Message:{" "}
                          </Font>
                          <Input
                            type="longtext"
                            value={review.message}
                            setValue={(e) =>
                              setReview({ ...review, message: e })
                            }
                          ></Input>
                        </View>
                        <View className="w-full">
                          <Button
                            type="primary"
                            label="send"
                            onPress={submitReviewHandler}
                            waitState={sendingReview}
                          ></Button>
                        </View>
                      </View>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {(bookingDetails?.status === "created" ||
                    bookingDetails?.status === "pending_confirmation " ||
                    bookingDetails?.status === "confirmed") &&
                  serviceDetails?.allow_reschedule ? (
                    <View className="flex-1 lg:flex w-full">
                      <Card title="Reschedule Booking">
                        <View className="flex-1 w-full px-2 pt-0">
                          {/* <View className="w-full flex-row pb-3">
                              <Font>
                                Reschedule your booking.
                              </Font>
                            </View> */}
                          {isCancelling ? (
                            <View className="flex w-full p-0 lg:pl-4 mb-4 bg-app-e3 border border-app-e h-10 rounded-lg justify-center">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.e}
                              ></ActivityIndicator>
                            </View>
                          ) : (
                            <View className="flex-1 w-full p-0 lg:pl-4 mb-4">
                              <Button
                                type="accent_light"
                                label="Reschedule"
                                onPress={() =>
                                  navigation.navigate("booking", {
                                    bookingId: bookingDetails.id,
                                    sessionId: bookingDetails.sessions[0].id,
                                    bookableServiceId:
                                      bookingDetails.service_id,
                                    listingId: bookingDetails.listing_id,
                                    reschedule: true,
                                  })
                                }
                              ></Button>
                            </View>
                          )}
                        </View>
                      </Card>
                    </View>
                  ) : (
                    <></>
                  )}
                  {bookingDetails?.status === "created" ||
                  bookingDetails?.status === "pending_confirmation" ||
                  bookingDetails?.status === "confirmed" ||
                  bookingDetails?.status === "verified" ? (
                    <View className="flex-1 lg:flex w-full">
                      <Card title="Cancel Booking">
                        <View className="flex-1 w-full px-4 pt-0">
                          {serviceDetails?.cancellation_penalty ? (
                            <View className="w-full flex-row pb-8">
                              <Font>Cancellation charges : </Font>
                              <Font className="text-app-d1">
                                {serviceDetails?.cancellation_penalty}%
                              </Font>
                            </View>
                          ) : (
                            <></>
                          )}
                          {isCancelling ? (
                            <View className="flex w-full p-0 mb-4 bg-app-e3 border border-app-e h-10 rounded-lg justify-center">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.e}
                              ></ActivityIndicator>
                            </View>
                          ) : (
                            <View className="flex-1 w-full p-0 mb-4">
                              <Button
                                type="primary_light"
                                label="Cancel"
                                onPress={onCancelBooking}
                              ></Button>
                            </View>
                          )}
                        </View>
                      </Card>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            </View>
          ) : bookingDetails === null && !isLoading ? (
            <View>
              <Font>No Details found</Font>
            </View>
          ) : (
            <View className="flex-1 w-full justify-center h-40">
              <ActivityIndicator
                size={"small"}
                color={colors.app.e}
              ></ActivityIndicator>
            </View>
          )}
          <Footer></Footer>

          {modal.isVisible && (
            <View className="flex-1">
              <ResponseModal
                modalContent={modal.message}
                modalVisible={modal.isVisible}
                setVisible={(e) => setModal({ ...modal, isVisible: e })}
                primaryButtonLabel="Dismiss"
                onPrimaryButtonPress={() => {
                  setModal({ ...modal, isVisible: false });
                }}
                showSecondaryButton={modal.showSecondaryButton}
              ></ResponseModal>
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
};
