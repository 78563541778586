import React from "react";
import {
  View,
  Platform,
  RefreshControl,
  ScrollView,
  Pressable,
  TextInput,
  Image,
  ActivityIndicator,
} from "react-native";
import { Font } from "../../components/font";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { getFullDateTime } from "../../../utils/date-time-util";
import { Button } from "../../components/input";
import { useUserFeedContext } from "../../context/user-feed-context";
import { cloneDeep } from "lodash";
import moment from "moment";
import { UserRoles, catchAPIErrorText } from "../../../utils";
import { ResponseModal } from "../../components/response-modal";
import {
  DEV_API,
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { SharedContext } from "../../context/sharedContext";

const UserFeedComments = ({ route }) => {
  const pid = +route.params?.slug;
  const { userToken } = React.useContext(AuthContext);
  const { userData } = React.useContext(UserContext);
  const { screen } = React.useContext(SharedContext);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [operationQueue, setOperationQueue] = React.useState([]);
  const [inputFor, setInputFor] = React.useState("comment");
  const [replyForCid, setReplyForCid] = React.useState();
  const [commentIndex, setCommentIndex] = React.useState();
  const [replyIndex, setReplyIndex] = React.useState();
  const [isInOperation, setIsInOperation] = React.useState();
  const [isPosting, setIsPosting] = React.useState(false);
  const [vendorFeedCommentsData, setVendorFeedCommentsData] = React.useState();
  const [isFeedCommentsDataFailed, setIsFeedCommentsDataFailed] =
    React.useState();
  const [isEditing, setIsEditing] = React.useState();
  const [text, setText] = React.useState();
  const [commentIsLoading, setcommentIsLoading] = React.useState();
  const [scrollRef, setScrollRef] = React.useState();
  const {
    tabStatus,
    setTabStatus,
    vendorFeedComments,
    setVendorFeedComments,
    posts,
    setPosts,
  } = useUserFeedContext();

  React.useEffect(() => {
    setTabStatus({ curr: "My Feed", prev: tabStatus.curr });
  }, []);

  const handleError = (e) => {
    setShowResponseModal({
      visible: true,
      onRequestClose: () => {
        setShowResponseModal({
          visible: false,
          onRequestClose: () => null,
          bodyText: "",
        });
      },
      bodyText: catchAPIErrorText(e, "Unknown Error while In Vendor Posts"),
    });
  };

  const getVendorFeedComments = async () => {
    setcommentIsLoading(true);
    let data;
    try {
      const res = await axiosGet({
        Url: "/users/me/posts/" + pid + "/comments",
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.comments;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Feed Comments"
        ),
      });
    } finally {
      setVendorFeedCommentsData(data || {});
      setVendorFeedComments({ data, id: pid });
      setcommentIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isInOperation && operationQueue?.length) {
      let operation;
      setOperationQueue((data) => {
        data = cloneDeep(data);
        [operation] = data.splice(0, 1);
        operation();
        return data;
      });
    }
  }, [isInOperation, operationQueue]);

  if (!userData?.id || !pid) {
    return navigation.replace("vendor-feed");
  }

  if (
    !vendorFeedCommentsData &&
    !vendorFeedComments?.[pid] &&
    !commentIsLoading
  ) {
    getVendorFeedComments();
  } else if (!vendorFeedCommentsData && vendorFeedComments?.[pid]) {
    setVendorFeedCommentsData(vendorFeedComments[pid] || {});
  }

  if (isFeedCommentsDataFailed) {
    return (
      <View className="flex-1 justify-center items-center bg-gray-50">
        {showResponseModal.visible && (
          <ResponseModal
            modalVisible={showResponseModal.visible}
            primaryButtonLabel={"Okay"}
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">
            Unable to Get Data from the Server
          </Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!commentIsLoading) {
                getVendorFeedComments();
                setIsFeedCommentsDataFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!vendorFeedComments || !vendorFeedCommentsData) {
    return (
      <View className="flex-1 justify-center items-center bg-gray-50">
        {showResponseModal.visible && (
          <ResponseModal
            modalVisible={showResponseModal.visible}
            primaryButtonLabel={"Okay"}
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!vendorFeedComments && !vendorFeedCommentsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  const deleteFeedComment = async (cid, commentIndex) => {
    try {
      setIsInOperation(true);
      const res = await axiosDelete({
        Url: "/users/me/posts/" + pid + "/comments/" + cid,
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 204) {
        setVendorFeedCommentsData((data) => {
          if (data?.length) {
            data.splice(commentIndex, 1);
          }
          return cloneDeep(data);
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted comment",
        });
        let commentsData = vendorFeedComments[pid];
        commentsData = commentsData.filter((c) => c.id !== cid);
        setVendorFeedComments({ data: commentsData, id: pid });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Deleting Comment"),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const deleteFeedCommentReply = async (cid, rid, commentIndex, replyIndex) => {
    try {
      setIsInOperation(true);
      const res = await axiosDelete({
        Url: "/users/me/posts/" + pid + "/comments/" + cid + "/replies/" + rid,
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 204) {
        setVendorFeedCommentsData((data) => {
          if (data?.length && data?.replies?.length) {
            data[commentIndex].replies.splice(replyIndex, 1);
          }
          return cloneDeep(data);
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted reply",
        });
        let commentsData = vendorFeedComments[pid];
        commentsData[commentIndex].replies = commentsData[
          commentIndex
        ].replies.filter((r) => r.id !== rid);
        setVendorFeedComments({ data: commentsData, id: pid });
        const post_index = posts?.findIndex((p) => p.id == pid);
        if (post_index && post_index > -1) {
          posts[post_index].comments--;
          setPosts({
            data: posts,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Deleting Comment Reply"
        ),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const updateFeedComment = async (dataObj, commentIndex) => {
    let data;
    try {
      setIsPosting(true);
      setIsInOperation(true);
      const res = await axiosPatch({
        Url: "/users/me/posts/" + pid + "/comments/" + dataObj.id,
        data: { message: dataObj.message },
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.post;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        const index = commentsData.findIndex((c) => c.id == dataObj.id);
        if (index > -1) commentsData[index] = data;
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((data1) => {
          data1 = cloneDeep(data1);
          if (data1?.length) {
            // const i = data.findIndex((v) => v.id === dataObj.id);
            data1[commentIndex] = data;
          } else {
            data1 = [data];
          }
          return data1;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Updating Comment Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsPosting(false);
      setIsInOperation(false);
    }
  };

  const updateFeedCommentReplies = async (
    dataObj,
    cid,
    commentIndex,
    replyIndex
  ) => {
    let data;
    try {
      setIsPosting(true);
      setIsInOperation(true);
      const res = await axiosPatch({
        Url:
          "/users/me/posts/" +
          pid +
          "/comments/" +
          cid +
          "/replies/" +
          dataObj.id,
        data: { message: dataObj.message },
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.post;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        const index = commentsData[commentIndex].replies.findIndex(
          (c) => c.id == data.id
        );
        if (index > -1) commentsData[commentIndex].replies[index] = data;
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((data1) => {
          data1 = cloneDeep(data1);
          if (data1[commentIndex].replies?.length) {
            // const i = data[commentIndex].replies.findIndex((v) => v.id === dataObj.id);
            data1[commentIndex].replies[replyIndex] = data;
          } else {
            data1[commentIndex].replies = [data];
          }
          return data1;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Updating Comment Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsPosting(false);
      setIsInOperation(false);
    }
  };

  const addFeedComment = async (dataObj) => {
    let data;
    try {
      setIsInOperation(true);
      setIsPosting(true);
      const res = await axiosPost({
        Url: "/users/me/posts/" + pid + "/comments",
        data: { message: dataObj.message },
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 201) {
        data = res.data.comment;
        delete dataObj.processText;
        let commentsData = vendorFeedComments?.[pid];
        commentsData = [...(commentsData || []), data];
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((values) => {
          values = cloneDeep(values);
          if (!values) {
            values = [];
          }
          values.push(data);
          return values;
        });
        // setShowResponseModal({
        //   visible: true,
        //   onRequestClose: () => {
        //     setShowResponseModal({
        //       visible: false,
        //       onRequestClose: () => null,
        //       bodyText: "",
        //     });
        //   },
        //   bodyText: res.data.message,
        // });
        const post_index = posts?.findIndex((p) => p.id == pid);
        if (post_index && post_index > -1) {
          posts[post_index].comments++;
          setPosts({
            data: posts,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Adding Comment Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsPosting(false);
      setIsInOperation(false);
    }
  };

  const addFeedCommentReply = async (dataObj, cid, commentIndex) => {
    let data;
    try {
      setIsPosting(true);
      setIsInOperation(true);
      const res = await axiosPost({
        Url: "/users/me/posts/" + pid + "/comments/" + cid + "/replies",
        data: { message: dataObj.message },
        token: userToken,
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 201) {
        data = res.data.reply;
        delete dataObj.processText;
        let commentsData = vendorFeedComments[pid];
        commentsData[commentIndex].replies = [
          ...(commentsData[commentIndex]?.replies || []),
          data,
        ];
        setVendorFeedComments({ data: commentsData, id: pid });
        setVendorFeedCommentsData((values) => {
          values = cloneDeep(values);
          values[commentIndex].replies = [
            ...(values[commentIndex]?.replies || []),
            data,
          ];
          return values;
        });
        // setShowResponseModal({
        //   visible: true,
        //   onRequestClose: () => {
        //     setShowResponseModal({
        //       visible: false,
        //       onRequestClose: () => null,
        //       bodyText: "",
        //     });
        //   },
        //   bodyText: res.data.message,
        // });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Adding Comment Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsPosting(false);
      setIsInOperation(false);
    }
  };

  const onSendTextHandler = (replyInput, isEditing, inputFor) => {
    setText("");
    setVendorFeedCommentsData((data) => {
      data = cloneDeep(data);
      if (isEditing) {
        isEditing = {
          ...isEditing,
          message: replyInput,
          is_edited: true,
          processText: "updating...",
        };
        const i = data.replies?.findIndex((v) => v.id === isEditing.id);
        if (i > -1) {
          data.replies[i] = isEditing;
        }
        if (inputFor === "comment") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() => updateFeedComment(isEditing, commentIndex));
            return q;
          });
        } else if (inputFor === "reply") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() =>
              updateFeedCommentReplies(
                isEditing,
                isEditing.comment_id,
                commentIndex,
                replyIndex
              )
            );
            return q;
          });
          setInputFor("comment");
        }
        setIsEditing(undefined);
      } else {
        const dateNow = moment.now();
        let replyObj = {
          id: "reply-local" + dateNow,
          user_id: userData.id,
          user_role: userData.role,
          user_name: userData.first_name + " " + userData.last_name,
          user_profile_image_url: userData.profile_image_url,
          rating: null,
          message: replyInput,
          is_reply:
            inputFor === "comment" ? false : inputFor === "reply" ? true : null,
          is_edited: false,
          created_at: dateNow,
          updated_at: dateNow,
          processText: "sending...",
        };
        if (!data?.replies) {
          data.replies = [replyObj];
        } else {
          data.replies.push(replyObj);
        }
        if (inputFor === "comment") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() => addFeedComment(replyObj));
            return q;
          });
        } else if (inputFor === "reply") {
          setOperationQueue((q) => {
            q = cloneDeep(q);
            q.push(() =>
              addFeedCommentReply(replyObj, replyForCid, commentIndex)
            );
            return q;
          });
          setInputFor("comment");
        }
      }
      setCommentIndex(undefined);
      setReplyIndex(undefined);
      return data;
    });
  };

  const onDeleteReplyHandler = (
    commentID,
    commentIndex,
    replyID,
    replyIndex
  ) => {
    if (replyIndex > -1) {
      setVendorFeedCommentsData((data) => {
        data = cloneDeep(data);
        data[commentIndex].replies.splice(replyIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() =>
          deleteFeedCommentReply(commentID, replyID, commentIndex, replyIndex)
        );
        return q;
      });
    }
  };

  const onDeleteCommentHandler = (commentID, commentIndex) => {
    if (commentIndex > -1) {
      setVendorFeedCommentsData((data) => {
        data = cloneDeep(data);
        data.splice(commentIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() => deleteFeedComment(commentID, commentIndex));
        return q;
      });
    }
  };

  return (
    <>
      {showResponseModal.visible && (
        <ResponseModal
          modalVisible={showResponseModal.visible}
          primaryButtonLabel={"Okay"}
          onPrimaryButtonPress={showResponseModal.onRequestClose}
          modalContent={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        ref={(ref) => setScrollRef(ref)}
        className="px-4 pb-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={commentIsLoading}
              onRefresh={() => {
                getVendorFeedComments();
              }}
            />
          )
        }
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="p-0 lg:px-4 flex-1   ">
            <Card title="Comments">
              {vendorFeedCommentsData ? (
                Object.keys(vendorFeedCommentsData || {})?.length ? (
                  vendorFeedCommentsData.map((comment, index) => (
                    <View
                      className={`w-full pt-4 ${
                        comment?.replies ? "mx-4 border-b border-gray-200" : ""
                      } flex-col`}
                      key={`vfcd_${comment.id}_${index}`}
                    >
                      <View className="flex-row mx-4 pb-4  " key={comment.id}>
                        <View className="justify-start pt-1 items-center px-4 ">
                          <Image
                            source={
                              comment.user_profile_image_url
                                ? {
                                    uri:
                                      `${DEV_API}/media/` +
                                      comment.user_profile_image_url,
                                  }
                                : require("../../../assets/userImage.jpg")
                            }
                            className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                          ></Image>
                        </View>
                        <View className="justify-start ml-2 flex-1 ">
                          <View className="flex-row w-full flex-wrap justify-between items-center pb-0.5">
                            <View className="flex-1 flex-row items-center flex-wrap ">
                              <Font
                                className="text-app-d text-base mr-2 "
                                weight={600}
                              >
                                {comment?.user_name || "Anonymous User"}
                              </Font>
                              <Font
                                className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                  comment?.user_role === UserRoles.CUSTOMER
                                    ? "bg-app-d3 text-app-d"
                                    : "bg-app-e3 text-app-e"
                                }`}
                              >
                                {comment?.user_role || "Unknown"}
                              </Font>
                            </View>
                            {userData.id === comment.user_id ? (
                              <View className="flex-row items-center flex-wrap h-full justify-end self-stretch">
                                <Pressable
                                  className="p-0.5 mr-2"
                                  onPress={() => {
                                    setIsEditing(comment);
                                    setCommentIndex(index);
                                    setInputFor("comment");
                                    setText(comment.message);
                                    scrollRef.scrollToEnd();
                                  }}
                                >
                                  <RemixIcon
                                    name="ri-pencil-fill"
                                    className="self-start"
                                    size={16}
                                    color={colors.app.d2}
                                    // color="white"
                                  />
                                </Pressable>
                                <Pressable
                                  className="p-0.5"
                                  onPress={() => {
                                    setShowConfirmModal((d) => ({
                                      ...d,
                                      visible: true,
                                      bodyText:
                                        "Do you want to delete this comment?",
                                      onConfirm: () => {
                                        setShowConfirmModal((d1) => {
                                          d1.visible = false;
                                          d1.onConfirm = () => null;
                                          d1.onCancel = () => null;
                                          d1.cancelText = "Cancel";
                                          d1.confirmText = "Confirm";
                                          d1.bodyText = "";
                                          return { ...d1 };
                                        });
                                        onDeleteCommentHandler(
                                          comment.id,
                                          index
                                        );
                                      },
                                      onCancel: () =>
                                        setShowConfirmModal((d1) => {
                                          d1.visible = false;
                                          d1.onConfirm = () => null;
                                          d1.onCancel = () => null;
                                          d1.cancelText = "Cancel";
                                          d1.confirmText = "Confirm";
                                          d1.bodyText = "";
                                          return { ...d1 };
                                        }),
                                    }));
                                  }}
                                >
                                  <RemixIcon
                                    name="ri-delete-bin-5-fill"
                                    className="self-start"
                                    size={16}
                                    color={colors.app.d2}
                                    // color={"white"}
                                  />
                                </Pressable>
                              </View>
                            ) : (
                              <></>
                            )}
                          </View>
                          <View className="flex-row items-center justify-start pb-2">
                            {/* <RemixIcon
                              className="mr-2 self-start h-full"
                              name="ri-discuss-fill"
                              size={16}
                              color={colors.app.d}
                            /> */}
                            <Font
                              numberOfLines={4}
                              className="text-app-d flex-row items-center leading-4"
                            >
                              {comment.is_edited && (
                                <>
                                  <RemixIcon
                                    className="pr-2 -mb-px self-start"
                                    name="ri-pencil-fill"
                                    size={12}
                                    color={colors.app.d}
                                  />
                                  &nbsp;
                                </>
                              )}
                              {comment.message}
                            </Font>
                          </View>
                          <View className="flex-row items-center flex-wrap">
                            <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                              {comment.likes}
                            </Font>
                            <Pressable
                              className="flex-row items-center"
                              onPress={() =>
                                (async () => {
                                  try {
                                    const res = await axiosPost({
                                      Url:
                                        "/users/me/posts/" +
                                        pid +
                                        "/comments/" +
                                        comment.id +
                                        (comment.is_liked
                                          ? "/unlike"
                                          : "/like"),
                                      data: {},
                                      token: userToken,
                                    });
                                    // console.log(
                                    //   "res.status: ",
                                    //   res.status,
                                    //   res.data
                                    // );
                                    if (res.status === 200) {
                                      setVendorFeedCommentsData((data) => {
                                        const is_liked = comment.is_liked;
                                        data[index].is_liked = is_liked
                                          ? false
                                          : true;
                                        if (is_liked) {
                                          data[index].likes--;
                                        } else {
                                          data[index].likes++;
                                        }
                                        return cloneDeep(data);
                                      });
                                    }
                                  } catch (e) {
                                    console.log(
                                      "e.res",
                                      e?.response,
                                      e,
                                      JSON.stringify(e, null, 1)
                                    );
                                    handleError(e);
                                  }
                                })()
                              }
                            >
                              <RemixIcon
                                name="ri-thumb-up-fill"
                                // className=""
                                size={14}
                                color={
                                  comment.is_liked
                                    ? colors.app.f
                                    : colors.app.d1
                                }
                              />
                              <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                Like
                              </Font>
                            </Pressable>
                            <Font className="text-app-d1 leading-4 text-xs">
                              •
                            </Font>
                            {/* <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                              {comment.replies}
                            </Font> */}
                            <Pressable
                              className="flex-row items-center"
                              onPress={() => {
                                setInputFor("reply");
                                setCommentIndex(index);
                                setReplyForCid(comment.id);
                                scrollRef.scrollToEnd();
                              }}
                            >
                              <RemixIcon
                                name="ri-reply-fill"
                                size={14}
                                color={colors.app.d1}
                                className="ml-2"
                              />
                              <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                Reply
                              </Font>
                            </Pressable>
                            <Font className="text-app-d1 leading-4 text-xs">
                              •
                            </Font>
                            <Font className="text-app-d1 leading-4 ml-2 text-sm">
                              {getFullDateTime(comment.updated_at, null, {
                                fromNow: true,
                              })}
                            </Font>
                          </View>
                        </View>
                      </View>
                      {!!comment?.replies?.length && (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                          // className="flex-row mx-4  flex-1 items-start justify-start overflow-auto bg-red-400 "
                        >
                          <View className="pt-1 px-1 lg:px-4 w-max  ">
                            <Font className="text-app-a bg-app-d w-max py-1 px-2 border border-app-d2 rounded ">
                              {comment?.replies?.length || "-"}{" "}
                              {comment?.replies?.length === 1
                                ? "Reply"
                                : "Replies"}
                            </Font>
                          </View>
                          <View className="flex-col items-center justify-start flex-1">
                            {comment.replies.map((reply, i) => (
                              <View
                                className={`py-4 pr-4 w-full ${
                                  i === 0 ? "" : "border-t border-gray-200"
                                } flex-row`}
                                key={`comment${reply.id}_${comment.id}_${index}`}
                              >
                                <View className="justify-start pt-1 items-center px-2 md:px-4">
                                  <Image
                                    source={
                                      reply.user_profile_image_url
                                        ? {
                                            uri:
                                              `${DEV_API}/media/` +
                                              reply.user_profile_image_url,
                                          }
                                        : require("../../../assets/userImage.jpg")
                                    }
                                    className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                                  ></Image>
                                </View>
                                <View className="justify-start ml-2 flex-1  ">
                                  <View className="flex-row flex-wrap justify-between items-center pb-0.5">
                                    <View className="flex-1 flex-row flex-wrap items-center">
                                      <Font
                                        className="text-app-d text-base mr-2"
                                        weight={600}
                                      >
                                        {reply?.user_name || "Anonymous User"}
                                      </Font>
                                      <Font
                                        className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                          reply?.user_role ===
                                          UserRoles.CUSTOMER
                                            ? "bg-app-d3 text-app-d"
                                            : "bg-app-e3 text-app-e"
                                        }`}
                                      >
                                        {reply.user_role || "Unknown"}
                                      </Font>
                                    </View>
                                    {userData.id === reply.user_id ? (
                                      <View className="flex-row items-center flex-wrap h-full justify-end self-stretch">
                                        <Pressable
                                          className="p-0.5 mr-2"
                                          onPress={() => {
                                            reply.comment_id = comment.id;
                                            setIsEditing(reply);
                                            setReplyForCid(comment.id);
                                            setCommentIndex(index);
                                            setReplyIndex(i);
                                            setInputFor("reply");
                                            setText(reply.message);
                                            scrollRef.scrollToEnd();
                                          }}
                                        >
                                          <RemixIcon
                                            name="ri-pencil-fill"
                                            className="self-start"
                                            size={16}
                                            color={colors.app.d2}
                                          />
                                        </Pressable>
                                        <Pressable
                                          className="p-0.5"
                                          onPress={() => {
                                            setShowConfirmModal((d) => ({
                                              ...d,
                                              visible: true,
                                              bodyText:
                                                "Do you want to delete this reply?",
                                              onConfirm: () => {
                                                setShowConfirmModal((d1) => {
                                                  d1.visible = false;
                                                  d1.onConfirm = () => null;
                                                  d1.onCancel = () => null;
                                                  d1.cancelText = "Cancel";
                                                  d1.confirmText = "Confirm";
                                                  d1.bodyText = "";
                                                  return { ...d1 };
                                                });
                                                onDeleteReplyHandler(
                                                  comment.id,
                                                  index,
                                                  reply.id,
                                                  i
                                                );
                                              },
                                              onCancel: () =>
                                                setShowConfirmModal((d1) => {
                                                  d1.visible = false;
                                                  d1.onConfirm = () => null;
                                                  d1.onCancel = () => null;
                                                  d1.cancelText = "Cancel";
                                                  d1.confirmText = "Confirm";
                                                  d1.bodyText = "";
                                                  return { ...d1 };
                                                }),
                                            }));
                                          }}
                                        >
                                          <RemixIcon
                                            name="ri-delete-bin-5-fill"
                                            className="self-start"
                                            size={16}
                                            color={colors.app.d2}
                                          />
                                        </Pressable>
                                      </View>
                                    ) : (
                                      <></>
                                    )}
                                  </View>
                                  {/* <View className="flex-row items-center pb-2">
                                    <Font className="text-app-d1 leading-4">
                                      {reply.processText
                                        ? reply.processText
                                        : fromNow({
                                            date: reply.updated_at,
                                            fromNow,
                                          })}
                                    </Font>
                                  </View> */}
                                  <View className="flex-row items-center pb-2">
                                    {/* <RemixIcon
                                      name="ri-reply-fill"
                                      size={14}
                                      color={colors.app.d}
                                      className="mr-2"
                                    /> */}
                                    <Font
                                      numberOfLines={4}
                                      className="text-app-d flex flex-row items-center leading-4"
                                    >
                                      {reply.is_edited ? (
                                        <>
                                          <RemixIcon
                                            className="self-center"
                                            name="ri-pencil-fill"
                                            size={12}
                                            color={colors.app.d}
                                          />
                                          &nbsp;
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {reply.message}
                                    </Font>
                                  </View>
                                  <View className="flex-row items-center flex-wrap">
                                    <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                      {reply.likes}
                                    </Font>
                                    <Pressable
                                      className="flex-row items-center"
                                      onPress={() =>
                                        (async () => {
                                          try {
                                            const res = await axiosPost({
                                              Url:
                                                "/users/me/posts/" +
                                                pid +
                                                "/comments/" +
                                                comment.id +
                                                "/replies/" +
                                                reply.id +
                                                (reply.is_liked
                                                  ? "/unlike"
                                                  : "/like"),
                                              data: {},
                                              token: userToken,
                                            });
                                            // console.log(
                                            //   "res.status: ",
                                            //   res.status,
                                            //   res.data
                                            // );
                                            if (res.status === 200) {
                                              setVendorFeedCommentsData(
                                                (data) => {
                                                  const is_liked =
                                                    reply?.is_liked;
                                                  data[index].replies[
                                                    i
                                                  ].is_liked = is_liked
                                                    ? false
                                                    : true;
                                                  if (is_liked) {
                                                    data[index].replies[i]
                                                      .likes--;
                                                  } else {
                                                    data[index].replies[i]
                                                      .likes++;
                                                  }
                                                  return cloneDeep(data);
                                                }
                                              );
                                            }
                                          } catch (e) {
                                            console.log(
                                              "e.res",
                                              e?.response,
                                              e,
                                              JSON.stringify(e, null, 1)
                                            );
                                            handleError(e);
                                          }
                                        })()
                                      }
                                    >
                                      <RemixIcon
                                        name="ri-thumb-up-fill"
                                        // className=""
                                        size={14}
                                        color={
                                          reply.is_liked
                                            ? colors.app.f
                                            : colors.app.d1
                                        }
                                      />
                                      <Font className="text-app-d1 leading-4 ml-1 mr-2 text-sm">
                                        Like
                                      </Font>
                                    </Pressable>
                                    {/* <Font className="text-app-d1 leading-4 text-xs">
                                      •
                                    </Font>
                                    <Font className="text-app-d1 leading-4 mx-2 text-sm">
                                      Reply
                                    </Font> */}
                                    <Font className="text-app-d1 leading-4 text-xs">
                                      •
                                    </Font>
                                    <Font className="text-app-d1 leading-4 ml-2 text-sm">
                                      {getFullDateTime(reply.updated_at, null, {
                                        fromNow: true,
                                      })}
                                    </Font>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      )}
                    </View>
                  ))
                ) : (
                  <View className="flex-1 justify-center items-center pb-8">
                    <Font className="text-app-d1">
                      Comments on this post Not Found
                    </Font>
                  </View>
                )
              ) : (
                <View className="flex-1 justify-center items-center pb-8">
                  <Font className="text-app-d">Loading...</Font>
                </View>
              )}

              <View className="h-18 w-full py-4 mt-16 pl-4 pr-1 flex-row items-center border-t rounded-b-lg lg:bg-app-d/95 border-gray-200">
                <View className="flex-1 mr-1 ">
                  {/* <Input type="text" placeholder="Type your message"></Input> */}
                  <View
                    className={`rounded-lg border border-gray-200 overflow-hidden flex-row  ${
                      text?.length > 100 ? "h-20" : "h-10"
                    } bg-app-d lg:bg-app-a`}
                  >
                    {!!isEditing ? (
                      <RemixIcon
                        className="self-center"
                        name="ri-pencil-fill"
                        size={12}
                        color={
                          screen?.width > 1023 ? colors.app.d : colors.app.a
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <TextInput
                      placeholder={`Write a ${inputFor}...`}
                      className={`w-full ${
                        Platform.OS === "web" ? "flex-1 py-2.5" : "h-full"
                      } px-3 min-w-0 bg-app-a/95 lg:bg-app-d/95 lg:text-app-a`}
                      style={
                        Platform.OS === "web" && {
                          outlineColor: "transparent",
                        }
                      }
                      multiline={true}
                      textAlignVertical="center"
                      value={text}
                      onChangeText={(e) => setText(e)}
                      onKeyPress={(e) => {
                        if (!e.shiftKey && ["Enter"].includes(e.code)) {
                          e.preventDefault();
                          onSendTextHandler(text, isEditing, inputFor);
                        }
                      }}
                    ></TextInput>
                  </View>
                </View>
                {(!!isEditing || inputFor === "reply") && (
                  <Pressable
                    className={`px-4 py-2 mr-1 self-end items-center justify-center rounded-lg ${
                      Platform.OS === "web"
                        ? "active:bg-app-c2"
                        : "active:bg-app-d3"
                    }`}
                    onPress={() => {
                      if (isEditing) {
                        setText("");
                        setIsEditing();
                      }
                      if (inputFor === "reply") {
                        setInputFor("comment");
                        setReplyForCid(undefined);
                      }
                    }}
                  >
                    <RemixIcon
                      name="ri-close-circle-fill"
                      color={screen?.width > 1023 ? colors.app.a : colors.app.d}
                    ></RemixIcon>
                  </Pressable>
                )}
                <Pressable
                  className={`pl-4 pr-4 py-2 self-end items-center justify-center rounded-lg ${
                    Platform.OS === "web"
                      ? "active:bg-app-c2"
                      : "active:bg-app-d3"
                  }`}
                  onPress={() => {
                    if (text) onSendTextHandler(text, isEditing, inputFor);
                  }}
                >
                  {isPosting ? (
                    <ActivityIndicator
                      color={screen?.width > 1023 ? colors.app.a : colors.app.d}
                      size={"small"}
                    />
                  ) : (
                    <RemixIcon
                      name="send-plane-2-line"
                      color={screen?.width > 1023 ? colors.app.a : colors.app.d}
                    ></RemixIcon>
                  )}
                </Pressable>
              </View>
            </Card>
          </View>
        </View>
      </ScrollView>
      {showConfirmModal.visible && (
        <ResponseModal
          modalVisible={showConfirmModal.visible}
          primaryButtonLabel={"Confirm"}
          onPrimaryButtonPress={showConfirmModal.onConfirm}
          showSecondaryButton={true}
          secondaryButtonLabel={"Cancel"}
          onSecondaryButtonPress={showConfirmModal.onCancel}
          modalContent={showConfirmModal.bodyText}
        />
      )}
    </>
  );
};

export default UserFeedComments;
