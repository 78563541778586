import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  Pressable,
  Text,
  View,
  ActivityIndicator,
  FlatList,
  TextInput,
  Dimensions,
  ScaledSize,
  Platform,
  TouchableOpacity,
} from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Unautorized } from "./unautorized";
import { axiosGet, navigate } from "../../services/api";
import { Font } from "../../components/font";
// import { MessagesContent } from "./messages-content";
// import { Dimensions, ScaledSize } from "react-native";
// import { Navbar } from "../../components/navbar";
import { ScrollView } from "react-native-gesture-handler";
import colors from "../../../colors";
import RemixIcon from "react-native-remix-icon";
import { Input } from "../../components/input";
import { Footer } from "../../components/footer";
import { AuthContext } from "../../context/authContext";
import { mediaURI } from "../../../constants";
import { useSharedContext } from "../../context/sharedContext";
import { getFullDateWithWeekday, getTime } from "../../../utils/date-time-util";
import { parseProfileName } from "../../../utils/index";
import { UserContext } from "../../context/userContext";
import { MessagesContext } from "../../context/messagesContext";
import { useRoute } from "@react-navigation/native";
import { useRef } from "react";
import { TouchableWithoutFeedback } from "react-native";
import { Card } from "../../components/card";

const Drawer = createDrawerNavigator();

export const Messages = ({ navigation }) => {
  const route = useRoute();
  const { user, userToken } = React.useContext(AuthContext);
  const { userData } = React.useContext(UserContext);
  const {
    redirectChatUserID,
    setRedirectChatUserID,
    channels,
    currentChannelMessages,
    currentChatData,
    isTypingData,
    currentChannelMessagesPagination,
    setChannels,
    setCurrentChannelMessages,
    setUpdateChannelUnreadCount,
    resetCurrentChatData,
    setCurrentChatData,
    setCurrentChannelMessagesPagination,
    resetCurrentChannelMessages,
    dispatchMessageEvents,
  } = React.useContext(MessagesContext);
  // console.log(
  //   "currentChannelMessagesPagination  ---------------- from msg content : ",
  //   currentChannelMessagesPagination
  // );
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isChatListLoading, setIsChatListLoading] = useState(false);
  const [isLoadMoreMessagesLoading, setIsLoadMoreMessagesLoading] =
    useState(false);
  const {
    screen: { width },
  } = useSharedContext();

  const isLargeScreen = width >= 1024;

  useEffect(() => {
    if (userToken && !route.params?.contact_vendor) {
      getChatList(userToken);
      if (currentChatData.data) {
        getChatMessages({ token: userToken, id: currentChatData.data.id });
      }
    }
  }, [userToken]);
  const messagesPerPage = 20;
  const getChatList = async (token) => {
    setIsChatListLoading(true);
    const Url = "/chat/channels";
    // setIsUserLoading(true);
    await axiosGet({
      Url,
      token,
    })
      .then((data) => {
        setChannels({ data: data.data.data });
      })
      .catch((err) => {
        console.log("messages, getChatList error: ", err);
      })
      .finally(() => {
        setIsChatListLoading(false);
      });
  };

  const getChatMessages = async ({ token, id, isLoadMore = false }) => {
    isLoadMore
      ? setIsLoadMoreMessagesLoading(true)
      : setIsMessagesLoading(true);
    const Url = `/chat/channels/${id}/messages?`;
    // setIsUserLoading(true);
    await axiosGet({
      Url,
      token,
      params: {
        offset:
          currentChannelMessagesPagination.to === null
            ? 0
            : currentChannelMessagesPagination.to + 1,

        limit: messagesPerPage,
      },
    })
      .then((data) => {
        // const messages = [...(currentChannelMessages || [])];
        // messages.push(...data.data.data);

        // setCurrentChannelMessages({ data: messages });
        setCurrentChannelMessages({ data: data.data.data, push: true });
        if (
          currentChannelMessagesPagination.to === null ||
          currentChannelMessagesPagination.to <
            currentChannelMessagesPagination.total
        ) {
          setCurrentChannelMessagesPagination({
            from:
              currentChannelMessagesPagination.to === null
                ? 0
                : currentChannelMessagesPagination.to + 1,
            to:
              currentChannelMessagesPagination.to === null
                ? messagesPerPage
                : currentChannelMessagesPagination.to + messagesPerPage,
            total: data.data.total_count,
          });
        }
        setUpdateChannelUnreadCount({ id });
      })
      .catch((err) => {
        console.log("messages, getChatMessages error: ", err);
      })
      .finally(() => {
        isLoadMore
          ? setIsLoadMoreMessagesLoading(false)
          : setIsMessagesLoading(false);
      });
  };

  const onBackPressResetCurrChatData = () => {
    resetCurrentChatData();
  };
  const onChatPress = async ({ data, index }) => {
    await getChatMessages({ token: userToken, id: data.id });
    setCurrentChatData({
      currIndex: index,
      prevIndex: currentChatData?.index,
      data: data,
    });
  };

  // const updateSelfLocalMessageHandler = (localID, messageData) => {
  //   setCurrentChannelMessages((messages) => {
  //     const i = messages.findIndex((m) => m.id === localID);
  //     messages[i] = messageData;
  //     return messages;
  //   });
  // };

  const onSendMessage = (message) => {
    dispatchMessageEvents.sendMessage({
      channel_id: currentChatData.data?.id,
      message,
      cb: (res) => {
        console.log("send new message res-------------------- : ", res);
      },
    });
  };
  const onDeleteMessageHandler = (id) => {
    dispatchMessageEvents.deleteMessage({
      channel_id: currentChatData.data.id,
      message_id: id,
    });
  };

  // ----------------------------------------- user typing ----------------------------

  const [typing, setTyping] = React.useState(false);

  return user ? (
    <View className="bg-gray-50 flex-1 w-full items-center justify-center">
      <View className="flex-1 w-full items-center lg:w-11/12 lg:px-4 lg:py-5">
        <View className="flex-row w-full">
          <View className="my-5 hidden lg:flex">
            <Font className="text-xl font-semibold text-app-d">Messages</Font>
          </View>
        </View>
        <View className="flex-1 w-full h-full lg:border lg:border-gray-200 lg:rounded-xl overflow-hidden">
          <Drawer.Navigator
            backBehavior="none"
            defaultStatus={
              isLargeScreen
                ? "closed"
                : !isLargeScreen && !currentChatData.data
                ? "open"
                : !isLargeScreen && currentChatData.data && "closed"
            }
            drawerContent={(props) => {
              return (
                <ChatList
                  {...props}
                  isLargeScreen={isLargeScreen}
                  chatsList={channels}
                  onChatPress={onChatPress}
                  currChatIndex={currentChatData?.currIndex}
                  typing={typing}
                  isTypingData={isTypingData}
                  redirectChatUserID={redirectChatUserID}
                  setRedirectChatUserID={setRedirectChatUserID}
                  isChatListLoading={isChatListLoading}
                />
              );
            }}
            screenOptions={{
              headerShown: false,
              drawerType: isLargeScreen ? "permanent" : "front",
              drawerStyle: isLargeScreen ? { width: "30%" } : { width: "100%" },
              drawerContentContainerStyle: { paddingTop: 4 },
              overlayColor: "transparent",
            }}
          >
            <Drawer.Screen name="chat">
              {(props) => (
                <MessagesContent
                  {...props}
                  isLargeScreen={isLargeScreen}
                  messages={currentChannelMessages}
                  user={userData}
                  currentChat={currentChatData}
                  onSendMessage={onSendMessage}
                  onBackPressResetCurrChatData={onBackPressResetCurrChatData}
                  onDeleteMessageHandler={onDeleteMessageHandler}
                  setTyping={setTyping}
                  typing={typing}
                  isTypingData={isTypingData}
                  currentChannelMessagesPagination={
                    currentChannelMessagesPagination
                  }
                  getChatMessages={() =>
                    getChatMessages({
                      token: userToken,
                      id: currentChatData.data.id,
                    })
                  }
                  messagesPerPage={messagesPerPage}
                  isMessagesLoading={isMessagesLoading}
                  isLoadMoreMessagesLoading={isLoadMoreMessagesLoading}
                  setCurrentChannelMessagesPagination={
                    setCurrentChannelMessagesPagination
                  }
                  resetCurrentChannelMessages={resetCurrentChannelMessages}
                />
              )}
            </Drawer.Screen>
          </Drawer.Navigator>
        </View>
      </View>
    </View>
  ) : (
    <Unautorized />
  );
};

export const ChatList = ({
  navigation,
  isLargeScreen,
  chatsList,
  onChatPress,
  currChatIndex,
  typing,
  isTypingData,
  redirectChatUserID,
  setRedirectChatUserID,
  isChatListLoading,
}) => {
  const { dispatchMessageEvents, currentChatData } =
    React.useContext(MessagesContext);

  useEffect(() => {
    console.log("redirectChatUserID:", redirectChatUserID);
    if (chatsList?.length && redirectChatUserID) {
      const index = chatsList.findIndex(
        (ch) => ch.customer_id == redirectChatUserID
      );
      // console.log(`channels[${index}]:`, channels[index]);
      if (index > -1) {
        onChatPress({ data: chatsList[index], index: index });
        setRedirectChatUserID({ uid: null });
        navigation.closeDrawer();
      }
    }
  }, [chatsList, redirectChatUserID, setRedirectChatUserID]);

  useEffect(() => {
    if (typing) {
      dispatchMessageEvents.startedTypingMessage({
        channel_id: currentChatData?.data?.id,
        cb: (res) => {
          console.log("res-----", res);
        },
      });
    } else {
      dispatchMessageEvents.stoppedTypingMessage({
        channel_id: currentChatData?.data?.id,
        cb: (res) => {
          console.log("res-----", res);
        },
      });
    }
  }, [typing]);

  const chatPressHandler = (chatData, chatIndex) => {
    onChatPress({ index: chatIndex, data: chatData });
    navigation.closeDrawer();
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-transparent">
      <View className="w-full h-16 bg-app-e items-center flex-row px-1 flex lg:hidden">
        <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
          Messages
        </Font>
        <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
      </View>

      <View className="flex-1 w-full p-4 lg:p-0 bg-app-b rounded-t-2xl lg:rounded-none">
        <View className="hidden flex-row  w-full lg:flex justify-center h-16 bg-app-c1">
          <Font className="flex items-center text-app-d1">Chat list</Font>
        </View>
        {/* <ScrollView
          className=""
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
        {isChatListLoading ? (
          <View className="flex-1 w-full justify-center h-full">
            <ActivityIndicator
              size={"small"}
              color={colors.app.e}
            ></ActivityIndicator>
          </View>
        ) : (
          <View className="flex-1 w-full h-full divide-y divide-gray-200 bg-white rounded-2xl lg:rounded-none">
            <FlatList
              data={chatsList}
              renderItem={({ item, index }) => {
                // console.log("item---------: ", item);
                const textColor =
                  currChatIndex === index && isLargeScreen
                    ? "text-app-a"
                    : "text-app-d1";
                return (
                  <Pressable
                    className={`flex-row p-4 active:bg-app-c rounded-lg border-b border-app-c1 ${
                      currChatIndex === index && isLargeScreen
                        ? "bg-app-d/90 "
                        : ""
                    }`}
                    onPress={() => {
                      chatPressHandler(item, index);
                    }}
                  >
                    <View className="rounded-full w-10 h-10 overflow-hidden">
                      <Image
                        source={require("../../../assets/userImage.jpg")}
                        className="w-full h-full"
                      ></Image>
                    </View>
                    <View className="flex-1 ml-4">
                      <View className="flex-row">
                        <Font
                          className={`flex-1 text-[15px] ${textColor}`}
                          weight={600}
                        >
                          {item?.vendor?.first_name +
                            " " +
                            item?.vendor?.last_name || item?.vendor?.email}
                        </Font>
                        <Font className={`${textColor} text-xs`}>
                          {getTime(
                            item.last_message?.timestamp ||
                              item.last_message?.updated_at
                          )}
                        </Font>
                      </View>
                      <View className="flex-row">
                        <Font
                          className={`flex-1 ${textColor} pt-[2px] text-xs `}
                          weight={item?.unread > 0 ? 700 : 500}
                          numberOfLines={1}
                        >
                          {item?.last_message
                            ? isTypingData &&
                              currChatIndex !== index &&
                              isTypingData.channel_id === item.id
                              ? "typing..."
                              : item?.last_message?.message
                            : isTypingData &&
                              currChatIndex !== index &&
                              isTypingData.channel_id === item.id
                            ? "typing..."
                            : "start a conversation..."}
                          {/* {Lorem ipsum dolor sit amet, consectetur adipiscingelit.} */}
                        </Font>
                        {item?.unread > 0 ? (
                          <View className="px-1 rounded-full bg-app-e">
                            <Font className="text-xs overflow-visible min-w-[10px] text-center text-app-a">
                              {item?.unread <= 99 ? item?.unread : 99 + "+"}
                            </Font>
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  </Pressable>
                );
              }}
            />
          </View>
        )}
        {/* </ScrollView> */}
      </View>
    </View>
  );
};
const ChatBubble = ({
  isOwnMessageBubble,
  time,
  message,
  isSending,
  onDeleteMessageHandler,
  typing,
  showOptions,
  setShowOptions,
  msgId,
}) => {
  const [showTime, setShowTime] = React.useState(isSending);
  return (
    <Pressable
      onPress={() => setShowOptions(null)}
      className={`relative ${
        isOwnMessageBubble ? "self-end" : "self-start"
      } p-2 min-w-max max-w-[80%]`}
    >
      <View
        className={`w-full flex-row group ${
          isOwnMessageBubble ? "justify-end" : "justify-start"
        }`}
      >
        <Pressable
          onPress={() => {
            setShowTime(!showTime);
            setShowOptions(null);
          }}
          className={`flex-row min-w-max flex-wrap p-4 rounded-lg ${
            isOwnMessageBubble
              ? "rounded-br-none bg-app-d"
              : "rounded-bl-none bg-app-b border border-app-c"
          }`}
        >
          <Font
            className={`${isOwnMessageBubble ? "text-app-a" : "text-app-d"}`}
          >
            {message}
          </Font>
        </Pressable>
        {isOwnMessageBubble && (
          <TouchableOpacity
            className="w-3 invisible group-hover:visible "
            onPress={() => {
              setShowOptions(showOptions === msgId ? null : msgId);
            }}
          >
            <RemixIcon name="ri-more-2-fill" color={colors.app.d}></RemixIcon>
          </TouchableOpacity>
        )}
      </View>
      {/* {showTime && ( */}
      <Font
        className={`text-xs pr-3 font-thin text-app-d ${
          isOwnMessageBubble ? "self-end" : "self-start"
        }`}
      >
        {isOwnMessageBubble ? (isSending ? "Sending..." : time) : time}
      </Font>
      {/* )} */}
      {isOwnMessageBubble && showOptions === msgId && (
        <View className=" flex-1 absolute top-0 right-8 bg-app-b shadow-lg h-10 w-20 p-2 border border-app-c rounded-md">
          <Pressable
            onPress={() => {
              onDeleteMessageHandler();
              setShowOptions(null);
            }}
          >
            <Font className="text-center">delete</Font>
          </Pressable>
        </View>
      )}
    </Pressable>
  );
};

export const MessagesContent = ({
  navigation,
  isLargeScreen,
  messages,
  currentChat,
  onSendMessage,
  user,
  onBackPressResetCurrChatData,
  onDeleteMessageHandler,
  setTyping,
  typing,
  isTypingData,
  getChatMessages,
  currentChannelMessagesPagination,
  messagesPerPage,
  isMessagesLoading,
  isLoadMoreMessagesLoading,
  setCurrentChannelMessagesPagination,
  resetCurrentChannelMessages,
}) => {
  const flatListRef = useRef();
  const [scrollRef, setScrollRef] = React.useState();
  const [messageText, setMessageText] = React.useState("");
  const [vMIsLoading, setVMIsLoading] = React.useState();
  const [uiGroupMessagesDate, setUIGroupMessagesDate] = React.useState();
  const [showOptions, setShowOptions] = React.useState(null);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      setTyping(false);
    }, 1000);

    return () => {
      clearTimeout(typingTimer);
    };
  }, [typing]);
  let prevMesg = { date: null, id: null };
  const getChatsFullDate = (dateToday, id, nextMessageDate, msg) => {
    let currDate = getFullDateWithWeekday(dateToday);
    let nextDate = getFullDateWithWeekday(nextMessageDate ?? "");
    if (nextDate !== currDate) {
      prevMesg.date = currDate;
      prevMesg.id = id;
      return (
        <View className="w-full h-12 justify-center items-center py-2">
          <Font className="text-app-d bg-app-d3 border border-app-c px-2 py-1 rounded">
            {currDate}
          </Font>
        </View>
      );
    }
    // else if (
    //   prevMesg?.date === currDate &&
    //   prevMesg?.id &&
    //   prevMesg?.id === id
    // ) {
    //   return (
    //     <View className="w-full flex-1 justify-center items-center py-2">
    //       <Font className="text-app-d bg-app-d3 border border-app-c px-2 py-1 rounded">
    //         {prevMesg.date}
    //       </Font>
    //     </View>
    //   );
    // }
    else return "";
  };

  const sendMessageHandler = (message) => {
    if (message && message?.trim() !== "") {
      setMessageText("");
      onSendMessage(message);
    }
  };

  // -------------------------- on scroll to top handler ----------------------------

  const handleScroll = ({ nativeEvent }) => {
    const { contentOffset } = nativeEvent;
    if (contentOffset.y === 0) {
      // Call your function here when scrolled to the top
      // console.log("----------------------Scrolled to top 100!");
    }
  };
  return currentChat.data ? (
    <View key={currentChat.id} className="flex-1 rounded-lg">
      <View className="w-full h-16 bg-app-e items-center flex-row px-1  flex ">
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
          onPress={() => {
            resetCurrentChannelMessages();
            onBackPressResetCurrChatData();
            setCurrentChannelMessagesPagination({
              from: null,
              to: null,
              total: null,
            });
            navigation.openDrawer();
          }}
        >
          <RemixIcon
            name="arrow-left-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
        <View className="flex-row p-1 lg:p-4">
          <View className="rounded-full w-10 h-10 overflow-hidden">
            <Image
              source={
                currentChat.data?.vendor?.profile_image_url
                  ? {
                      uri:
                        mediaURI + currentChat.data?.vendor?.profile_image_url,
                    }
                  : require("../../../assets/userImage.jpg")
              }
              className="w-full h-full"
            ></Image>
          </View>
          <View className="px-4 justify-center">
            <View className="flex-col">
              <Font className="text-[15px] text-app-a" weight={600}>
                {currentChat.data?.vendor?.first_name +
                  " " +
                  currentChat.data?.vendor?.last_name ||
                  currentChat.data?.vendor?.email}
              </Font>
              <Font className="text-app-b text-xs">Online</Font>
            </View>
          </View>
        </View>
        <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 lg:hidden"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
        {/* <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
          onPress={() => navigation.openDrawer()}
        >
          <RemixIcon
            name="arrow-left-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable> */}
        {/* <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
          Messages
        </Font> */}
        {/* <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable> */}
      </View>
      {/* <ScrollView
        ref={(ref) => setScrollRef(ref)}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className="bg-app-a"
        snapToEnd={true}
        onContentSizeChange={() => {
          scrollRef.scrollToEnd();
        }}
      > */}
      {vMIsLoading && (
        <ActivityIndicator
          animating={true}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md bg-app-a/50 rounded-full p-2 z-50 top-2 absolute place-self-start"
        />
      )}
      {isMessagesLoading && !isLoadMoreMessagesLoading ? (
        <View className="flex-1 w-full justify-center h-full">
          <ActivityIndicator
            size={"small"}
            color={colors.app.e}
          ></ActivityIndicator>
        </View>
      ) : (
        <View className="flex-1 w-full h-full">
          <FlatList
            ref={flatListRef}
            keyboardShouldPersistTaps="always"
            inverted
            onEndReached={() => {
              if (
                currentChannelMessagesPagination.to === null ||
                currentChannelMessagesPagination.to <
                  currentChannelMessagesPagination.total
              ) {
                getChatMessages({ isLoadMore: true });
              }
            }}
            onEndReachedThreshold={1.5}
            className="w-full h-full bg-app-a"
            contentContainerStyle={{
              flexGrow: 1,
              // justifyContent: "flex-start",
              // alignItems: "stretch",
              // minHeight: "100%",
              // maxHeight: "100%",
              padding: 10,
            }}
            // onContentSizeChange={() =>
            //   flatListRef?.current?.scrollToEnd({ animated: true })
            // }
            onScroll={handleScroll}
            // refreshControl={() => (
            //   <RefreshControl
            //     refreshing={!vMIsLoading}
            //     onRefresh={() => null}
            //   />
            // )}
            data={messages}
            keyExtractor={(item, index) => `message_in_chat_${index}`}
            extraData={messages}
            viewabilityConfig={{
              itemVisiblePercentThreshold: 80,
              minimumViewTime: 1000,
            }}
            ListFooterComponent={() => {
              if (isLoadMoreMessagesLoading && !isMessagesLoading) {
                return (
                  <ActivityIndicator
                    size={"small"}
                    color={colors.app.e}
                  ></ActivityIndicator>
                );
              }
            }}
            ListHeaderComponent={() => {
              if (
                currentChat?.data?.id === isTypingData?.channel_id &&
                typing &&
                isTypingData?.user?.id !== user?.id
              ) {
                return (
                  <View
                    className={`relative w-max self-start p-2 min-w-max max-w-[80%]`}
                  >
                    <View className="flex-row group">
                      <Pressable
                        className={`flex-row p-4 rounded-lg rounded-bl-none bg-gray-100 border border-app-c
                  `}
                      >
                        <Font className="text-app-d">typing...</Font>
                      </Pressable>
                    </View>
                  </View>
                );
              }
            }}
            renderItem={({ item, index }) => {
              const time = getTime(item.timestamp || item.updated_at);
              return (
                <Pressable
                  className="w-full flex-col m-1"
                  onPress={() => setShowOptions(null)}
                >
                  {getChatsFullDate(
                    item.created_at,
                    item.id,
                    messages[index + 1]?.created_at,
                    item.message
                  )}
                  <ChatBubble
                    isOwnMessageBubble={item.sender_id === user?.id}
                    message={item.message}
                    isSending={item.isSending}
                    time={time}
                    onDeleteMessageHandler={() =>
                      onDeleteMessageHandler(item.id)
                    }
                    typing={typing}
                    msgId={item.id}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                  />
                </Pressable>
              );
            }}
          />
        </View>
      )}
      {/* </ScrollView> */}
      <View className="h-18 w-full p-2 flex-row items-center border-t lg:bg-app-d border-app-d2">
        <View className="flex-1 ml-1 mr-2">
          {/* <Input type="text" placeholder="Type your message"></Input> */}
          <View
            className={`rounded-lg border border-app-d2 overflow-hidden ${
              messageText?.length > 100 ? "h-20" : "h-10"
            } bg-app-a`}
          >
            <TextInput
              placeholder="Type your message"
              className={`w-full ${
                Platform.OS === "web" ? "flex-1 py-2.5" : "h-full"
              } px-3 min-w-0 text-app-d lg:bg-app-d/10`}
              style={
                Platform.OS === "web" && {
                  outlineColor: "transparent",
                }
              }
              multiline={true}
              textAlignVertical="center"
              value={messageText}
              onChangeText={(e) => {
                setTyping(true);
                setMessageText(e);
              }}
              onKeyPress={(e) => {
                if (!e.shiftKey && ["Enter"].includes(e.code)) {
                  e.preventDefault();
                  sendMessageHandler(messageText);
                }
              }}
            ></TextInput>
          </View>
        </View>
        {/* {!!isEditingMessage && (
          <Pressable
            className={`w-12 self-center h-10 mr-1 items-center justify-center rounded-lg ${
              Platform.OS === "web" ? "active:bg-app-c2" : "active:bg-app-d3"
            }`}
            onPress={() => {
              setMessageText("");
              setIsEditingMessage();
            }}
          >
            <RemixIcon
              name="ri-close-circle-fill"
              color={Platform.OS === "web" ? colors.app.a : null}
            ></RemixIcon>
          </Pressable>
        )} */}
        <Pressable
          className={`w-12 self-center h-10 items-center justify-center rounded-lg ${
            Platform.OS === "web" ? "active:bg-app-c2" : "active:bg-app-d3"
          }`}
          onPress={
            messageText && messageText?.trim() !== ""
              ? () => sendMessageHandler(messageText)
              : () => {}
          }
        >
          <RemixIcon
            name="send-plane-2-line"
            color={
              isLargeScreen
                ? messageText && messageText?.trim() !== ""
                  ? colors.app.a
                  : colors.app.a1
                : messageText && messageText?.trim() !== ""
                ? colors.app.d
                : colors.app.d1
            }
          ></RemixIcon>
        </Pressable>
      </View>
    </View>
  ) : (
    isLargeScreen && !currentChat.data && (
      <View className="flex-1 rounded-r-lg bg-app-d justify-center items-center">
        <View className="rounded-full w-24 h-24 overflow-hidden mb-2 -mt-8">
          <Image
            source={require("../../../assets/smbex-logoa.png")}
            className="w-full h-full"
          ></Image>
        </View>
        <View className="flex-row rounded bg-app-a/50 px-4 py-1 shadow-sm">
          <Font className="text-2xl text-app-d">SMB</Font>
          <Font className="text-2xl text-app-e">Ex</Font>
          <Font className="text-2xl text-app-a"> Support Chat</Font>
        </View>
        <Font className="text-lg text-app-c">
          Welcome, {parseProfileName(user?.first_name, user?.last_name)}
        </Font>
      </View>
    )
  );
};
