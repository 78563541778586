import React, { createContext, useContext, useEffect, useState } from "react";

export const BusinessesContext = createContext({ reset: () => null });

export const BusinessesProvider = ({ children }) => {
  const [isBusinessLoading, setIsBusinessLoading] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [featuredPlaces, setFeaturedPlaces] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [pagination, setPagination] = useState({
    offset: null,
    total_records: null,
  });

  return (
    <BusinessesContext.Provider
      value={{
        isBusinessLoading,
        businesses,
        businessCategories,
        featuredPlaces,
        reviews,
        businessDetails,
        setIsBusinessLoading,
        setBusinesses,
        setBusinessCategories,
        setFeaturedPlaces,
        setReviews,
        setBusinessDetails,
        pagination,
        setPagination,
        reset: () => {
          setIsBusinessLoading(false);
          setBusinesses([]);
          setBusinessCategories([]);
          setFeaturedPlaces([]);
          setReviews([]);
          setBusinessDetails(null);
        },
      }}
    >
      {children}
    </BusinessesContext.Provider>
  );
};

export const useBusinessesContext = () => useContext(BusinessesContext);
