import React, { useContext, useEffect, useState } from "react";
import { Platform, Pressable, Text, View } from "react-native";
import {
  getStats,
  getRecentActivites,
  getGroupNonMembers,
  getGroupNonMembersInvites,
  sendInviteToEmail,
  sendInvite,
  addGroupMember,
} from "../../services/api";
import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";

// import correctCheckbox from "../../../assets/green-fill-checkbox.svg";
// import cancelCheckbox from "../../../assets/gray-fill-cross.svg";
import verticalMenu from "../../../assets/white-menu-vertical.svg";
import RemixIcon from "react-native-remix-icon";
import { ResponseModal } from "../../components/response-modal";
import { PleaseWaitModal } from "../../components/modals";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import SVGLoader from "../../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import correctCheckbox from "../../../assets/green-fill-checkbox.svg";
import cancelCheckbox from "../../../assets/gray-fill-cross.svg";
import { AuthContext } from "../../context/authContext";
import { Footer } from "../../components/footer";

export default function AddInviteMember({ navigation, route }) {
  const { userToken } = useContext(AuthContext);

  useEffect(() => {
    if (route.params) {
      const { id, groupId } = route.params;
      setGroupId(groupId);
      getMembersToAdd(groupId);
      getMembersToInvite(groupId);
    }
  }, [route.params]); // Dependency array ensures this runs only when route.params changes
  const [groupId, setGroupId] = useState();
  const [memberList, setMemberList] = useState([]);
  const [memberList1, setMemberList1] = useState([]);
  const [emailText, setEmailText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [isNoRestrictionSelected, setIsNoRestrictionSelected] = useState(false);
  const [isInviteOnlySelected, setIsInviteOnlySelected] = useState(false);
  const [selectedNoRestrictUserIds, setSelectedNoRestrictUserIds] = useState(
    []
  );
  const [selectedInviteOnlyUserIds, setSelectedInviteOnlyUserIds] = useState(
    []
  );

  useEffect(() => {
    const t = setTimeout(() => {
      if (searchText === null) {
        setSearchText("");
        getMembersToAdd(groupId);
        getMembersToInvite(groupId);
      }
    }, 1000);
    return () => clearTimeout(t);
  }, [searchText]);

  const getMembersToAdd = async (groupId) => {
    try {
      let res = await getGroupNonMembers(groupId, userToken);
      if (res.status === 200) {
        console.log(res.data, "members to add");
        setMemberList(res.data.data);
      } else {
        console.log(res, "members to add failed");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const searchUsers = async (groupId, search_text) => {
    try {
      let res = await getGroupNonMembersInvites(
        groupId,
        userToken,
        search_text
      );
      console.log(res.status, res.data, "members to invite check");
      if (res.status === 200) {
        res?.data?.data?.forEach((user) => {
          if (user.group_joining_preference === "invites_only") {
            setMemberList1((prev) => [...(prev || []), user]);
          } else setMemberList((prev) => [...(prev || []), user]);
        });
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const getMembersToInvite = async (groupId) => {
    try {
      let res = await getGroupNonMembersInvites(groupId, userToken);
      console.log(res.status, res.data, "members to invite check");
      if (res.status === 200) {
        setMemberList1(res?.data?.data);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const addUserToGroup = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await addGroupMember(
        groupId,
        selectedNoRestrictUserIds,
        userToken
      );
      console.log("add member res:", res.status, res.data);
      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        let isSomeMembersFailed = false;
        res.data?.data?.forEach((mr) => {
          if (mr.status !== 200) {
            isSomeMembersFailed = true;
          }
        });
        setResponseModal({
          visible: true,
          message: isSomeMembersFailed
            ? "Some of Group Members added successfully"
            : "Group members added successfully",
          DismissText: "Dismiss",
        });
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
    }
  };

  const sendInvitesToNonMembers = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await sendInvite(
        groupId,
        selectedNoRestrictUserIds,
        userToken
      );
      console.log("Invite Members res:", res.status, res.data);
      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
    }
  };

  const sendInviteEmail = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await sendInviteToEmail(emailText, userToken);
      console.log("Invite Email res:", res.status, res.data);
      if (res.status == 200) {
        setEmailText("");
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: res.data?.message || "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      console.log(error, "send invite email error");
      setIsPleaseWaitModal(false);
    }
  };

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (memberId, type) => {
    if (type === "invite_only") {
      setSelectedInviteOnlyUserIds((prevSelected) => {
        if (prevSelected.includes(memberId)) {
          return prevSelected.filter((id) => id !== memberId);
        } else {
          return [...prevSelected, memberId];
        }
      });
    } else {
      setSelectedNoRestrictUserIds((prevSelected) => {
        if (prevSelected.includes(memberId)) {
          return prevSelected.filter((id) => id !== memberId);
        } else {
          return [...prevSelected, memberId];
        }
      });
    }
  };

  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        modalVisible={responseModal.visible}
        modalContent={responseModal.message}
        onPrimaryButtonPress={closeResponseModal}
        primaryButtonLabel={responseModal.DismissText}
      ></ResponseModal>
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-5 hidden lg:flex px-4">
            <Font className="text-lg font-semibold text-app-d">
              Add/Invite Members
            </Font>
          </View>
          <View className="flex-col justify-center item-center max-w-screen-lg w-full py-2">
            <View className="flex justify-center items-center">
              <View className=" w-full lg:w-3/5 mb-4 px-4 lg:px-0 lg:py-1 flex-row bg-white rounded-full shadow-sm">
                <View className="w-full flex-row justify-between items-center">
                  <TextInput
                    className="w-full lg:w-1/2 ml-2"
                    placeholder="...Search by Name, e mail or phone"
                    placeholderTextColor="#94a3b8"
                    type="text"
                    value={searchText}
                    onChangeText={(e) => setSearchText(e || null)}
                    onSubmitEditing={(e) => {
                      searchUsers(groupId, e.nativeEvent.text);
                    }}
                    returnKeyType="search"
                  />
                  <Pressable
                    onPress={() => {
                      searchUsers(groupId, searchText);
                    }}
                    className="w-8 h-8 -ml-6 lg:mr-2 lg:ml-0 justify-center items-center"
                  >
                    <SVGLoader
                      src={search}
                      height={25}
                      width={25}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </Pressable>
                </View>
              </View>
            </View>
            <View className="flex-col max-w-screen-lg w-full">
              <Card className="flex-1">
                <View className="w-full px-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Add
                    </Font>
                    <View className="flex-row justify-center items-center">
                      {/* <Font className="lg:text-sm text-base text-app-e self-center">
                        See all
                      </Font>
                      <View className="w-8 h-4 mt-1 flex justify-center items-center">
                        <SVGLoader
                          src={verticalMenu}
                          height={30}
                          width={30}
                          webHeight={"20em"}
                          webWidth={"auto"}
                        />
                      </View> */}
                      <View className="flex-row flex justify-between items-center">
                        <Font className="px-2 text-app-d1">
                          {isNoRestrictionSelected
                            ? "Un-select All"
                            : "Select All"}
                        </Font>
                        <View className="relative overflow-hidden">
                          {isNoRestrictionSelected ? (
                            <SVGLoader
                              src={cancelCheckbox}
                              height={28}
                              width={28}
                              webHeight={"20.5px"}
                              webWidth={"20.5px"}
                              style={
                                Platform.OS === "web"
                                  ? {}
                                  : {
                                      marginTop: -1,
                                      marginBottom: -7,
                                      marginRight: -6,
                                    }
                              }
                            />
                          ) : (
                            <SVGLoader
                              src={correctCheckbox}
                              height={36}
                              width={36}
                              webHeight={"34px"}
                              webWidth={"34px"}
                              style={{
                                margin: Platform.OS === "web" ? -7 : -8,
                              }}
                            />
                          )}
                          <Pressable
                            onPress={() => {
                              if (isNoRestrictionSelected) {
                                setIsNoRestrictionSelected(() => false);
                                setSelectedNoRestrictUserIds([]);
                              } else {
                                setIsNoRestrictionSelected(() => true);
                                setSelectedNoRestrictUserIds(
                                  memberList?.map((user) => user.id) || []
                                );
                              }
                            }}
                            className="absolute top-0 left-0 bottom-0 right-0"
                          ></Pressable>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] max-h-64 p-3 pt-0 px-6 overflow-auto">
                    {!memberList?.length ? (
                      <View className="py-4 flex-col justify-center items-center">
                        <Font className="text-app-d1">No users found</Font>
                      </View>
                    ) : (
                      memberList?.map((member) => {
                        const isSelected = selectedNoRestrictUserIds.includes(
                          member?.id
                        );
                        return (
                          <View
                            className="w-full mb-3 flex-row justify-between"
                            key={member?.id}
                          >
                            <View>
                              <Font className="font-bold text-base">
                                {member?.first_name + " " + member?.last_name}
                              </Font>
                              <Font>{member?.email}</Font>
                              <Font>{member?.phone}</Font>
                            </View>
                            <View className="mt-2">
                              {/* <Input
                            type="checkbox"
                            value={member?.isSelected}
                            setValue={() => {
                              setIsSelected(!isSelected);
                            }}
                          /> */}
                              <Pressable
                                style={{
                                  width: 17,
                                  height: 17,
                                  borderRadius: 2,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: isSelected
                                    ? "red"
                                    : "#f3f3f3",
                                  borderColor: isSelected ? "red" : "#ccc",
                                  borderWidth: 1,
                                }}
                                onPress={() =>
                                  handleCheckboxToggle(
                                    member?.id,
                                    "no_restriction"
                                  )
                                }
                              >
                                {isSelected ? (
                                  <RemixIcon
                                    name="check-fill"
                                    color={"#ffffff"}
                                    size={20}
                                  />
                                ) : null}
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    )}
                  </View>
                </View>
              </Card>
              <View className="my-4 flex-row justify-center lg:justify-end item-center w-full">
                <Pressable
                  onPress={addUserToGroup}
                  className="px-4 py-1 border border-app-e bg-app-e rounded-full"
                >
                  <Text className="text-white">Add to Group</Text>
                </Pressable>
              </View>
              <View className="w-4 h-4" />
              <Card className="flex-1">
                <View className="w-full px-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Invite
                    </Font>
                    <View className="flex-row justify-center items-center">
                      {/* <Font className="lg:text-sm text-base text-app-e self-center">
                        See all
                      </Font>
                      <View className="w-8 h-4 mt-1 flex justify-center items-center">
                        <SVGLoader
                          src={verticalMenu}
                          height={30}
                          width={30}
                          webHeight={"20em"}
                          webWidth={"auto"}
                        />
                      </View> */}
                      <View className="flex-row flex justify-between items-center">
                        <Font className="px-2 text-app-d1">
                          {isInviteOnlySelected
                            ? "Un-select All"
                            : "Select All"}
                        </Font>
                        <View className="relative overflow-hidden">
                          {isInviteOnlySelected ? (
                            <SVGLoader
                              src={cancelCheckbox}
                              height={28}
                              width={28}
                              webHeight={"20.5px"}
                              webWidth={"20.5px"}
                              style={
                                Platform.OS === "web"
                                  ? {}
                                  : {
                                      marginTop: -1,
                                      marginBottom: -7,
                                      marginRight: -6,
                                    }
                              }
                            />
                          ) : (
                            <SVGLoader
                              src={correctCheckbox}
                              height={36}
                              width={36}
                              webHeight={"34px"}
                              webWidth={"34px"}
                              style={{
                                margin: Platform.OS === "web" ? -7 : -8,
                              }}
                            />
                          )}
                          <Pressable
                            onPress={() => {
                              if (isInviteOnlySelected) {
                                setIsInviteOnlySelected(() => false);
                                setSelectedInviteOnlyUserIds([]);
                              } else {
                                setIsInviteOnlySelected(() => true);
                                setSelectedInviteOnlyUserIds(
                                  memberList1?.map((user) => user.id) || []
                                );
                              }
                            }}
                            className="absolute top-0 left-0 bottom-0 right-0"
                          ></Pressable>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] max-h-64 p-3 pt-0 px-6 overflow-auto">
                    {!memberList1?.length ? (
                      <View className="py-4 flex-col justify-center items-center">
                        <Font className="text-app-d1">No users found</Font>
                      </View>
                    ) : (
                      memberList1?.map((member) => {
                        const isSelected = selectedInviteOnlyUserIds.includes(
                          member?.id
                        );
                        return (
                          <View
                            className="w-full mb-3 flex-row justify-between"
                            key={member?.id}
                          >
                            <View>
                              <Font className="font-bold text-base">
                                {member?.first_name + " " + member?.last_name}
                              </Font>
                              <Font>{member?.email}</Font>
                              <Font>{member?.phone}</Font>
                            </View>
                            <View className="mt-2">
                              <Pressable
                                style={{
                                  width: 17,
                                  height: 17,
                                  borderRadius: 2,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: isSelected
                                    ? "red"
                                    : "#f3f3f3",
                                  borderColor: isSelected ? "red" : "#ccc",
                                  borderWidth: 1,
                                }}
                                onPress={() =>
                                  handleCheckboxToggle(
                                    member?.id,
                                    "invite_only"
                                  )
                                }
                              >
                                {isSelected ? (
                                  <RemixIcon
                                    name="check-fill"
                                    color={"#ffffff"}
                                    size={20}
                                  />
                                ) : null}
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    )}
                  </View>
                </View>
              </Card>
              <View className="my-4 flex-row justify-center lg:justify-end item-center w-full">
                <Pressable
                  onPress={sendInvitesToNonMembers}
                  className="px-4 py-1 border border-app-e bg-app-e rounded-full"
                >
                  <Text className="text-white">Send Invitation</Text>
                </Pressable>
              </View>
              <View className="w-4 h-4" />
            </View>
          </View>

          <View className="flex-col max-w-screen-lg w-full">
            <Card className="flex-1">
              <View className="w-full p-4 flex-col">
                <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                  <Font className="lg:text-lg text-base font font-bold self-center">
                    Invite via email
                  </Font>
                </View>
                <View className="">
                  <View className="p-2 flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                    <TextInput
                      className="w-full ml-2"
                      placeholder="...Search by e mail"
                      placeholderTextColor="#94a3b8"
                      type="text"
                      value={emailText}
                      onChangeText={(e) => setEmailText(e)}
                    />
                  </View>
                  <Pressable
                    onPress={() => sendInviteEmail()}
                    className="mt-2 flex-row justify-end item-center w-full"
                  >
                    <Font className="lg:text-lg text-sm text-app-e font-semibold">
                      Invite
                    </Font>
                  </Pressable>
                </View>
              </View>
            </Card>
          </View>
          <View className="w-full h-8" />
          <Footer />
        </View>
      </ScrollView>
    </View>
  );
}
