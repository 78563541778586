import React, { useContext, useState } from "react";
import * as Linking from "expo-linking";

import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Signin } from "./signin";
import { Signup } from "./signup";
import { Platform } from "react-native";
import { ForgotPassword } from "./forgot-password";
import { UpdatePassword } from "./update-password";
import { navigate, navigationRef } from "../../services/api";
import { Root as RootStack } from "./root";
import { Font } from "../../components/font";
import { View } from "react-native";
import { UpdatePasswordSuccessfullyPage } from "./update-password-successful";
import { InvalidLink } from "./invalid-link";
import { ExpiredLink } from "./expired-link";
import { EmailSentSuccessfullyPage } from "./email-sent-successful";
import { useEffect } from "react";
import { ConfirmEmailSent } from "./confirm-email-sent";
import { ConfirmEmailVerify } from "./confirm-email-verify";
import { ConfirmEmailSuccess } from "./confirm-email-success";
import VerifyOtp from "./verifyOtp";
import {
  SharedContext,
  SharedContextProvider,
} from "../../context/sharedContext";
import { PhoneNumberScreen } from "./PhoneNumberScreen";

const title = (text) =>
  Platform.select({ web: `SMBEx | ${text}`, default: text });
const prefix = Linking.createURL("/");

const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      app: {
        path: "/",
        screens: {
          home: "",
          "about-us": "about-us",
          "contact-us": "contact-us",
          post: "post/:slug",
          faq: "faq",
          contacted: "contacted",
          blog: "blog",
          termscondition: "termscondition",
          "privacy-policy": "privacy-policy",
          wishlist: "wishlist",
          bookings: "bookings",
          booking: "booking",
          orders: "orders",
          withdraw: "withdraw",
          "top-up": "top-up",
          messages: { path: "messages/", screens: { chat: "chat" } },
          search: "search",
          // "search-modal": "search-modal",
          // cart: "cart",
          // account: "account",
          listing: "listing",
          product: "product",
          vendor: "vendor",
          "user-feed": {
            path: "/user-feed",
            screens: {
              "user-feed-timeline": "/timeline",
              "user-feed-comments": "/timeline/:slug/comments",
              "user-reviews": "/reviews",
              "user-review": "/reviews/:slug",
            },
          },
          checkout: "checkout",
          "booking-details": "booking-details",
          orders: "orders",
          "order-details": "order-details",
          "manage-addresses": "manage-addresses",
          "update-address": "update-address",
          "update-profile": "update-profile",
          "invite-contact": "invite-contact",
          review: "review",
          groups: "groups",
          "view-groups": "view-groups",
          "group-details": "group-details",
          "add-member": "add-member",
          "group-join-requests": "group-join-requests",
        },
      },
      "forgot-password": "forgot-password",
      "email-sent-successful": "email-sent-successful",
      "update-password": "update-password",
      "confirm-email-success": "confirm-email-success",
      "confirm-email-sent": "confirm-email-sent",
      "confirm-email-verify": "confirm-email-verify",
      "invalid-link": "invalid-link",
      "expired-link": "expired-link",
      "update-password-successful": "update-password-successful",
      signin: "signin",
      signup: "signup",
      phoneNumber:"phoneNumber",
    },
  },
};
const Stack = createNativeStackNavigator();
export const CustomerRouting = () => {
  const { shared, setShared } = useContext(SharedContext);

  useEffect(() => {
    // Deep linking event listener
    const handleUrl = ({ url }) => {
      if (!url) {
        return;
      }
      // Process the incoming deep link URL here
      // console.log("Received deep link 1:", url);
      let urlStub = url.split("//")[1];
      if (urlStub) {
        let urlStubArray = urlStub.split("/");
        if (urlStubArray[0] == "") {
          urlStubArray.splice(0, 1);
        }
        if (Platform.OS === "web") {
          urlStubArray.shift();
        }
        let urlHash = urlStubArray[1];

        if (urlStubArray[0] === "set-new-password") {
          navigationRef.navigate("update-password", { urlHash });
        } else if (urlStubArray[0] === "verify-email") {
          navigationRef.navigate("confirm-email-verify", { urlHash });
        } else if (urlStubArray[0] === "chat") {
          setRedirectChatUserID({
            uid: parseInt(urlStubArray[1].toString()),
            callback: () => navigationRef.current?.navigate("messages"),
          });
        }
      }
    };

    let subscription = Linking.addEventListener("url", handleUrl);
    // Handle initial deep link when the app is opened from a deep link
    Linking.getInitialURL().then((url) => {
      if (url) {
        // Process the initial deep link URL here
        console.log("Received initial deep link 1:", url);
        handleUrl({ url });
      }
    });
    return () => {
      subscription.remove();
    };
  }, []);

  // useEffect(() => {
  //     if(user!==null){
  //         if(user !== false){
  //             replace("home")
  //         }
  //         if(user === false){
  //             replace("signin")
  //         }
  //     }
  // }, [user]);

  return (
    <SharedContextProvider>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        fallback={
          <View className="flex-1 justify-center items-center">
            <Font> Loading...</Font>
          </View>
        }
      >
        <Stack.Navigator
          screenOptions={{ animation: "none" }}
          // initialRouteName="verifyOtp"
          // screenListeners={{
          //   state: (e) => {
          //     let name = e?.data?.state?.routes[0]?.state?.routes;
          //     // console.log("route : " , e?.data?.state);
          //     if (name) {
          //       setShared((e) => ({
          //         ...e,
          //         route: name[name.length - 1].name,
          //       }));
          //     }
          //   },
          // }}
          screenListeners={{
            state: (e) => {
              const getRoute = (routes) => {
                const lastIndexRoute =
                  routes?.[routes.length - 1]?.state?.routes;
                const hasInnerRoutes = lastIndexRoute?.state?.routes;
                if (hasInnerRoutes) {
                  return getRoute(hasInnerRoutes);
                } else return lastIndexRoute;
              };
              let routes = e?.data?.state?.routes;
              if (routes) {
                const route = getRoute(routes);
                setShared(route);
              }
            },
          }}
        >
          <Stack.Screen
            name="app"
            component={RootStack}
            options={{
              title: title("Home"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="signin"
            component={Signin}
            options={{
              title: title("Sign In"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="signup"
            component={Signup}
            options={{
              title: title("Sign Up"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="phoneNumber"
            component={PhoneNumberScreen}
            options={{
              title: title("Enter Phone Number"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="forgot-password"
            component={ForgotPassword}
            options={{
              title: title("Forgot Password"),
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="email-sent-successful"
            component={EmailSentSuccessfullyPage}
            options={{
              title: title("Email Sent Successfully Page"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="update-password"
            component={UpdatePassword}
            options={{
              title: title("Set New Password"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="update-password-successful"
            component={UpdatePasswordSuccessfullyPage}
            options={{
              title: title("Set New Password Successfully Page"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="confirm-email-success"
            component={ConfirmEmailSuccess}
            options={{
              title: title("Confirm Email Success"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="confirm-email-verify"
            component={ConfirmEmailVerify}
            options={{
              title: title("Confirm Email Verify"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="verifyOtp"
            component={VerifyOtp}
            options={{
              title: title("Verify Otp"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="confirm-email-sent"
            component={ConfirmEmailSent}
            options={{
              title: title("Confirm Email Sent"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="expired-link"
            component={ExpiredLink}
            options={{
              title: title("Expired Link"),
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="invalid-link"
            component={InvalidLink}
            options={{
              title: title("Invalid Link"),
              headerShown: false,
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </SharedContextProvider>
  );
};
